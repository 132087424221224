import {Component, OnDestroy, OnInit} from '@angular/core';
import {NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';
import {UserData} from '../../../@core/data/users';
import {map, takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AuthenticationService} from '../../../services/authentication.service';
import {Router} from '@angular/router';
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';
import {ProfileService} from '../../../services/profile.service';
import {GeneralExamService} from "../../../services/general-exam.service";
import {NotificationService} from "../../../services/notification.service";
import {PaginationConfig} from '../../../models/PaginationConfig';
import {Formatter} from 'sarala-json-api-data-formatter';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  showHideNotification: boolean = true;
  notification = [ {title: ''},
   ];

  themes = [
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';
  config: PaginationConfig;
  currentPage: any;
  notificationUnreadCount: number=0;

  userMenu: any[];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData, private translate: TranslateService,
              private profileService: ProfileService,
              private breakpointService: NbMediaBreakpointsService,
              private router: Router,
              private service: NotificationService,
  ) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.user = this.userService.getCurrentUser();
    this.profileService.getChangedUserData.subscribe(
      userData => {
        this.user.name = userData.name;
        this.user.profile_picture = userData.profile_picture;
      });
    const {xl} = this.breakpointService.getBreakpointsMap();
    this.getMenuTranslation();
    this.userMenu = [
      {title: 'Profile', url: '/#/profile/update'},
      {title: 'Log out', url: '/#/auth/logout'}
    ];
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {

      this.getMenuTranslation();
    });
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({name}) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.menuService.onItemClick().subscribe((event) => {
      this.onItemSelection(event.item);
    });

    this.reloadData();

  }

  getMenuTranslation() {

    this.translate.get(['profile', 'log_out'])
      .subscribe((res: any[]) => {
        this.userMenu = [
          {title: res['profile'], url: '/#/profile/update'},
          {title: res['log_out'], url: '/#/auth/logout'}
        ];

      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    this.router.navigate(['/']);
    return false;
  }

  /**
   * check if the item has url attrbuite, then navigate the user to the url
   */
  onItemSelection(item) {
    if (item && item.url) {
      this.router.navigate([item.url]);
    }
  }

  changeShowNotification() {

    this.showHideNotification = !this.showHideNotification;
    this.reloadData();
    // this.showHideMessage = true;
  }

  readNotification(notification, indice) {

    console.log(notification);
    console.log(indice);
    var obj = notification;
    // if (obj.accion == '2') {
    //   // emitir obj al pedido para cargarlo
    //   this.headerToPedidosEventService.headerToPedidosData.emit(notification);
    //   this.router.navigateByUrl('/pages/socios/registrar');
    // }
  }

  private reloadData(pageNumber = 1) {
    this.service.getAll(pageNumber).pipe(tap(data => {
      const formatter = new Formatter();
      const datas = formatter.deserialize(data);
      console.log(datas)

      if(datas.hasOwnProperty('notificationsData')&&datas.notificationsData.hasOwnProperty('data')){
        this.notification = datas.notificationsData.data;

      }
     this.notificationUnreadCount= datas.notifications_count
      // this.notification = datas.data;
      // this.generatePagination(datas.meta.pagination);
    }))
      .subscribe();
  }

  private generatePagination(pagination) {
    this.currentPage = pagination.current_page;
    // this.pagination.numbers = [];
    this.config = {
      currentPage: pagination.current_page,
      itemsPerPage: pagination.per_page,
      totalItems: pagination.total,
    };
  }

  nPageChange(pageNumber) {
    if (pageNumber !== this.currentPage) {
      this.reloadData(pageNumber);
    }
  }
}
