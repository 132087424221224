import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Formatter } from 'sarala-json-api-data-formatter';
import { SubjectService } from '../../../services/subject.service';
import { concatAll } from 'rxjs/operators';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'subject-details',
  templateUrl: './subject-details.component.html',
  styleUrls: ['./subject-details.component.scss', '../sub-sections/sub-sections.component.scss']
})
export class SubjectDetailsComponent implements OnInit {
  subjectId: any;
  loading: boolean;
  subject: any;
  sections: any = [];
  subSections: any;
  resources: any;
  currentLang: any;
  langChange: Subscription;

  constructor(private router: ActivatedRoute, private subjectService: SubjectService, private translate: TranslateService) {
    this.currentLang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
    });
    this.router.params.subscribe(params => {
      this.subjectId = params.subjectId;
      this.reloadData(this.subjectId)
    });
  }

  ngOnInit() {
    this.langChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      console.log(event)
      this.reloadData(this.subjectId);
    });
  }

  reloadData(subjectId: any) {
    this.loading = true;
    this.subjectService.getSubject(subjectId)
      .subscribe(data => {
        const formatter = new Formatter();
        this.loading = false;
        this.subject = formatter.deserialize(data);
        console.log(this.subject);
        this.sections = this.subject.subjectFormatSubjects? this.subject.subjectFormatSubjects.data : [];
      });
  }

  ngOnDestroy() {
    this.langChange.unsubscribe();
  }
}
