import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { QuestionData, mapToMasterQuestion } from '../../../../../models/QuestionData';
import { LanguageConfig } from '../../../../../models/LanguageConfig';
import { CompleteQuestion } from '../../../../../models/MasterQuestion';

@Component({
  selector: 'complete-question',
  templateUrl: './complete-question.component.html',
  styleUrls: ['./complete-question.component.scss'],
})
export class CompleteQuestionComponent implements OnInit, OnChanges{
  completeQuestion: CompleteQuestion;
  @Input() questionData: QuestionData;
  @Input() languageConfig: LanguageConfig;
  @Input() selectedQuestions: number[] = [];
  @Output() questionSelected: EventEmitter<number> = new EventEmitter<number>();
  @Output() questionRemoved: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.completeQuestion = <CompleteQuestion>mapToMasterQuestion(this.questionData);
  }

  selectQuestion(id){
    this.questionSelected.emit(id);
  }

  removeQuestion(id){
    this.questionRemoved.emit(id);
  }
}
