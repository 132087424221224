import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, tap, map} from 'rxjs/operators';
import {Task} from '../models/task';
import {BaseService} from './base.service';
import {Fetchedsubject, GeneralExamReportQuestion} from '../models/DiscriminationQuestionReport.model';
import {Formatter} from 'sarala-json-api-data-formatter';
import {SubjectD} from '../models/subject';
import {PreparedQuestion} from '../models/PreparedQuestion';
import {QuestionData, mapToMasterQuestion} from '../models/QuestionData';
import {ApiAction} from '../models/ApiAction';
import {CreateGeneralExam} from "../models/general-exam";

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

@Injectable({
  providedIn: 'root',
})
export class DiscriminationQuestionReportService extends BaseService {
  private readonly service_url = `${BaseService.baseUrl}/sme/general-exam-reports/subjects`;
  private readonly general_url = `${BaseService.baseUrl}/sme/general-exam-reports`;

  constructor(private http: HttpClient) {
    super();
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      return of(result as T);
    };
  }

  getAll(pageNumber): Observable<Fetchedsubject[]> {
    return this.http.get<any>(`${this.service_url}?page=${pageNumber}`);
  }

  getListReportsById(id: number, pageNumber: number = 1): Observable<any> {
    const url = `${this.general_url}/general-exam/${id}?page=${pageNumber}`;
    return this.http.get<any>(url)
      .pipe(
        map(data => {
          const formatter = new Formatter();
          const datas = formatter.deserialize(data);
          return datas;
        }),
        catchError(this.handleError('dude2', [])),
      );
  }

  getById(id: number): Observable<any> {
    const url = `${this.general_url}/${id}/details`;
    return this.http.get<any>(url)
      .pipe(
        map(data => {
          const formatter = new Formatter();
          const datas = formatter.deserialize(data);
          return datas;
        }),
        catchError(this.handleError('dude2', [])),
      );
  }

  getSubject(exam: Fetchedsubject): Observable<any> {
    const url = exam.actions.data.find(a => a.key === 'view subject sections').endpoint_url;
    return this.http.get<any>(url);
  }


  ignore(id: number) {
    const url = `${this.general_url}/ignore/${id}`;
    return this.http.post(url, {});
  }

  sendToEdit(id: number, note, due_date) {
    const url = `${this.general_url}/report/${id}`;
    const data = {
      data: {
        'id': null,
        'type': 'question_report_tasks',
        'attributes': {
          'note': note,
          'due_date': due_date,
        },
      },
    };
    return this.http.post<any>(url, data);
  }

}
