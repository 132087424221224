import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { TaskService } from '../../../services/task.service';
import { Task } from '../../../models/task';
import { Router } from '@angular/router';
import { Formatter } from 'sarala-json-api-data-formatter';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CanDeactivateComponent } from '../../../can-deactivate-component';
@Component({
  selector: 'tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss']
})

export class TasksListComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
  tasks: { freeAssigned: Task[], assignedToMe: Task[] } = { freeAssigned: [], assignedToMe: [] };
  activeTab: any;
  loading: { freeAssigned: boolean, assignedToMe: boolean };
  pagination: { freeAssigned: any, assignedToMe: any };
  config: any;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  curretLang: string;
  langChange: Subscription;
  filters: [];
  activeFilters: any;
  tabChanged = false;
  constructor(private tasksService: TaskService, private router: Router, private translate: TranslateService) { 
    super()
  }

  ngOnInit() {
    this.curretLang = this.translate.getDefaultLang();
    this.filters = [];
    this.activeFilters = {};
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.curretLang = event.lang;
    });
    this.loading = { freeAssigned: true, assignedToMe: true }
    this.pagination = { freeAssigned: null, assignedToMe: null }
    this.activeTab = 'freeAssigned';
    this.getTasks('freeAssigned', 1);
    this.getTasks('assignedToMe', 1);
    this.langChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      console.log(event)
      this.getTasks('freeAssigned', 1);
      this.getTasks('assignedToMe', 1);
    });
    this.tasksService.getTriggerMethod$.subscribe(methodType => {
      if ('getTasks') {
        this.getTasks('freeAssigned', 1);
        this.getTasks('assignedToMe', 1);
      }
    })
  };
  getTasks(type: 'assignedToMe' | 'freeAssigned', pagination: number | 'first' | 'last', filters?) {
    let page = pagination;
    // if (pagination == 'first') {
    //   page = this.pagination[type].total_pages ? 1 : 1;
    // }
    // if (pagination == 'last') {
    //   page = this.pagination[type].total_pages ? this.pagination[type].total_pages.length : 1;
    // }

    this.loading[type] = true;
    if (!filters) { filters = {} } else {
      this.activeFilters = filters;
    }

    this.activeFilters.is_assigned = type === 'assignedToMe' ? 'yes' : 'no';
    this.tasksService.getTasks(page, this.activeFilters)
      .subscribe(data => {
        this.tabChanged = false;
        const formatter = new Formatter();
        const datas = formatter.deserialize(data);
        this.tasks[type] = datas.data.map((task: any) => {
          const dateInfo = this.tasksService.getDateInfo(task.created_at);
          return { ...task, ...dateInfo };
        });
        this.filters = datas.meta.filters.filter(filter => filter.name !== 'is_assigned' && filter.name !== 'subject_id');

        this.pagination[type] = { ...datas.links, ...datas.meta.pagination };
        // const total_pages = []
        // for (let index = 0; index < this.pagination[type].total_pages; index++) {
        //   const active = index + 1 == pagination ? true : false
        //   total_pages.push({ id: index + 1, active: active })
        // }
        // this.pagination[type].total_pages = total_pages;
        this.config = {
          currentPage: this.pagination[type].current_page,
          itemsPerPage: this.pagination[type].per_page,
          totalItems: this.pagination[type].total
        };
        this.loading[type] = false;
      });
  }

  changeActiveTab(tab: 'assignedToMe' | 'freeAssigned') {
    this.activeTab = tab;
    this.tabChanged = true;
    this.activeFilters = {};
    this.getTasks(tab, 1);
  }
  ngOnDestroy() {
    this.langChange.unsubscribe();
  }

};

