import { Formatter } from 'sarala-json-api-data-formatter';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { GeneralExamService } from '../../../services/general-exam.service';
import { FetchedGeneralExam } from '../../../models/general-exam';
import { NbDialogService } from '@nebular/theme';
import { ConfirmDialogComponent } from '../../../pages/partials/confirm-dialog/confirm-dialog.component';
import { map, tap } from 'rxjs/operators';
import { PaginationConfig } from '../../../models/PaginationConfig';
import { CanDeactivateComponent } from '../../../can-deactivate-component';

@Component({
  selector: 'list-general-exams',
  templateUrl: './list-general-exams.component.html',
  styleUrls: ['./list-general-exams.component.scss']
})
export class ListGeneralExamsComponent extends CanDeactivateComponent implements OnInit {
  exams: FetchedGeneralExam[];
  currentPage: any;
  config: PaginationConfig;

  constructor(private service: GeneralExamService,
    private dlgService: NbDialogService,
    private cdr: ChangeDetectorRef) { 
      super()
    }

  ngOnInit() {
    this.reloadData();
  }

  private reloadData(pageNumber = 1) {
    this.service.getAll(pageNumber).pipe(tap(data => {
      const formatter = new Formatter();
      const datas = formatter.deserialize(data);
      this.exams = datas.data;
      this.generatePagination(datas.meta.pagination);
    }))
      .subscribe();
  }

  private generatePagination(pagination) {
    this.currentPage = pagination.current_page;
    // this.pagination.numbers = [];
    this.config = {
      currentPage: pagination.current_page,
      itemsPerPage: pagination.per_page,
      totalItems: pagination.total
    };
  }

  onPageChange(pageNumber) {
    if (pageNumber !== this.currentPage)
      this.reloadData(pageNumber);
  }

  delete(id) {
    let ref = this.dlgService.open(ConfirmDialogComponent, { context: { data: { message: "Are you sure you wanna delete this?", action: 'danger' } } });
    ref.onClose.subscribe(result => {
      if (result == true) {
        this.service.deleteExam(id).subscribe(r => this.service.getAll(1).subscribe(result => this.exams = result));
      }
    })
  }
}
