import { Component, OnInit, Input } from '@angular/core';
import { TrueFalseQuestion } from '../../../../../models/MasterQuestion';
import { LanguageConfig } from '../../../../../models/LanguageConfig';

@Component({
  selector: 'view-true-false',
  templateUrl: './view-true-false.component.html',
  styleUrls: ['./view-true-false.component.scss']
})
export class ViewTrueFalseComponent implements OnInit {

  @Input() languageConfig: LanguageConfig;
  @Input() question: TrueFalseQuestion;

  constructor() { }

  ngOnInit() {
  }

}
