import { Component } from '@angular/core';
@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">{{"created_by"|translate}} <b><a href="" target="_blank">{{"application_name" | translate}}</a></b> 2019</span>
  `,
})
export class FooterComponent {

}
