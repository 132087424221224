import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { GeneralExamService } from '../../../services/general-exam.service';
import { ExamStudent } from '../../../models/general-exam';
import { NbDialogService } from '@nebular/theme';
import { ConfirmDialogComponent } from '../../../pages/partials/confirm-dialog/confirm-dialog.component';
import {ActivatedRoute} from "@angular/router";
import { CanDeactivateComponent } from '../../../can-deactivate-component';

@Component({
  selector: 'general-exam-students',
  templateUrl: './general-exam-students.component.html',
  styleUrls: ['./general-exam-students.component.scss']
})
export class GeneralExamStudentsComponent extends CanDeactivateComponent implements OnInit {
  students: ExamStudent[];
  id: number;

  constructor(private service: GeneralExamService,
    private dlgService: NbDialogService,
    private cdr: ChangeDetectorRef,
  private activatedRoute: ActivatedRoute
  ) {
    super()
   }

  ngOnInit() {
    this.id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));

    this.service.getExamStudents(this.id ).subscribe(result => {
      this.students = result;
      this.cdr.detectChanges();
    });
  }


}
