import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ImageService {

  constructor(private http: HttpClient) { }

  public uploadImage(images: File[]): Observable<Response> {
    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
      const image = images[i];

      // Check the file type.
      if (!image.type.match('image.*')) {
        continue;
      }

      // Add the file to the request.
      formData.append('media[]', image);
    }

    return this.http.post<any>(`${environment.apiUrl}/media`, formData);
  }
}
