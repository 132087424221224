import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'attributeTypePipe',
    pure: false
})
export class attributeTypePipe implements PipeTransform {
    transform(attributes: any[], filter: any[]): any {
        if (!attributes || !filter) {
            return attributes;
        }

        return attributes.filter(attribute => filter.includes(attribute.value.data_type))
    }
}