import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'link-video-note',
  templateUrl: './link-video-note.component.html',
  styleUrls: ['./link-video-note.component.scss']
})
export class LinkVideoNoteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
