import {Component, OnInit, OnDestroy, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import { Formatter } from 'sarala-json-api-data-formatter';

import {DiscriminationQuestionReportService} from '../../../services/discrimination-question-report.service';
import { Fetchedsubject } from '../../../models/DiscriminationQuestionReport.model';
import {PaginationConfig} from '../../../models/PaginationConfig';
import {NbDialogService} from '@nebular/theme';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'ngx-discrimination-question-reports',
  templateUrl: './discrimination-question-reports-list.component.html',
  styleUrls: ['./discrimination-question-reports-list.component.scss'],
})
export class DiscriminationQuestionReportsListComponent implements OnInit {
  subjects: Fetchedsubject[];
  currentPage: any;
  config: PaginationConfig;

  constructor(private service: DiscriminationQuestionReportService,
              private dlgService: NbDialogService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.reloadData();
  }

  private reloadData(pageNumber = 1) {
    this.service.getAll(pageNumber).pipe(tap(data => {
      const formatter = new Formatter();
      const datas = formatter.deserialize(data);
      this.subjects = datas.data;
      this.generatePagination(datas.meta.pagination);
    }))
      .subscribe();
  }

  private generatePagination(pagination) {
    this.currentPage = pagination.current_page;
    // this.pagination.numbers = [];
    this.config = {
      currentPage: pagination.current_page,
      itemsPerPage: pagination.per_page,
      totalItems: pagination.total,
    };
  }

  onPageChange(pageNumber) {
    if (pageNumber !== this.currentPage)
      this.reloadData(pageNumber);
  }


}
