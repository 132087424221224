export class LanguageConfig {
    curretLang: string;
    
    get alignClass(): {
        [key: string]: boolean;
    } {
        return {
            ltr: this.curretLang === 'en',
            rtl: this.curretLang !== 'en'
        };
    }

    constructor(currentLang: string) {
        this.curretLang = currentLang;
    }
}

export class TaskFormConfig {
    languageConfig: LanguageConfig;
    isDone: boolean;
    
    constructor(languageConfig: LanguageConfig) {
        this.languageConfig = languageConfig;
    }
}
