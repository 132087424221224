import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// import {SectionstructureformatsComponent} from '../../../components/structureformats/sectionstructureformats/sectionstructureformats.component';
// theme module
import {
  NbButtonModule, NbCardModule, NbChatModule,
  NbDialogModule, NbIconModule, NbLayoutModule,
  NbMenuModule, NbSelectModule, NbSidebarModule, NbTabsetModule, NbToastrModule, NbWindowModule, NbAlertModule, NbCheckboxModule, NbSpinnerModule
} from '@nebular/theme';
import { QuillModule } from 'ngx-quill';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AlertModule } from '../../../auth/alert/alert.module';
import { TranslateModule } from '@ngx-translate/core';
import { ResourceComponent } from './resource/resource.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CKEditorModule } from 'ckeditor4-angular';
import { PartialsModule } from '../../../pages/partials/partials.module';
import { SectionFormComponent } from './section-form/section-form.component';
import { SubjectEditComponent } from './subject-edit.component';


// const quillToolbarConfigs: any = [
//   ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
//   ['blockquote', 'code-block'],

//   [{ 'header': 1 }, { 'header': 2 }],               // custom button structure
//   [{ 'list': 'ordered' }, { 'list': 'bullet' }],
//   [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
//   [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
//   [{ 'direction': 'rtl' }],                         // text direction

//   [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
//   [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

//   [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
//   [{ 'font': [] }],
//   [{ 'align': [] }],

//   ['clean'],                                         // remove formatting button
// ];

@NgModule({
  declarations: [SubjectEditComponent, SectionFormComponent, ResourceComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DragDropModule,
    AlertModule,
    NbLayoutModule,
    NbAlertModule,
    NbSidebarModule, // NbSidebarModule.forRoot(), //if this is your app.module
    NbButtonModule,
    NbTabsetModule,
    CKEditorModule,
    NbSpinnerModule,
    NbSelectModule,
    NbCheckboxModule,
    NbIconModule,
    NbSidebarModule.forRoot(),
    TranslateModule.forChild(),
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    NbTabsetModule,
    NbCardModule,
    PartialsModule,
    // QuillModule.forRoot({
    //   modules: {
    //     syntax: false,
    //     toolbar: quillToolbarConfigs,
    //   },
    // }),
  ],
  exports: [
    SectionFormComponent
  ]
})
export class SubjectEditModule {
}
