import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {Formatter} from 'sarala-json-api-data-formatter';

import {FetchedGeneralExam} from '../../../models/general-exam';
import {PaginationConfig} from '../../../models/PaginationConfig';
import {NbDialogService} from '@nebular/theme';
import {tap} from 'rxjs/operators';
import {DiscriminationQuestionReportService} from '../../../services/discrimination-question-report.service';
import {GeneralExamReportQuestion} from '../../../models/DiscriminationQuestionReport.model';
import {ActivatedRoute} from '@angular/router';
import { CanDeactivateComponent } from '../../../can-deactivate-component';

@Component({
  selector: 'ngx-discrimination-question-reports-subject',
  templateUrl: './discrimination-question-reports-subject.component.html',
  styleUrls: ['./discrimination-question-reports-subject.component.scss'],
})
export class DiscriminationQuestionReportsSubjectComponent extends CanDeactivateComponent implements OnInit {
  generalExamReportQuestions: GeneralExamReportQuestion[];
  currentPage: any;
  config: PaginationConfig;
  id: number;
  spinner: boolean;

  constructor(private service: DiscriminationQuestionReportService,
              private dlgService: NbDialogService,
              private cdr: ChangeDetectorRef,
              private activatedRoute: ActivatedRoute,
  ) {
    super()
  }

  ngOnInit() {
    this.spinner = true;
    this.reloadData();
  }

  private reloadData(pageNumber = 1) {
    this.id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    this.service.getListReportsById(this.id, pageNumber).pipe(tap(data => {

      this.generalExamReportQuestions = data.data;
      this.generatePagination(data.meta.pagination);
      this.spinner = false;

    }))
      .subscribe();
  }

  private generatePagination(pagination) {
    this.currentPage = pagination.current_page;
    // this.pagination.numbers = [];
    this.config = {
      currentPage: pagination.current_page,
      itemsPerPage: pagination.per_page,
      totalItems: pagination.total,
    };
  }

  onPageChange(pageNumber) {
    if (pageNumber !== this.currentPage) {
      this.reloadData(pageNumber);
    }
  }


}
