import { ResourceSubjectFormatSubject } from './resource-subject-format-subject';

export class SubjectFormatSubject {
    id: number;
    title: string;
    description: string;
    questions_count?: number;
    is_active: string;
    parent_subject_format_id: string;
    subject_id: string;
    resource_subject_format_subjects: ResourceSubjectFormatSubject[];
    children: SubjectFormatSubject[];

    public static CreateDummy(): any {
        let k = {
            id: 1,
            title: 'subj_1',
            parent_subject_format_id: 'test',
            subjectFormatSubjects: {
                data: [
                    {
                        id: 11,
                        title: 'subj_11',
                        parent_subject_format_id: '1',
                        subjectFormatSubjects: {
                            data: [

                            ]
                        },
                        children: []
                    },
                    {
                        id: 12,
                        title: 'subj_12',
                        parent_subject_format_id: '1',
                        subjectFormatSubjects: {
                            data: [
                                {
                                    id: 121,
                                    title: 'subj_121',
                                    parent_subject_format_id: '12',
                                    subjectFormatSubjects: {
                                        data: [

                                        ]
                                    },
                                    children: []
                                }
                            ]
                        },
                        children: []
                    },
                    {
                        id: 13,
                        title: 'subj_13',
                        parent_subject_format_id: '1',
                        subjectFormatSubjects: {
                            data: [
                                {
                                    id: 131,
                                    title: 'subj_131',
                                    parent_subject_format_id: '13',
                                    subjectFormatSubjects: {
                                        data: [

                                        ]
                                    },
                                    children: []
                                },
                                {
                                    id: 132,
                                    title: 'subj_132',
                                    parent_subject_format_id: '13',
                                    subjectFormatSubjects: {
                                        data: [
                                            {
                                                id: 1321,
                                                title: 'subj_1321',
                                                parent_subject_format_id: '132',
                                                subjectFormatSubjects: {
                                                    data: [

                                                    ]
                                                },
                                                children: []
                                            }
                                        ]
                                    },
                                    children: []
                                }
                            ]
                        },
                        children: []
                    }
                ]
            },
            children: []
        };
        return k;
    }
}

export function getDifficultyLevelId(difficulty_level: string | number): number {
    if (typeof difficulty_level === 'number') {
        return difficulty_level;
    }
    switch (difficulty_level) {
        case 'Easy':
            return 10;
        case 'Medium':
            return 11;
        default:
            return 12;
    }
}

