import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SubjectFormatSubject } from '../../../models/subject-format-subject';

@Component({
  selector: 'single-section',
  templateUrl: './single-section.component.html',
  styleUrls: ['./single-section.component.scss']
})
export class SingleSectionComponent implements OnInit {

  @Input() selected: number;
  @Input() section: SubjectFormatSubject;
  @Output() clicked: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  onClick(id: number) {
    this.clicked.emit(id);
  }
}
