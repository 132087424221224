
import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { NbDialogService, NbDialogRef, NbToastrService } from '@nebular/theme';
import { QuestionReportService } from '../../../services/question-report.service';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'ngx-question-report',
  template: `
  <nb-card>
    <nb-card-body>

        <ng-container style="width:100%">
            <form (ngSubmit)="sendRequest()" #form="ngForm" autocomplete="nope">

                <div class="row" style="min-width:100%">
                    <div class="col-md-12 m-b">
                        <h6 class="field-label" [ngClass]="curretLang==='en' ? 'ltr' : 'rtl'">
                            {{ "question-report-notes" | translate }}:</h6>
                        <textarea name="note" [(ngModel)]="report.note" #note="ngModel" class="form-control"
                            required></textarea>
                            <div class="form-control-feedback" *ngIf="note?.errors && (note?.dirty || note?.touched)">
                            <div style="color:red" *ngIf="note?.errors?.required">{{ "question-report-notes" | translate }} {{"view_question_required-meaage"| translate }}</div>
                             </div>
                    </div>
                    <div class="col-md-6 m-b">
                        <h6 class="field-label" [ngClass]="curretLang==='en' ? 'ltr' : 'rtl'">
                            {{ "question-report-duedate" | translate }}:</h6>
                        <input type="text" name="due_date" id="due_date" pattern="^[0-9]*$" [(ngModel)]="report.due_date" #due_date="ngModel"
                               class="form-control" required   />
                               <div class="form-control-feedback" *ngIf="due_date?.errors && (due_date?.dirty || due_date?.touched)">
                               <div style="color:red" *ngIf="due_date?.errors?.required">{{ "question-report-duedate" | translate }} {{"view_question_required-meaage"| translate }}</div>
                               <div  style="color:red" *ngIf="due_date?.errors?.pattern">{{"view_question_valid-meaage"| translate}} {{ "question-report-duedate" | translate }}</div>
                               </div>

                    </div>
                     <div class="form-group col-md-12 m-b">
                        <button [disabled]="!form.valid" type="submit" class="btn btn-primary">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            {{ "question-report-report" | translate }}
                        </button>
                        <button type="button" (click)="close()" style="margin: 10px;"  class="btn btn-danger">
                         {{ "question-report-cancel" | translate }}
                    </button>
                    </div>
                 </div>
            </form>
        </ng-container>

    </nb-card-body>
</nb-card>
`,
  styleUrls: []
})
export class QuestionReportComponent {
  loading = false;
  curretLang = this.translate.getDefaultLang();
  report = {
    due_date: '',
    note: ''
  };
  questionId: any;

  constructor(private qestionReportService: QuestionReportService, private translate: TranslateService, private router: Router, private toastrService: NbToastrService, protected dialogRef: NbDialogRef<QuestionReportComponent>) {
  }


  sendRequest() {
    // debugger;
    this.loading = true;
    this.qestionReportService.ReportQuestionReport(this.questionId, this.report.note, this.report.due_date)
      .subscribe(data => {
        this.loading = false;
        this.toastrService.show(
          'Success',
          data.meta.message,
          { status: 'success' });
        this.dialogRef.close();
      });

  }
  close() {
    this.dialogRef.close();
  }


}
