import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { QuestionData, mapToMasterQuestion } from '../../../../../models/QuestionData';
import { LanguageConfig } from '../../../../../models/LanguageConfig';
import { MatchingQuestion } from '../../../../../models/MasterQuestion';

@Component({ selector: 'matching', templateUrl: './matching.component.html', styleUrls: ['./matching.component.scss'] })

export class MatchingComponent implements OnInit {

  matchingQuestion: MatchingQuestion;
  @Input() questionData: QuestionData;
  @Input() languageConfig: LanguageConfig;
  @Input() selectedQuestions: number[] = [];
  @Output() questionSelected: EventEmitter<void> = new EventEmitter<void>();
  @Output() questionRemoved: EventEmitter<void> = new EventEmitter<void>();
  
  constructor() {
  }

  ngOnChanges(changes): void {
    this.matchingQuestion = <MatchingQuestion>mapToMasterQuestion(this.questionData);
  }

  ngOnInit() {
  }

  selectQuestion() {
    this.questionSelected.emit();
  }

  removeQuestion() {
    this.questionRemoved.emit();
  }
}
