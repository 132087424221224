import { Component, OnInit, Input } from '@angular/core';
import { CompleteQuestion } from '../../../../../models/MasterQuestion';
import { LanguageConfig } from '../../../../../models/LanguageConfig';

@Component({
  selector: 'view-completle',
  templateUrl: './view-completle.component.html',
  styleUrls: ['./view-completle.component.scss']
})
export class ViewCompletleComponent implements OnInit {

  @Input() languageConfig: LanguageConfig;
  @Input() question: CompleteQuestion;

  constructor() { }

  ngOnInit() {
  }

}
