import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

interface ConfirmDialogData {
  message: string;
  action: 'primary' | 'danger';
}

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  @Input() data: ConfirmDialogData;

  constructor(protected dialogRef: NbDialogRef<ConfirmDialogComponent>) { }

  ngOnInit() {
  }

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
