import { Formatter } from 'sarala-json-api-data-formatter';
import { NewSubjectService, Resource } from './../../../../services/subject.service.new';
import { NestedFormData, NewSubjectFormService, MinimalNestedFormData } from './../../subjectForm.new.service';
import { Component, OnInit, Input, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss']
})
export class ResourceComponent implements OnInit, OnDestroy {
  @Input() minimalFormData: MinimalNestedFormData;
  formData: NestedFormData;
  @Input() parentSection: NestedFormData;
  @ViewChild('titleEl', { static: false }) titleEl: ElementRef;

  title: string;
  resourceID: string;
  attributes: any;
  curretLang: string;

  resourceTemplatesSubscription: Subscription;
  isNew: boolean = false;
  isExpanded: boolean;

  constructor(private subjectService: NewSubjectService,
    private toastr: NbToastrService,
    public subjectFormService: NewSubjectFormService, private resourceEl: ElementRef,
    private translate: TranslateService) { }

  ngOnInit() {
    this.setIds();
    this.setNewState();
    this.curretLang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      console.log(event)
      this.curretLang = event.lang;
    });
    this.resourceTemplatesSubscription = this.subjectService.resourceTemplates$.subscribe((resourceTemplates) => {
      if (resourceTemplates.length) {
        const resourceTemplate = resourceTemplates.find(template => template.id == this.minimalFormData.resource_id)
        if (resourceTemplate) {
          this.attributes = resourceTemplate.learningResourceAcceptCriteria.data
          this.title = resourceTemplate.title;
          delete this.attributes.type;
          delete this.attributes.id;
        }
      }
    })
  }

  expandForm() {
    this.subjectService.getResource(this.minimalFormData.id).pipe(
      map(resource => this.subjectFormService.convert_MinimalFormData_resource(this.minimalFormData, resource))
    ).subscribe(r => {
      this.formData = r;
      this.isExpanded = true;
    });
  }

  collapseForm() {
    this.isExpanded = false;
  }

  //#region dragshit
  onDragStart(event) {
    this.subjectFormService.OnDragStartComponent(event, this.parentSection, this.minimalFormData, this.titleEl, this.resourceEl)
  }

  onDragEnd() {
    this.subjectFormService.onDragEndComponent(this.resourceEl)
  }
  //#endregion

  removeResource() {
    this.subjectFormService.removeComponent(this.parentSection, this.minimalFormData.id, !this.isNew);
  }

  submit() {
    if (this.parentSection.type == 'subject_format_subject') {
      this.formData['parent_subject_format_id'] = this.parentSection.id;
    }
    this.subjectService.createUpdateResource(this.formData, this.parentSection.id, this.attributes).subscribe(r => {
      this.toastr.success('Success!');
      const value = this.formData.form.value;
      this.formData.form.reset(value);
      const formatter = new Formatter();
      const resource: Resource = formatter.deserialize(r);
      this.setIds(resource.id, resource.learningResourceAcceptCriteria.data.id);
      this.setNewState();
    });
  }

  private setIds(id?, acceptanceCriteriaId?) {
    if (id) {
      this.minimalFormData.id = id;
      this.formData.id = id;
    }
    if (acceptanceCriteriaId) {
      this.formData.acceptanceCriteriaId = acceptanceCriteriaId;
    }
    this.resourceID = this.subjectFormService.createComponentId(this.minimalFormData.id, 'resource');
  }

  private setNewState() {
    this.isNew = this.minimalFormData.id != null && this.minimalFormData.id.includes('new');
    if (this.isNew) {
      this.formData = <any>this.minimalFormData;
      this.isExpanded = true;
    }
  }

  ngOnDestroy(): void {
    console.log(this.minimalFormData.id + ' destroyed')
    this.resourceTemplatesSubscription.unsubscribe()
  }
}
