import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'deleteKeysPipe',
    pure: false
})
export class deleteKeysPipe implements PipeTransform {
    transform(attributes: any[], filter: any[]): any {
        if (!attributes || !filter) {
            return attributes;
        }
        return attributes.filter(attribute => filter.indexOf(attribute.key) === -1);
    }
}
