import { Formatter } from 'sarala-json-api-data-formatter';
import { Component, OnInit } from '@angular/core';
import { ImageService } from '../../services/image.service';

@Component({
  selector: 'ngx-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})

export class ImageUploadComponent implements OnInit {
    formatter = new Formatter();
    selectedFiles: File[] ;
    constructor(private imageService: ImageService) {
     }
    ngOnInit() {}

    onSelectedFile(event) {
        this.selectedFiles =  event.target.files;
     //   this.selectedFiles = this.formatter.serialize(this.selectedFile );
        this.imageService.uploadImage(this.selectedFiles).subscribe(
          (res) => {
            alert('success');
          },
          (err) => {
            console.log(this.selectedFiles);
              err;
          },
        );
    }
}
