import { Component, OnInit, Input } from '@angular/core';
import { DragDropQuestion } from '../../../../../models/MasterQuestion';
import { LanguageConfig } from '../../../../../models/LanguageConfig';

@Component({
  selector: 'view-drag-drop',
  templateUrl: './view-drag-drop.component.html',
  styleUrls: ['./view-drag-drop.component.scss']
})
export class ViewDragDropComponent implements OnInit {

  @Input() languageConfig: LanguageConfig;
  @Input() questionData: DragDropQuestion;

  constructor() { }

  ngOnInit() {
  }

}
