import { CanDeactivateComponent } from './../../../can-deactivate-component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Formatter } from 'sarala-json-api-data-formatter';
import { SubjectD } from '../../../models/subject';
import { SubjectService } from '../../../services/subject.service';
import { TaskService } from '../../../services/task.service';
import { ActivatedRoute } from '@angular/router';


(window as any).global = window;


@Component({
  selector: 'ngx-taskperformancelist',
  templateUrl: './task-performance-detail.component.html',
  styleUrls: ['./task-performance-detail.component.scss'],
})
export class TaskPerformanceDetailComponent extends CanDeactivateComponent implements OnInit, OnDestroy {

  attributes: any[] = [];
  taskId: any;
  curretLang: any;
  taskDetail: any;
  langChange: Subscription;
  constructor(private taskService: TaskService,
    private toastrService: NbToastrService,
    private translate: TranslateService, private activatedRout: ActivatedRoute) {
      super();
    this.activatedRout.params.subscribe(params => {
      if (params.taskId) {
        this.taskId = params.taskId;
        this.reloadData();
      }
    });
    this.curretLang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.curretLang = event.lang;
    });
  }

  ngOnInit() {
    this.langChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      console.log(event)
      this.reloadData();
    });

  }

  reloadData() {
    this.taskService.getTaskPerformanceDetail(this.taskId)
      .subscribe(data => {
        const formatter = new Formatter();
        const datas = formatter.deserialize(data);
        this.taskDetail = formatter.deserialize(data);
      });
  }
  ngOnDestroy() {
    this.langChange.unsubscribe();
  }


}
