import { TaskPerformanceService } from '../../services/task-performance.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Formatter } from 'sarala-json-api-data-formatter';
import { SubjectD } from '../../models/subject';
import { SubjectService } from '../../services/subject.service';
import { TaskService } from '../../services/task.service';
import { IComponentCanDeactivate } from '../../auth/helpers';
import { CanDeactivateComponent } from '../../can-deactivate-component';


(window as any).global = window;


@Component({
  selector: 'ngx-taskperformance',
  templateUrl: './task-performance.component.html',
  styleUrls: ['./task-performance.component.scss'],
})
export class TaskPerformanceComponent extends CanDeactivateComponent implements OnInit, OnDestroy  {

  constructor(private taskPerformanceService: TaskPerformanceService, private translate: TranslateService) {
    super();
    this.reloadData(1);
  }
  langChange: Subscription;
  data: [];
  tableKeys: any;

  pagination;


  ngOnInit() {
    this.tableKeys = this.taskPerformanceService.getPerformance.list.tableKeys;
    this.data = [];
    this.langChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.reloadData(this.pagination.current_page);
    });
  }

  reloadData(pagenumber) {
    this.taskPerformanceService.getList(pagenumber)
      .subscribe(list => {
        this.data = list.data.map(item => {
          item.routeId = item.user.data.id;
          item.content_author = `${item.user.data.first_name}  ${item.user.data.last_name}`;
          return item;
        });
        this.pagination = list.pagination;
      });
  }


  ngOnDestroy() {
    this.langChange.unsubscribe();
  }

}
