import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AlertModule } from './alert/alert.module';
import { LoginComponent } from './login';
import { RequestPaswordComponent } from './request-password';
import { ResetPaswordComponent } from './reset-password';
import { ThemeModule } from '../@theme/theme.module';
import { AuthHeaderComponent } from './auth-header.component';
import { RouterModule } from '@angular/router';

import {
  NbAlertModule,
  NbButtonModule, NbCardModule,
  NbCheckboxModule,
  NbInputModule,
} from '@nebular/theme';

@NgModule({
  declarations: [LoginComponent,RequestPaswordComponent,ResetPaswordComponent,AuthHeaderComponent],
  imports: [
    CommonModule,
    NbInputModule,
    ReactiveFormsModule,
    TranslateModule.forRoot(),
    AlertModule,
    ThemeModule,
    RouterModule,

    CommonModule,
    RouterModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    NbCardModule,
  ],
  exports: [
    LoginComponent,
    RequestPaswordComponent,
    ResetPaswordComponent,
  ],
})
export class AuthModule { }
