import { BaseService } from './../../../services/base.service';
import { Component, OnDestroy, Input } from '@angular/core';
import { NbLayoutDirectionService, NbLayoutDirection } from '@nebular/theme';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { OurEduLocalStorageService } from '../../../services/our-edu-local-storage.service';

@Component({
  selector: 'ngx-layout-direction-switcher',
  template: `
    <ngx-switcher
      [firstValue]="directions.RTL"
      [secondValue]="directions.LTR"
      [firstValueLabel]="'Arabic'"
      [secondValueLabel]="'English'"
      [value]="currentDirection"
      (valueChange)="toggleDirection($event)"
      (valueChange)="switchLanguage()"
      [vertical]="vertical">
    </ngx-switcher>
  `,
})
export class LayoutDirectionSwitcherComponent implements OnDestroy {

  protected destroy$ = new Subject<void>();

  directions = NbLayoutDirection;
  currentDirection: NbLayoutDirection;

  @Input() vertical: boolean = false;

  constructor(private directionService: NbLayoutDirectionService,
    protected translateService: TranslateService, protected baseService: BaseService) {
    this.directionService.onDirectionChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe(newDirection => this.currentDirection = newDirection);
    const currentLang = OurEduLocalStorageService.getCurrentLanguage();
    // translateService.setDefaultLang(currentLang);
    this.currentDirection = currentLang == "ar" ? NbLayoutDirection.RTL : NbLayoutDirection.LTR;
    try {
      this.directionService.setDirection(this.currentDirection);
    }
    catch{

    }
  }

  toggleDirection(newDirection) {
    this.directionService.setDirection(newDirection);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  switchLanguage() {
    if (this.currentDirection === 'ltr') {

      OurEduLocalStorageService.SetCurrentLanguage('en');
      this.baseService.setBaseUrl('en');
      this.translateService.use('en');

    } else {

      OurEduLocalStorageService.SetCurrentLanguage('ar');
      this.baseService.setBaseUrl('ar');
      this.translateService.use('ar');
    }
  }
}
