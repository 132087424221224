import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OurEduLocalStorageService {

  constructor() { }

  static getUserData() {
    if (localStorage.getItem("userData")) {
      return JSON.parse(localStorage.getItem("userData"));
    } else {
      return false;
    }
  }

  static saveUserData(userData) {
    localStorage.setItem("userData", JSON.stringify(userData));
  }

  static removeUserData() {
    localStorage.removeItem("userData");
  }

  static getCurrentLanguage() {
    if (localStorage.getItem("lang")) {
      return localStorage.getItem("lang");
    } else {
      return 'ar';
    }
  }

  static SetCurrentLanguage(lang) {
    localStorage.setItem("lang", lang);
  }

  private static _submitting: boolean = false;
  static setFormSubmitting() {
    this._submitting = true;
  }

  static clearFormSubmitting() {
    this._submitting = false;
  }

  static getFormSubmitting() {
    console.log('%ci was asked for it, it = ', 'color: orange;', this._submitting);
    return this._submitting;
  }
}
