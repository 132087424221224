import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

declare global {
  interface Window {
    com: any;
  }
}

@Directive({
  selector: '[appMath]'
})
export class MathDirective implements OnInit, OnChanges, OnDestroy {
  private alive$ = new Subject<boolean>();

  @Input()
  private appMath: string;
  private readonly _el: HTMLElement;

  constructor(
    private el: ElementRef) {
    this._el = el.nativeElement as HTMLElement;
  }

  ngOnInit(): void {
    this.render(this._el, this.appMath);
  }

  render(element: HTMLElement, math?: string): void {
    element.innerHTML = math;
    window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () {
      console.log('======> you can have it back!');
    })
  }


  ngOnChanges(changes: SimpleChanges): void {
 }

  ngOnDestroy(): void {
    this.alive$.next(false);
  }
}