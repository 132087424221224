import { NbToastrService } from "@nebular/theme";
import { map } from "rxjs/operators";
import { NewSubjectService } from "../../../../services/subject.service.new";
import {
  NewSubjectFormService,
  SubjectFormData,
  NestedFormData,
  MinimalNestedFormData,
} from "../../subjectForm.new.service";
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ElementRef,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import { Subscription } from "rxjs";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import * as ClassicEditor from "../../../../../assets/js/ck-editor-math-type/ckeditor.js";
import { ActivatedRoute } from "@angular/router";
import { environment } from "../../../../../environments/environment";
import { CKEDITOR_CONFIG } from "../../../../util";

@Component({
  selector: "section-form",
  templateUrl: "./section-form.component.html",
  styleUrls: ["./section-form.component.scss"],
})
export class SectionFormComponent implements OnInit, OnDestroy {
  ckeditorConfig: any = CKEDITOR_CONFIG;

  @Input() minimalFormData: MinimalNestedFormData;
  formData: NestedFormData;
  @Input() parentSection: SubjectFormData | NestedFormData;
  @ViewChild("titleEl", { static: false }) titleEl: ElementRef;
  @Input() isClone = false;
  isNew = false;
  isExpanded = false;

  sectionID: string;
  resourceTemplates: any;
  curretLang: string;
  resourceTemplatesSubscription: Subscription;
  public Editor = ClassicEditor;
  public baseApiURL = environment.mediaBaseUrl + "en/media/upload";

  subjectId: string;
  refreshIsNeeded: boolean;

  constructor(
    public subjectFormService: NewSubjectFormService,
    private subjectService: NewSubjectService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private toastr: NbToastrService,
    private sectionEl: ElementRef,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.curretLang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      console.log(event);
      this.curretLang = event.lang;
    });
    this.subjectId = this.route.snapshot.paramMap.get("subjectId");
    this.setNewState();
    this.setIds();

    this.resourceTemplatesSubscription =
      this.subjectService.resourceTemplates$.subscribe((resourceTemplates) => {
        this.resourceTemplates = resourceTemplates;
      });
  }

  expandForm() {
    this.subjectService
      .getMinimalSection(this.minimalFormData.id)
      .pipe(
        map((sec) =>
          this.subjectFormService.convert_MinimalFormData_section(
            this.minimalFormData,
            sec
          )
        )
      )
      .subscribe((r) => {
        this.formData = r;
        this.isExpanded = true;
      });
  }

  collapseForm() {
    this.isExpanded = false;
  }

  createSection() {
    this.subjectFormService.addNewSection(this.formData.nestedForms);
  }

  createResource(resourceIndex: number) {
    this.subjectFormService.addNewResource(this.formData.nestedForms, {
      type: this.resourceTemplates[resourceIndex].id,
      slug: this.resourceTemplates[resourceIndex].slug,
    });
  }

  //#region dragshit
  onDragStart(event) {
    this.subjectFormService.OnDragStartComponent(
      event,
      this.parentSection,
      this.minimalFormData,
      this.titleEl,
      this.sectionEl
    );
  }
  onDragEnd() {
    this.subjectFormService.onDragEndComponent(this.sectionEl);
  }
  onDragOver(event) {
    event.preventDefault();
    this.subjectFormService.onDragOverComponent(event, this.formData);
  }
  onDragLeave(event) {
    this.subjectFormService.removeDragOverStyleIfExists(event);
  }
  onDrop(event) {
    event.preventDefault();
    this.subjectFormService.onDropComponent(
      event,
      this.formData,
      this.subjectId
    );
    this.refreshIsNeeded = !this.refreshIsNeeded;
  }
  //#endregion

  removeSection() {
    this.subjectFormService.removeComponent(
      this.parentSection,
      this.minimalFormData.id,
      !this.isNew
    );
  }

  submit() {
    if (this.parentSection.type == "subject_format_subject") {
      this.formData["parent_subject_format_id"] = this.parentSection.id;
    }
    this.subjectService
      .createUpdateSingleSection(this.formData, this.subjectId)
      .subscribe((r) => {
        this.toastr.success("Success!");
        var value = this.formData.form.value;
        this.formData.form.reset(value);
        this.minimalFormData.title = value.title;
        this.setIds(r.data.id);
        this.setNewState();
      });
  }

  resetForm() {
    if (
      confirm(
        "This action will reset any unsaved data including nested sections/ resources. Ok?"
      )
    ) {
      this.expandForm();
    }
  }

  private setIds(id?) {
    if (id) {
      this.minimalFormData.id = id;
      this.formData.id = id;
    }
    this.sectionID = this.subjectFormService.createComponentId(
      this.minimalFormData.id,
      "section"
    );
  }

  private setNewState() {
    this.isNew =
      this.minimalFormData.id != null &&
      this.minimalFormData.id.includes("new");
    if (this.isNew || this.isClone) {
      this.formData = <any>this.minimalFormData;
      this.isExpanded = true;
    }
  }

  ngOnDestroy(): void {
    console.log(this.minimalFormData.id + " destroyed");
    this.resourceTemplatesSubscription.unsubscribe();
  }
}
