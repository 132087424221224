import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { QuestionData } from '../../../models/QuestionData';
import { MasterQuestion } from '../../../models/MasterQuestion';
import { LanguageConfig } from '../../../models/LanguageConfig';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { PaginationConfig } from '../../../models/PaginationConfig';

@Component({
  selector: 'preview-questions',
  templateUrl: './preview-questions-dialog.component.html',
  styleUrls: ['./preview-questions.scss']
})
export class PreviewQuestionsDialogComponent implements OnInit {

  @Input() questions: MasterQuestion[];
  languageConfig: LanguageConfig;
  @Input() config: PaginationConfig;
  @Input() pageChange: EventEmitter<number>;

  constructor(protected dialogRef: NbDialogRef<PreviewQuestionsDialogComponent>, private translate: TranslateService) {

  }

  ngOnInit() {
    this.setupLanguage();
  }

  close() {
    this.dialogRef.close();
  }

  onPageChange(pageNumber) {
    this.pageChange.emit(pageNumber);
  }

  private setupLanguage() {
    this.languageConfig = new LanguageConfig(this.translate.getDefaultLang());
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.languageConfig = new LanguageConfig(event.lang);
    });
  }
}


