import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode >
      <nb-layout-header fixed *ngIf="logged">
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive start  *ngIf="logged">
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed *ngIf="logged">
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements OnInit {
  constructor(private authentication: AuthenticationService) {

  }
  logged: boolean;
  ngOnInit() {
    this.initialiseLogged();
    this.isLogged();
  }

  initialiseLogged() {
    this.logged = false;
    if (localStorage.getItem("userData")) {
      const token = JSON.parse(localStorage.getItem("userData"))['token']
      this.logged = token ? true : false;
    } else {
      this.logged = false
    }
  }
  isLogged() {

    this.authentication.getCurrentUserSubject.pipe(
      delay(0),

    ).subscribe(data => {
      if (data)
        this.logged = true
      else {
        this.initialiseLogged();
      }
    })
  }
}



