import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Formatter } from 'sarala-json-api-data-formatter';
import { Task } from '../../models/task';
import { TaskService } from '../../services/task.service';

@Component({
  selector: 'ngx-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnInit {
  constructor(private tasksService: TaskService) { }
  tasks: Task[];
  isEmpty: boolean = false;
  loading: boolean = true;
  ngOnInit() {
    this.listTasks();
  }
  listTasks() {

    this.tasksService.getTasks('freeAsigned', 1)
      .subscribe(data => {
        const formatter = new Formatter();
        const datas = formatter.deserialize(data);
        const nTasks = datas.data.map((i: any) => {
          const dateInfo = this.tasksService.getDateInfo(i.created_at);
          return { ...i, ...dateInfo };
        });
        this.tasks = nTasks;
        this.isEmpty = nTasks.length > 0 ? false : true;
      });
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }
}
