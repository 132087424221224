import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Task } from '../models/task';
import { BaseService } from './base.service';
import { OurEduLocalStorageService } from './our-edu-local-storage.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class QuestionReportService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  getQuestionReports(pagenumber): Observable<Task[]> {
    const url = `${BaseService.baseUrl}/sme/questions-report?page=${pagenumber}`;
    return this.http.get<Task[]>(url)
      .pipe(
        tap(task => console.log('fetched reports')),
        catchError(this.handleError('getQuestionReports', [])),
      );
  }

  getQuestionReportsSubject(pagenumber, subjectId): Observable<Task[]> {
    const url = `${BaseService.baseUrl}/sme/questions-report/subject/${subjectId}?page=${pagenumber}`;
    return this.http.get<Task[]>(url)
      .pipe(
        tap(task => console.log('fetched reports subjects')),
        catchError(this.handleError('getQuestionReportsSubject', [])),
      );
  }


  getQuestionReportsQuestion(questionId): Observable<Task[]> {
    const url = `${BaseService.baseUrl}/sme/questions-report/question/${questionId}?`;
    return this.http.get<Task[]>(url)
      .pipe(
        tap(task => console.log('fetched reports questions')),
        catchError(this.handleError('getQuestionReportsQuestions', [])),
      );
  }

  IgnoreQuestionReport(id: number): any {
    const url = `${BaseService.baseUrl + '/sme/questions-report/ignore'}/${id}`;
    const token = OurEduLocalStorageService.getUserData().token;
    const headerDict = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    };
    const httpOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post<any>(url, null, httpOptions);
  }

  ReportQuestionReport(id: number, note, due_date): any {
    const url = `${BaseService.baseUrl + '/sme/questions-report/report'}/${id}`;
    const token = OurEduLocalStorageService.getUserData().token;
    const headerDict = {
      'Content-Type': 'application/vnd.api+json',
      'Accept': 'application/vnd.api+json',
      'Authorization': 'Bearer ' + token
    };
    const httpOptions = {
      headers: new HttpHeaders(headerDict),
    };
    let data = {
      data: {
        "id": null,
        "type": "question_report_tasks",
        "attributes": {
          "note": note,
          "due_date": due_date
        }
      }
    };
    return this.http.post<any>(url, data, httpOptions);
  }


}
