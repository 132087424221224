import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Formatter } from 'sarala-json-api-data-formatter';
import { TaskService } from '../../../services/task.service';
import { Task } from '../../../models/task';
import { QuestionReportService } from '../../../services/question-report.service';
import { Questionsubject } from '../../../models/question-subject';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { NbToastrService, NbDialogService, NbDialogRef } from '@nebular/theme';
import { QuestionReportComponent } from './question-report.Component';

@Component({
  selector: 'ngx-question-detail',
  templateUrl: './question-detail.component.html',
  styleUrls: ['./question-detail.component.scss'],
})
export class QuestionDetailComponent implements OnInit, OnDestroy {
  constructor(private qestionReportService: QuestionReportService
    , private activatedRout: ActivatedRoute, private translate: TranslateService
    , private toastrService: NbToastrService, private router: Router, private dialogService: NbDialogService
  ) {
    this.activatedRout.params.subscribe(params => {
      if (params.questionId) {
        this.questionId = params.questionId;
        this.reloadData();
      }
    });
    this.curretLang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.curretLang = event.lang;
    });
  }
  question: any;
  langChange: Subscription;
  questionId;
  curretLang: string;
  loading = false;

  ngOnInit() {
    const el = document.getElementById('nb-global-spinner');
    if (el) {
      el.style['display'] = 'none';
    }
    this.langChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      console.log(event)
      this.reloadData();
    });
  }

  reloadData() {
    this.loading = true;
    this.qestionReportService.getQuestionReportsQuestion(this.questionId)
      .subscribe(data => {
        const formatter = new Formatter();
        const datas = formatter.deserialize(data);
        this.question = formatter.deserialize(data);
        this.loading = false;
      });
  }

  ignore() {
    this.loading = true;
    this.qestionReportService.IgnoreQuestionReport(this.questionId)
      .subscribe(data => {
        this.loading = false;
        this.toastrService.show(
          'Success',
          data.meta.message,
          { status: 'success' });
        this.router.navigate(['question-reports']);
      });
  }

  open() {
    this.dialogService.open(QuestionReportComponent, {
      context: {
        questionId: this.questionId,
      }
    }).onClose.subscribe((res) => { this.router.navigate(['question-reports']); });
  }
  ngOnDestroy() {
    this.langChange.unsubscribe();
  }



}
