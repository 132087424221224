import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { LanguageConfig } from '../../../../../models/LanguageConfig';
import { QuestionData, mapToMasterQuestion } from '../../../../../models/QuestionData';
import { MultipleChoiceQuestion } from '../../../../../models/MasterQuestion';

@Component({
  selector: 'multiplechoice',
  templateUrl: './multiplechoice.component.html',
  styleUrls: ['./multiplechoice.component.scss']
})
export class MultiplechoiceComponent implements OnChanges {
  
  mcQuestion: MultipleChoiceQuestion;
  @Input() questionData: QuestionData;
  @Input() languageConfig: LanguageConfig;
  @Input() selectedQuestions: number[] = [];
  @Output() questionSelected: EventEmitter<number> = new EventEmitter<number>();
  @Output() questionRemoved: EventEmitter<number> = new EventEmitter<number>();
  get alignClass(): {} {
    return this.languageConfig ? this.languageConfig.alignClass : {};
  }

  constructor() {
  }
  
  ngOnChanges(changes): void {
    this.mcQuestion = <MultipleChoiceQuestion>mapToMasterQuestion(this.questionData);
  }

  ngOnInit() {
  }

  selectQuestion(id){
    this.questionSelected.emit(id);
  }

  removeQuestion(id){
    this.questionRemoved.emit(id);
  }
}
