import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { SubjectService } from './../../../services/subject.service';
import { ImageUploadComponent } from './../../image-upload/image-upload.component';
import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ProfileService } from '../../../services/profile.service';
import { OurEduLocalStorageService } from '../../../services/our-edu-local-storage.service';
import { Formatter } from 'sarala-json-api-data-formatter';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MediaService } from '../../../services/media.service';

@Component({
  selector: 'ngx-profile-update',
  templateUrl: './profile-update.component.html',
  styleUrls: ['./profile-update.component.scss'],
})

export class ProfileUpdateComponent implements OnInit, OnDestroy {
  user;
  passwordObj: any = {
    "old_password": "",
    "password": "",
    "password_confirmation": ""
  };
  matchedPasswords = true;
  errorMsg = "";
  formatter = new Formatter();
  files: any;
  pictureId: any;
  loading: boolean = false;
  data;
  langChange: Subscription;
  showChangePassword: boolean = false;
  showSuccessAlert: boolean = false;
  @Output() userDataChanged: EventEmitter<{}> = new EventEmitter();

  constructor(private profileService: ProfileService,
    private translate: TranslateService,
    private http: HttpClient,
    private media: MediaService) {
    this.reloadData();
  }
  ngOnInit() {
    // const formTemp = document.querySelector('form')
    // var form = document.getElementById('file-form');
    // var fileSelect = document.getElementById('file-select');
    // var uploadButton = document.getElementById('upload-button');
    this.langChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      console.log(event)
      this.reloadData();
    });
    // form.onsubmit = function (event) {
    //   event.preventDefault();
    //   // The rest of the code will go here...
    //   // Get the selected files from the input.
    //   var files = fileSelect['files'];
    //   // Create a new FormData object.
    //   var formData = new FormData();
    //   // Loop through each of the selected files.
    //   for (var i = 0; i < files.length; i++) {
    //     var file = files[i];

    //     // Check the file type.
    //     if (!file.type.match('image.*')) {
    //       continue;
    //     }

    //     // Add the file to the request.
    //     formData.append('media[]', file);
    //   }

    //   // Update button text.
    //   uploadButton.innerHTML = 'Uploading...';

    //   // Set up the request.
    //   var xhr = new XMLHttpRequest();
    //   // Open the connection.
    //   xhr.open('POST', `${environment.apiUrl}/media`, true);
    //   // Set up a handler for when the request finishes.
    //   xhr.onload = function () {
    //     if (xhr.status === 200) {
    //       // File(s) uploaded.
    //       uploadButton.innerHTML = 'Upload';
    //     } else {
    //       alert('An error occurred!');
    //     }
    //   };
    //   // Send the Data.
    //   xhr.send(formData);


    // }

  }
  tempSubmit(event) {
    event.preventDefault();
    const formTemp = document.querySelector('form');
    var form = document.getElementById('file-form');
    var fileSelect = document.getElementById('file-select');
    var uploadButton = document.getElementById('upload-button');
    var files = fileSelect['files'];
    // Create a new FormData object.
    var formData = new FormData();
    // Loop through each of the selected files.
    for (var i = 0; i < files.length; i++) {
      var file = files[i];

      // Check the file type.
      if (!file.type.match('image.*')) {
        continue;
      }

      // Add the file to the request.
      formData.append('media[]', file);
    }

    // Update button text.
    uploadButton.innerHTML = 'Uploading...';
    this.http.post<any>(`${environment.apiUrl}media`, formData).subscribe(data => {
      console.log(data)
      uploadButton.innerHTML = 'Upload';
    })
  }


  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.files = event.target.files;
      this.loading = true;
      (<HTMLInputElement>document.getElementById("myFile")).disabled = true;
      this.media.addNewMedia(this.files).subscribe(filesData => {
        console.log(filesData.data);
        this.loading = false;
        this.user.profile_picture = filesData.data[0].attributes.url;
        this.pictureId = filesData.data[0].id;
        (<HTMLInputElement>document.getElementById("myFile")).disabled = false;
      })

    }
    event.target.value = null;
  }

  reloadData() {
    this.profileService.getCurrentUserData()
      .subscribe(data => {
        const formatter = new Formatter();
        const user_data = formatter.deserialize(data);
        this.user = user_data;
      });
    return this.user;
  }

  onUpdate(formData) {
    this.errorMsg = "";
    let user = { ...this.user };
    delete user.type;
    delete user.name;
    delete user.profile_picture;
    delete user.country_id;
    delete user.id;
    this.data = {
      'data': {
        'id': null,
        'type': 'user',
        'attributes': user,
      }
    };
    if (this.pictureId) {
      let relationshipsObj = { "relationships": { "attach_media": { "data": [{ "type": "attach_media", "id": this.pictureId }] } } };

      let includedObj = { "included": [{ "type": "attach_media", "id": this.pictureId, "attributes": {} }] };

      this.data = { ...this.data, ...includedObj };
      let relationships = { ...this.data.data, ...relationshipsObj };
      this.data.data = relationships;
    }
    this.profileService.updateCurrentUserData(this.data).subscribe(
      (res) => {
        const formatter = new Formatter();
        let updatedProfileData = formatter.deserialize(res);

        let userData = JSON.parse(localStorage.getItem("userData"));

        userData.name = updatedProfileData.name;
        userData.profile_picture = updatedProfileData.profile_picture;

        formatter.serialize(userData);
        OurEduLocalStorageService.saveUserData(userData);
        this.profileService.userDataChanged();
        this.data = {};
        this.pictureId = null;
        this.showSuccessAlert = true;
        setTimeout(() => {
          this.showSuccessAlert = false;
        }, 3000);
      },
      (err) => {
        this.errorMsg = err.error.errors[0].detail;
      },
    );
  }

  updatePassword() {
    this.matchedPasswords = true;
    this.errorMsg = "";
    if (this.passwordObj.password === this.passwordObj.password_confirmation)
      this.matchedPasswords = true;
    else
      this.matchedPasswords = false;
    let data = {
      'data': {
        'id': null,
        'type': 'user',
        'attributes': this.passwordObj,
      }
    };
    if (this.matchedPasswords) {
      this.profileService.updatePassword(data).subscribe(
        (res) => {
          this.showSuccessAlert = true;
          setTimeout(() => {
            this.showSuccessAlert = false;
          }, 3000);
        },
        (err) => {
          console.log(err);
          this.errorMsg = err.error.errors[0].detail;
        },
      );
    }

  }
  ngOnDestroy() {
    this.langChange.unsubscribe();
  }

}
