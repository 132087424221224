import { User } from './../models/user';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

const proxyurl = 'https://cors-anywhere.herokuapp.com/';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends BaseService {
  @Output() getChangedUserData: EventEmitter<{}> = new EventEmitter();


  constructor(private http: HttpClient) {
    super();
  }

  public getCurrentUserData(): Observable<User> {
    return this.http.get<User>(BaseService.baseUrl + '/profile').pipe(
      tap(user => {
        this.formatter.deserialize(user);
      }),
    );

  }

  public updateCurrentUserData(formData) {
    return this.http.post(BaseService.baseUrl + '/profile/update-profile', formData);
  }

  public updatePassword(formData) {
    return this.http.post(BaseService.baseUrl + '/profile/update-password', formData);
  }

  userDataChanged() {
    return this.getChangedUserData.emit(JSON.parse(localStorage.getItem('userData')));
  }
}
