import { Component, OnInit, Input } from '@angular/core';
import { MatchingQuestion } from '../../../../../models/MasterQuestion';
import { LanguageConfig } from '../../../../../models/LanguageConfig';

@Component({
  selector: 'view-matching',
  templateUrl: './view-matching.component.html',
  styleUrls: ['./view-matching.component.scss']
})
export class ViewMatchingComponent implements OnInit {
  
  @Input() languageConfig: LanguageConfig;
  @Input() questionData: MatchingQuestion;

  constructor() { }

  ngOnInit() {
  }

}
