import { filter, map, flatMap, mergeAll } from 'rxjs/operators';
import { ValidationData } from './subject-edit-new/ValidationData';
import { NbToastrService } from '@nebular/theme';
import { MinimalSection, Section, Resource, MinimalResource, NewSubjectService } from './../../services/subject.service.new';
import { Injectable, Renderer2, RendererFactory2, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Subject } from '../../services/subject.service.new';
import { Observable, forkJoin } from 'rxjs';

function getLastComponent(arr: MinimalNestedFormData[]): MinimalNestedFormData {
  return arr.reduce(
    (current, result) => (current.list_order_key > result.list_order_key) ? current : result,
    <MinimalNestedFormData>{ list_order_key: 0 });
}

export interface MinimalNestedFormData {
  id;
  list_order_key: number;
  title: string;
  type: 'resource_subject_format_subject' | 'subject_format_subject';

  slug?: string;
  resource_id?;
}

export interface NestedFormData extends MinimalNestedFormData {
  nestedForms: MinimalNestedFormData[];
  is_editable: boolean;
  form: FormGroup;
  description: string;

  id;
  list_order_key: number;
  title: string;
  type: 'resource_subject_format_subject' | 'subject_format_subject';

  slug?: string;
  resource_id?;

  acceptanceCriteriaId?: string;
  media?: any;
}

export interface SubjectFormData {
  id;
  type: string;
  nestedForms: MinimalNestedFormData[];
  media: any[];
  form: FormGroup;
}

@Injectable({
  providedIn: 'root',
})
export class NewSubjectFormService {
  private renderer: Renderer2;
  private dragDrop_components: { drag_component: MinimalNestedFormData, drag_parentComponent: SubjectFormData | NestedFormData }
    = { drag_component: null, drag_parentComponent: null };

  constructor(rendererFactory: RendererFactory2,
    private toastr: NbToastrService,
    private subjectService: NewSubjectService, private fb: FormBuilder) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  createSubjectForm(subject: Subject): SubjectFormData {
    let subjectForm: SubjectFormData = {
      id: subject.id,
      type: 'subject',
      media: [],
      form: this.fb.group({
        'subject_library_text': this.fb.control(subject.subject_library_text || '')
      }),
      nestedForms: []
    }
    if (subject.subjectFormatSubjects) {
      subjectForm.nestedForms = this.createNestedSectionForms(subject.subjectFormatSubjects.data);
    }
    if (subject.subjectMedia) {
      subjectForm.media = [];
      this.createMediaForm(subjectForm, subject.subjectMedia.data)
    }
    return subjectForm;
  }

  createMediaForm(subjectForm: SubjectFormData, mediaData: any[]) {
    if (!subjectForm.media)
      subjectForm.media = [];

    let returnedMedia = mediaData.map((media: any) => {
      if (media.attributes) {
        media = { ...media, ...media.attributes }
        delete media.attributes
      }
      return media;
    })
    subjectForm.media = [...subjectForm.media, ...returnedMedia]
  }

  removeMedia(mediaPath: any[], fileID: number) {
    const fileIndex = mediaPath.findIndex(file => file.id === fileID)
    if (fileIndex > -1)
      if (mediaPath[fileIndex].type === 'garbage_media')
        mediaPath.splice(fileIndex, 1);
      else mediaPath[fileIndex].type = 'detach_media'
  }

  convert_MinimalFormData_section(min: MinimalNestedFormData, section: Section): NestedFormData {
    let obj: NestedFormData = {
      description: section.description,
      form: this.fb.group({}),
      id: min.id,
      is_editable: section.is_editable,
      list_order_key: min.list_order_key,
      nestedForms: [],
      title: section.title,
      type: min.type,
    };
    this.createSectionForm(obj.form, section);
    if (section.subjectFormatSubjects) {
      let secForms = this.createNestedSectionForms(section.subjectFormatSubjects.data);
      obj.nestedForms = obj.nestedForms.concat(secForms);
    }
    if (section.resourceSubjectFormatSubjects) {
      let resourceForms = this.createNestedRerourceForms(section.resourceSubjectFormatSubjects.data);
      obj.nestedForms = obj.nestedForms.concat(resourceForms);
    }
    return obj;
  }

  convert_MinimalFormData_resource(min: MinimalNestedFormData, res: Resource): NestedFormData {
    let obj: NestedFormData = {
      description: null,
      form: this.fb.group({}),
      id: min.id,
      is_editable: res.is_editable,
      list_order_key: min.list_order_key,
      nestedForms: [],
      title: res.resource_slug,
      type: min.type,
      resource_id: min.resource_id,
      slug: res.resource_slug,
      acceptanceCriteriaId: res.learningResourceAcceptCriteria.data.id
    };
    this.createResourceForm(obj.form, res);
    return obj;
  }

  addNewResource(formPath: MinimalNestedFormData[], formsDataAdd: { type: number | string, slug: string }) {
    const listOrderKey = getLastComponent(formPath).list_order_key + 1;
    const obj: NestedFormData = {
      id: `new_${Math.floor((Math.random() * 100) + 1)}`,
      is_editable: true,
      list_order_key: listOrderKey,
      description: null,
      title: null,
      type: 'resource_subject_format_subject',
      form: this.fb.group({}),
      nestedForms: null, //no nested forms in resource
      resource_id: formsDataAdd.type,
      slug: formsDataAdd.slug,
    };
    this.createResourceForm(obj.form, { resource_slug: obj.slug, is_editable: true })

    formPath.push(obj);
  }

  addNewSection(formPath: MinimalNestedFormData[]) {
    const listOrderKey = getLastComponent(formPath).list_order_key + 1;
    const obj: NestedFormData = {
      id: `new_${Math.floor((Math.random() * 100) + 1)}`,
      is_editable: true,
      list_order_key: listOrderKey,
      description: null,
      title: null,
      type: 'subject_format_subject',
      form: this.fb.group({}),
      nestedForms: []
    };
    this.createSectionForm(obj.form, obj)
    formPath.push(obj);
  }

  createComponentId(id, type) {
    return `${type}_${id}`;
  }

  OnDragStartComponent(event, parentComponent: SubjectFormData | NestedFormData, dragComponent: MinimalNestedFormData, titleElement: ElementRef, componentEl: ElementRef) {
    this.dragDrop_components.drag_component = dragComponent;
    this.dragDrop_components.drag_parentComponent = parentComponent;
    event.dataTransfer.dropEffect = "move";
    this.addDragStartStyles(componentEl);
    event.dataTransfer.setDragImage(titleElement.nativeElement, 0, 0);
  }

  onDragEndComponent(componentEl: ElementRef) {
    this.removeDragStartStyles(componentEl);
  }

  onDragOverComponent(event, dragOverComponent: NestedFormData | SubjectFormData) {
    const drag_parentComponent = this.dragDrop_components.drag_parentComponent;
    const drop_parentComponent = dragOverComponent;
    if (this.shitIsCool(drag_parentComponent, drop_parentComponent)) {
      if (!event.target.classList.contains("drag-over"))
        event.target.classList.add("drag-over");
    } else {
      event.dataTransfer.dropEffect = "none";
      this.removeDragOverStyleIfExists(event);
    }
  }

  removeDragOverStyleIfExists(event) {
    if (event.target.classList.contains("drag-over"))
      event.target.classList.remove("drag-over");
  }

  onDropComponent(event, parentComponent: NestedFormData | SubjectFormData, subjectId) {
    this.removeDragOverStyleIfExists(event);
    const dragComponent = this.dragDrop_components.drag_component;
    const drag_parentComponent = this.dragDrop_components.drag_parentComponent;
    let shitToBeUpdated: MinimalNestedFormData[] = [];
    if (this.shitIsCool(drag_parentComponent, parentComponent)) {
      const dropComponentId = event.target.id;
      if (dropComponentId === 'endOfNestedForm') {
        const lastComponent = getLastComponent(parentComponent.nestedForms);
        if (!this.twoComponentsAreTheSame(dragComponent, lastComponent)) {
          dragComponent.list_order_key = lastComponent.list_order_key + 1;
          shitToBeUpdated.push(dragComponent);
          this.updateNestedFormOrders(shitToBeUpdated, subjectId, parentComponent.id).subscribe();
        }
      } else {
        const afterComponent = parentComponent.nestedForms.find(form => form.id === dropComponentId)
        if (!this.twoComponentsAreTheSame(dragComponent, afterComponent)) {
          const targetOrder = afterComponent.list_order_key;
          const followingComponents = parentComponent.nestedForms.filter(form => form.list_order_key > targetOrder && form != dragComponent);
          dragComponent.list_order_key = targetOrder;
          afterComponent.list_order_key++;
          followingComponents.forEach(form => {
            form.list_order_key++;
          });
          shitToBeUpdated = [dragComponent, afterComponent, ...followingComponents];
          this.updateNestedFormOrders(shitToBeUpdated, subjectId, parentComponent.id).subscribe();
        }
      }
    } else {
      this.toastr.warning('Cannot drag to different parent component. Please use remove and add');
    }
  }

  private updateNestedFormOrders(nestedComponents: MinimalNestedFormData[], subjectId, parentId): Observable<boolean> {
    const tasks$ = nestedComponents.map(nested => nested.type == 'subject_format_subject' ?
      this.subjectService.updateSectionOrder(nested.id, subjectId, nested.list_order_key, parentId) :
      this.subjectService.updateResourceOrder(nested.id, parentId, nested.list_order_key)
    )
    return forkJoin(tasks$).pipe(map(s => true));
  }

  removeComponent(parentComponent: SubjectFormData | NestedFormData, formDataId: string, permenantDelete?: boolean) {
    const successFn = () => {
      this.toastr.success('Success!');
      parentComponent.nestedForms.splice(formIndex, 1);
    }
    const formIndex = parentComponent.nestedForms.findIndex(nestedForm => nestedForm.id === formDataId)
    if (formIndex > -1) {
      const type = parentComponent.nestedForms[formIndex].type
      const id = parentComponent.nestedForms[formIndex].id
      if (permenantDelete) {
        if (type === 'subject_format_subject')
          this.subjectService.deleteSingleSection(id).subscribe(successFn);
        else
          this.subjectService.deleteResource(id).subscribe(successFn);
      } else {
        parentComponent.nestedForms.splice(formIndex, 1);
      }
    }
  }

  getValidationMessagesFromFormGroup(fg: FormGroup): ValidationData[] {
    var controlErrors: ValidationData[] = [];
    Object.keys(fg.controls).forEach(key => {
      const control = fg.controls[key];
      let errorMessage: string;
      let params: any;
      Object.keys(control.errors || {}).forEach(errorKey => {
        switch (errorKey) {
          case 'required':
            errorMessage = 'requiredMsg';
            break;
          case 'max':
            errorMessage = 'error_messages.max';
            params = { max_value: control.errors[errorKey].max };
            break;
          case 'min':
            errorMessage = 'error_messages.min';
            params = { min_value: control.errors[errorKey].min };
            break;
          case 'pattern':
            errorMessage = 'error_messages.pattern';
            break;
          default:
            errorMessage = control.errors[errorKey];
            console.warn('unsupported error key =>', errorKey);
            break;
        }
        controlErrors.push({ key: key, value: errorMessage, params: params });
      });
    });
    return controlErrors;
  }

  private shitIsCool(drag_parentComponent: NestedFormData | SubjectFormData, drop_parentComponent: NestedFormData | SubjectFormData) {
    return this.twoComponentsAreTheSame(drag_parentComponent, drop_parentComponent);
  }

  private resourceRaye7_3laSubject(drag_component: MinimalNestedFormData, drop_parentComponent: MinimalNestedFormData | SubjectFormData) {
    return drag_component.type === 'resource_subject_format_subject' && drop_parentComponent.type === 'subject';
  }

  private addDragStartStyles(componentEl: ElementRef) {
    document.getElementsByTagName('nb-layout-header')[0].classList.remove("fixed");
    this.renderer.addClass(componentEl.nativeElement, 'onDragStart');
  }

  private removeDragStartStyles(componentEl: ElementRef<any>) {
    this.renderer.removeClass(componentEl.nativeElement, 'onDragStart');
    document.getElementsByTagName('nb-layout-header')[0].classList.add('fixed');
  }

  private twoComponentsAreTheSame(c1, c2): boolean {
    return c1.id === c2.id && c1.type === c2.type;
  }

  private createNestedSectionForms(minimalSections: MinimalSection[]): MinimalNestedFormData[] {
    const formPath: MinimalNestedFormData[] = [];
    for (let index = 0; index < minimalSections.length; index++) {
      const minSection = minimalSections[index];
      const obj: MinimalNestedFormData = {
        id: minSection.id,
        list_order_key: minSection.list_order_key,
        title: minSection.title,
        type: 'subject_format_subject'
      };
      formPath.push(obj);
    }
    return formPath;
  }

  private createNestedRerourceForms(minimalResources: MinimalResource[]): MinimalNestedFormData[] {
    const formPath: MinimalNestedFormData[] = [];
    for (let index = 0; index < minimalResources.length; index++) {
      const minResource = minimalResources[index];
      const obj: MinimalNestedFormData = {
        id: minResource.id,
        list_order_key: minResource.list_order_key,
        title: minResource.title || minResource.slug,
        type: 'resource_subject_format_subject',
        resource_id: minResource.resource_id,
        slug: minResource.slug
      };
      formPath.push(obj);
    }
    return formPath;
  }

  private createSectionForm(formGroup: FormGroup, sectionData: { is_editable: boolean; is_active?: boolean; title: string; description: string; }) {
    formGroup.addControl('title', new FormControl(sectionData.title, Validators.required));
    formGroup.addControl('is_active', new FormControl(sectionData.is_active))
    formGroup.addControl('description', new FormControl(sectionData.description))

    // if (!sectionData.is_editable)
    // {
    //   formGroup.disable()
    // }
  }

  private createResourceForm(formGroup: FormGroup, resourceData: { is_active?: boolean, resource_slug: string, learningResourceAcceptCriteria?: any, is_editable: boolean }) {
    formGroup.addControl('is_active', new FormControl(resourceData.is_active))
    this.subjectService.resourceTemplates$.subscribe((resourceTemplates) => {
      console.log(resourceTemplates);
      if (resourceTemplates.length) {
        const template = resourceTemplates.find(template => template.slug == resourceData.resource_slug);
        if (template) {
          const attributes = template.learningResourceAcceptCriteria.data;
          attributes['id'] = '';

          const attributeKeys = Object.keys(attributes).filter(key => key !== 'type');
          for (let i = 0; i < attributeKeys.length; i++) {
            const attributeKey = attributeKeys[i];
            if (resourceData.learningResourceAcceptCriteria) {
              formGroup.addControl(attributeKey,
                new FormControl(resourceData.learningResourceAcceptCriteria.data[attributeKey] || '',
                  this.setFieldValidation(attributes[attributeKey].validation)));
            } else {
              formGroup.addControl(attributeKey, new FormControl('', this.setFieldValidation(attributes[attributeKey].validation)));
            }
          }

          if (!formGroup.get('id').value)
            formGroup.controls['id'].setValue(`new_${Math.floor((Math.random() * 100) + 1)}`);

          if (!resourceData.is_editable)
            formGroup.disable()
        }
      }
    });
  }

  private setFieldValidation(validation: {}) {
    const neededValidations = [];
    for (const validationKey in validation) {
      if (validation.hasOwnProperty(validationKey)) {
        switch (validationKey) {
          case 'required':
            if (validation[validationKey])
              neededValidations.push(Validators.required);
            break;
          case 'max':
            neededValidations.push(Validators.max(validation[validationKey]));
            break;
          case 'min':
            neededValidations.push(Validators.min(validation[validationKey]));
            break;
          case 'number':
            if (validation[validationKey])
              neededValidations.push(Validators.pattern('^(0|[1-9][0-9]*)$'));
            break;
          default:
            break;
        }
      }
    }
    return neededValidations;
  }
}
