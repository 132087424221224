import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Time } from "../../models/Time";

@Component({
  selector: 'time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss']
})
export class TimeInputComponent implements OnInit {

  @Input() startingValue: string;
  @Output() timeChanged: EventEmitter<string> = new EventEmitter<string>();
  model: Time;

  constructor() { }

  ngOnInit() {
    this.model = new Time(() => this.timeChanged.emit(this.model.toTimeString()), this.startingValue);
  }

  getSEXYhour(hour: number): string {
    if (hour < 10) {
      return '0' + hour;
    } else {
      return hour.toString();
    }
  }
}
