import { splitAtColon } from '@angular/compiler/src/util';

export type OnTimeChange = () => void;

export class Time {
    private _hour?: number;
    public get hour(): number {
        return this._hour;
    }
    public set hour(v: number) {
        this._hour = v;
        this.onChange();
    }
    private _minute?: number;
    public get minute(): number {
        return this._minute;
    }
    public set minute(v: number) {
        this._minute = v;
        this.onChange();
    }
    private _aa: 'AM' | 'PM' = 'AM';
    public get aa(): 'AM' | 'PM' {
        return this._aa;
    }
    public set aa(v: 'AM' | 'PM') {
        this._aa = v;
        this.onChange();
    }
    toTimeString(): string {
        if ((!this.hour && this.hour !== 0) || (!this.minute && this.minute !== 0)) {
            return "";
        }
        //sanitize possible strings
        this._hour = parseInt(this._hour.toString());
        this._minute = parseInt(this._minute.toString());
        //
        let actualHour = this.hour;
        if (this.aa == 'PM' && this.hour != 12) {
            actualHour += 12;
        }
        if (this.aa == 'AM' && this.hour == 12) {
            actualHour -= 12;
        }
        let hourString = (actualHour < 10) ? '0' + actualHour : actualHour.toString();
        let minuteString = this.minute < 10 ? '0' + this.minute : this.minute.toString();
        const result = `${hourString}:${minuteString}:00`;
        return result;
    }
    onChange: OnTimeChange;
    constructor(onChange: OnTimeChange, timeString?: string) {
        this.onChange = onChange;
        if (!timeString) {
            return;
        }
        const parts = splitAtColon(timeString, []);
        const actualHour = parseInt(parts[0]);
        let miniHour = actualHour;
        if (actualHour >= 12) {
            this._aa = 'PM';
            if (actualHour != 12) {
                miniHour = actualHour - 12;
            }
        }
        this._hour = miniHour;
        this._minute = parseInt(parts[1]);
    }
}
