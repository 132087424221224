import { OurEduLocalStorageService } from './our-edu-local-storage.service';
import { Injectable } from '@angular/core';
import { Formatter } from 'sarala-json-api-data-formatter';
import { environment } from '../../environments/environment';
import { HttpHeaders } from '@angular/common/http';


@Injectable({ providedIn: 'root' })

export class BaseService {

  static baseUrl = environment.apiUrl + OurEduLocalStorageService.getCurrentLanguage();
  static mediaUrl = environment.mediaBaseUrl + OurEduLocalStorageService.getCurrentLanguage();
  static token;

  formatter = new Formatter();

  constructor() { }

  static setRequestOptions(token) {
    const prefixURL = environment.apiUrl;
    BaseService.baseUrl = prefixURL + OurEduLocalStorageService.getCurrentLanguage()

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/vnd.api+json');
    headers.append('Accept', 'application/vnd.api+json');
    headers.append('Authorization', 'Bearer ' + token);
    BaseService.token = token;

  }

  deserialize(obj) {
    return this.formatter.deserialize(obj);
  }
  setBaseUrl(lang) {
    BaseService.baseUrl = environment.apiUrl + lang;
  }


}

