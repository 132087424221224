import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'general-exam',
  templateUrl: './general-exam.component.html',
  styleUrls: ['./general-exam.component.scss']
})
export class GeneralExamComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
