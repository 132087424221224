import { PreparedQuestion, Slugs } from './PreparedQuestion';
import { MasterQuestion, CompleteQuestion, DragDropQuestion, MatchingQuestion, MultiMatchingQuestion, MultipleChoiceQuestion, TrueFalseQuestion } from './MasterQuestion';

export class QuestionData {
  id;
  type: "question_exam_data";
  description: string;
  question_feedback: string;
  questions: any[];
  options?: any[];
  prepared_question_id: number;
  prepared_question?: PreparedQuestion;
  question_type: Slugs;
  true_false_type?: string;
}

export function mapToMasterQuestion(questionData: QuestionData): MasterQuestion {
  let question: MasterQuestion;
  switch (questionData.question_type) {
    case 'complete':
      let cQuestion: any = {
        ...questionData.questions[0],
      };
      question = (cQuestion);
      break;
    case 'drag_drop':
      let ddQuestion: any = {
        options: questionData.options,
        questions: questionData.questions
      };
      question = (ddQuestion);
      break;
    case 'matching':
      let matchingQuestion: any = {
        options: questionData.options,
        questions: questionData.questions
      };
      question = (matchingQuestion);
      break;
    case 'multiple_matching':
      let mmatchingQuestion: any = {
        options: questionData.options,
        questions: questionData.questions
      };
      question = (mmatchingQuestion);
      break;
    case 'multiple_choice':
      let mQuestion: any = {
        ...questionData.questions[0]
      }
      question = (mQuestion);
      break;
    case 'true_false':
      let tfQuestion: any = {
        true_false_type: questionData.true_false_type,
        ...questionData.questions[0]
      }
      question = (tfQuestion);
      break;
    default:
      break;
  }
  question.question_type = questionData.question_type;
  question.description = questionData.description;
  question.prepared_question_id = questionData.prepared_question_id;

  return question;
}

export function getPreparedQuestionId(questionData, singleQuestionId: number): number {
  return questionData.possible_prepared_questions[questionData.questions.findIndex(q => q.id == singleQuestionId)]
}
