import { AcceptanceCriteria } from './../components/subjects/fill-resource/taskForm.service';
import { NestedFormData, SubjectFormData } from './../components/subjects/subjectForm.new.service';
import { Slugs } from './../models/PreparedQuestion';
import { Media } from './../models/MasterQuestion';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, Subscription } from 'rxjs';
import { catchError, tap, map, filter, flatMap } from 'rxjs/operators';
import { BaseService } from './base.service';
import { Formatter } from 'sarala-json-api-data-formatter';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NbToastrService } from '@nebular/theme';
import { StupidArrayRelation } from '../models/StupidArrayRelation';
import { ResourceTemplate } from '../models/ResourceTemplate';

export interface Resource {
  id: string;
  resource_slug: Slugs;
  is_active: boolean;
  is_editable: boolean;
  task_is_done: boolean;
  list_order_key: number;
  learningResourceAcceptCriteria: any;
}

export interface MinimalSection {
  id: string;
  title: string,
  list_order_key: number;
  subject_type: string,
}

export interface MinimalResource {
  id;
  title: string,
  list_order_key: number;
  resource_id;
  slug: Slugs;
}

export interface Section {
  id: string;
  title: string,
  description: string,
  subject_type: string,
  is_active: boolean,
  is_editable: boolean,
  list_order_key: number
  subjectFormatSubjects: StupidArrayRelation<MinimalSection>;
  resourceSubjectFormatSubjects: StupidArrayRelation<MinimalResource>;
}

export interface Subject {
  id: string;
  name: string,
  educational_system: string,
  educational_term: string,
  academical_years: string,
  grade_class: string,
  start_date: string,
  end_date: string,
  is_active: true,
  section_type: "section",
  subject_library_text: string,
  subject_media_rules: string;
  subjectMedia: StupidArrayRelation<Media>;
  subjectFormatSubjects: StupidArrayRelation<MinimalSection>;
}

@Injectable({
  providedIn: 'root',
})
export class NewSubjectService extends BaseService {
  formatter = new Formatter();
  subjectForm: any;
  resourceTemplates$ = new BehaviorSubject<ResourceTemplate[]>([]);
  private media = {
    attachMedia: [],
    detachMedia: []
  }

  constructor(private http: HttpClient, private translate: TranslateService, private toastrService: NbToastrService) {
    super();
  }

  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      if (error.status == 422 && error.error && error.error.errors) {
        let errorMsg = '';
        error.error.errors.forEach(err => {
          if (err.status == 422) {
            errorMsg += err.detail;
          }
        });
        if (!errorMsg) {
          errorMsg = 'serverResponse.subjectForm.error';
          this.translate.get(errorMsg).subscribe((errorMessage: string) => {
            this.toastrService.show(
              'Error',
              errorMessage,
              { status: 'danger' });
          });
        } else {
          this.toastrService.show(
            'Error',
            errorMsg,
            { status: 'danger' });
        }
      }
      return of(result as T);
    };
  }

  private setMedia(type: 'garbage_media' | 'detach_media', id) {
    if (type === 'garbage_media') {
      this.media['attachMedia'].push({
        "type": type,
        "id": id
      });
    } else if (type === 'detach_media') {
      this.media['detachMedia'].push({
        "type": type,
        "id": id
      });
    }
  }

  getMinimalSubject(id: number): Observable<Subject> {
    const url = `${BaseService.baseUrl + '/sme/subjects'}/${id}/minimal`;

    return this.http.get<any>(url).pipe(
      map(data => {
        const m = this.formatter.deserialize(data);
        console.log('formatted subject', m);
        return m;
      }),
      catchError(this.handleError<Subject>()),
    );
  }

  getMinimalSection(id): Observable<Section> {
    const url = `${BaseService.baseUrl}/sme/subjects/get-section-structure/${id}`;

    return this.http.get<any>(url).pipe(
      map(data => {
        const m = this.formatter.deserialize(data);
        console.log('formatted section', m);
        if (!m.resourceSubjectFormatSubjects) {
          m.resourceSubjectFormatSubjects = { data: [] };
        }
        if (!m.subjectFormatSubjects) {
          m.subjectFormatSubjects = { data: [] };
        }
        return m;
      }),
      catchError(this.handleError<Section>()),
    );
  }

  updateSubject(subjectFormData: SubjectFormData, subject: Subject): Observable<Subject> {
    const url = `${BaseService.baseUrl}/sme/subjects/${subject.id}/public-library`;

    let media: any[] = subjectFormData.media;
    for (let i = 0; i < media.length; i++) {
      const element = media[i];
      this.setMedia(element.type, element.id);
    }
    let serialized = this.formatSubject(subjectFormData, subject);
    return this.http.put<any>(url, serialized).pipe(
      map(data => {
        this.media.attachMedia = this.media.detachMedia = [];
        if (!data.data || !data.data.id) {
          return null;
        }
        const m = this.formatter.deserialize(data);
        console.log('formatted subject', m);
        return m;
      }),
      catchError(this.handleError<Subject>()),
    );
  }

  private formatSubject(formData: SubjectFormData, subject: Subject) {
    var attributeKeys: string[] = [
      "name",
      "educational_system",
      "educational_term",
      "academical_years",
      "grade_class",
      "start_date",
      "end_date",
      "is_active",
      "subscription_cost",
      "section_type",
      "subject_library_text",
      "subject_media_rules"
    ];
    let attributes: any = {};
    attributeKeys.forEach(key => {
      if (["subject_library_text"].includes(key)) { // if editabe filed, get from form
        attributes[key] = formData.form.value[key];
      } else {
        attributes[key] = subject[key];
      }
    });
    const includesArray: any[] = this.media.attachMedia.map(mediaTypeId => ({ ...mediaTypeId, attributes: {} }));
    let result = {
      "data": {
        "type": "subject",
        "id": subject.id,
        "attributes": attributes,
        "relationships": {
          "attachMedia": {
            "data": this.media.attachMedia
          },
          "detachMedia": {
            "data": this.media.detachMedia
          }
        }
      },
      "included": includesArray
    };
    return result;
  }

  getResource(id: number): Observable<Resource> {
    const url = `${BaseService.baseUrl}/sme/subjects/get-single-resource/${id}`;

    return this.http.get<any>(url).pipe(
      map(data => {
        const m = this.formatter.deserialize(data);
        console.log('formatted resource', m);
        return m;
      }),
      catchError(this.handleError<Section>()),
    );
  }

  addComponents(formPath: any[], formatData) {
    for (let formIndex = 0; formIndex < formPath.length; formIndex++) {
      const componentForm = formPath[formIndex]
      if (componentForm.type === 'subject_format_subject') {
        if (!formatData.subjectFormatSubjects) {
          formatData.subjectFormatSubjects = {
            data: [],
            data_collection: true,
          }
          if (!formatData['relationships'])
            formatData['relationships'] = [];
          formatData['relationships'].push("subjectFormatSubjects");
        }
        formatData.subjectFormatSubjects.data.push(this.formatSection(componentForm))
        if (componentForm['nestedForm']) {
          const sectionData = formatData.subjectFormatSubjects.data[formatData.subjectFormatSubjects.data.length - 1];
          this.addComponents(componentForm.nestedForm, sectionData)
        }
      } else if (componentForm.type === 'resource_subject_format_subject') {
        if (!formatData.resourceSubjectFormatSubjects) {
          formatData.resourceSubjectFormatSubjects = {
            data: [],
            data_collection: true,
          }
          if (!formatData['relationships'])
            formatData['relationships'] = [];
          formatData['relationships'].push("resourceSubjectFormatSubjects");
        }
        formatData.resourceSubjectFormatSubjects.data.push(this.formatResource(componentForm))
      }
    }
  }

  private formatSection(sectionForm) {
    const formattedSection = { ...sectionForm };
    for (const valueKey in formattedSection.form.value) {
      if (formattedSection.form.value.hasOwnProperty(valueKey)) {
        const value = formattedSection.form.value[valueKey];
        formattedSection[valueKey] = value
      }
    }
    formattedSection['subject_type'] = 'section'
    delete formattedSection.form
    delete formattedSection.nestedForm
    return formattedSection
  }

  private formatResource(resourceForm) {
    const formattedResource = { ...resourceForm };
    formattedResource.is_active = formattedResource.form.value.is_active;
    formattedResource['resource_slug'] = resourceForm.slug;
    (<FormGroup>formattedResource.form).removeControl('is_active');

    formattedResource.learningResourceAcceptCriteria = {
      data: {
        type: "learning_resource_accept_criteria_field",
        //   id: `JSONApi_${Math.floor((Math.random() * 100) + 1)}`,
        resource_id: resourceForm.resource_id,
        resource_slug: resourceForm.slug
      },
    }
    formattedResource['relationships'] = ["learningResourceAcceptCriteria"];

    for (const valueKey in formattedResource.form.value) {
      if (formattedResource.form.value.hasOwnProperty(valueKey)) {
        const value = formattedResource.form.value[valueKey];
        formattedResource.learningResourceAcceptCriteria.data[valueKey] = value
      }
    }
    delete formattedResource.form
    return formattedResource
  }

  createUpdateSingleSection(formData: NestedFormData, subjectId): Observable<any> {
    const url = `${BaseService.baseUrl}/sme/subjects/${subjectId}/section-structural`;
    var formattd = this.formatSection(formData);
    const Serialzed = this.formatter.serialize(formattd);
    return this.http.put(url, Serialzed);
  }

  updateSectionOrder(sectionId, subjectId, order: number, parentId): Observable<any> {
    const getUrl = `${BaseService.baseUrl}/sme/subjects/get-section-structure/${sectionId}`;
    const updateUrl = `${BaseService.baseUrl}/sme/subjects/${subjectId}/section-structural`;
    return this.http.get<any>(getUrl).pipe(
      flatMap(data => {
        data.data.attributes.list_order_key = order;
        if (parentId != subjectId) {
          data.data.attributes.parent_subject_format_id = parentId;
        }
        return this.http.put<any>(updateUrl, data)
      })
    );
  }

  updateResourceOrder(resourceId, sectionId, order: number): Observable<any> {
    const getUrl = `${BaseService.baseUrl}/sme/subjects/get-single-resource/${resourceId}`;
    const updateUrl = `${BaseService.baseUrl}/sme/subjects/sections/${sectionId}/resource-structural`;
    return this.http.get<any>(getUrl).pipe(
      flatMap(data => {
        data.data.attributes.list_order_key = order;
        return this.http.put<any>(updateUrl, data)
      })
    );
  }

  deleteSingleSection(sectionId: any) {
    const url = `${BaseService.baseUrl}/sme/subjects/${sectionId}/section-structural`;
    return this.http.delete(url);
  }

  createUpdateResource(formData: NestedFormData, sectionId, acceptanceCriteria: AcceptanceCriteria): Observable<any> {
    const url = `${BaseService.baseUrl}/sme/subjects/sections/${sectionId}/resource-structural`;
    var serialized = this.serializeResource(acceptanceCriteria, formData);

    return this.http.put(url, serialized);
  }

  deleteResource(resourceId) {
    const url = `${BaseService.baseUrl}/sme/subjects/delete-single-resource/${resourceId}`;
    return this.http.delete(url);
  }

  private serializeResource(acceptanceCriteria: AcceptanceCriteria, formData: NestedFormData) {
    var value = formData.form.value;
    let variableAttributes = {
      "resource_id": formData.resource_id,
      "resource_slug": formData.slug
    };
    for (const key in acceptanceCriteria) {
      if (key != 'id' && acceptanceCriteria.hasOwnProperty(key) && value[key] !== undefined) {
        variableAttributes[key] = value[key];
      }
    }
    var serialized = {
      "data": {
        "type": "resource_subject_format_subject",
        "id": formData.id,
        "attributes": {
          "resource_slug": formData.slug,
          "resource_id": formData.resource_id,
          "is_active": value.is_active,
          "is_editable": formData.is_editable,
          "list_order_key": formData.list_order_key
        },
        "relationships": {
          "learningResourceAcceptCriteria": {
            "data": {
              // "type": "AcceptCriteria",
              "type": "learning_resource_accept_criteria_field",
              "id": formData.acceptanceCriteriaId || "new_learning_resource_accept_criteria_field"
            }
          }
        }
      },
      "included": [
        {
          "type": "learning_resource_accept_criteria_field",
          "id": formData.acceptanceCriteriaId || "new_learning_resource_accept_criteria_field",
          "attributes": variableAttributes,
        }
      ]
    };
    return serialized;
  }

  getResourceTemplates = (() => {
    return this.http.get<any>(BaseService.baseUrl + '/learning-resources')
      .pipe(
        tap(() => console.log('fetched resources')),
        catchError(this.handleError([])),
      ).subscribe(data => {
        const dataSerialized = this.formatter.deserialize(data);
        console.log(dataSerialized.data);
        const resourceTemplates = dataSerialized.data;
        this.resourceTemplates$.next(resourceTemplates);
      });
  })()


  private resourceIds: string[] = [];
  private sectionsThatNeedToBeExpanded = 0;
  private sectionNumber$: BehaviorSubject<number>;
  private subs: Subscription[] = [];

  getAllResourceIds(subject: Subject): Observable<string[]> {
    this.sectionsThatNeedToBeExpanded += subject.subjectFormatSubjects.data.length;
    this.sectionNumber$ = new BehaviorSubject(this.sectionsThatNeedToBeExpanded);
    subject.subjectFormatSubjects.data.forEach(m => {
      this.getSectionResources(m);
    });

    return this.sectionNumber$.pipe(
      filter(value => value == 0),
      map(value => this.resourceIds),
      tap(value => this.cleanUp())
    );
  }

  private cleanUp() {
    this.subs.forEach(s => s.unsubscribe());
    this.subs = [];
    this.resourceIds = [];
    this.sectionNumber$ = null;
  }

  private getSectionResources = (minimalSection: MinimalSection) => {
    this.subs.push(this.getMinimalSection(minimalSection.id).subscribe(section => {
      this.resourceIds = this.resourceIds.concat(section.resourceSubjectFormatSubjects.data.map(r => r.id));
      this.sectionsThatNeedToBeExpanded += (section.subjectFormatSubjects.data.length - 1);
      this.sectionNumber$.next(this.sectionsThatNeedToBeExpanded);
      section.subjectFormatSubjects.data.forEach(this.getSectionResources);
    }));
  }

  generateSubject(subjectId) {
    const url = BaseService.baseUrl + '/sme/subjects/generate-subject-tasks/' + subjectId;
    return this.http.post(url, {});
  }

  generateResources(resourceIds: string[]): Observable<any> {
    const url = `${BaseService.baseUrl}/sme/subjects/generate-resources-tasks`;
    var obj = {
      "data": {
        "type": "resource_subject_format_subject",
        "id": null,
        "attributes": {
          "resources": resourceIds
        }
      }
    };
    return this.http.post<any>(url, obj);
  }
}
