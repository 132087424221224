import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

interface ConfirmDialogData {
  message: string;
  action: 'primary' | 'danger';
}

@Component({
  templateUrl: './published-dialog.component.html',
  styleUrls: ['./published-dialog.component.scss']
})
export class PublishedDialogComponent implements OnInit {
  @Input() url: string;
  @Input() msg: string;

  constructor(protected dialogRef: NbDialogRef<PublishedDialogComponent>) { }

  ngOnInit() {
  }

  copy(inputElement){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    alert('Done');
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
