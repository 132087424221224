import {SubjectCloneComponent} from './components/subjects/subject-clone/subject-clone.component';
import {TasksFilterComponent} from './components/tasks/tasks-filter/tasks-filter.component';
import {TasksListComponent} from './components/tasks/tasks-list/tasks-list.component';
import {TaskSummaryComponent} from './components/tasks/task-summary/task-summary.component';
import {DiscriminationQuestionReportsSubjectComponent} from './components/discrimination-question-reports/discrimination-question-reports-subject/discrimination-question-reports-subject.component';
import {DiscriminationQuestionDetailComponent} from './components/discrimination-question-reports/discrimination-question-detail/discrimination-question-detail.component';
import {DiscriminationQuestionReportsListComponent} from './components/discrimination-question-reports/discrimination-question-reports-list/discrimination-question-reports-list.component';
import {DiscriminationSendToEditComponent} from './components/discrimination-question-reports/discrimination-question-detail/discrimination-send-to-edit.component';

// core modules
import {DragDropModule} from '@angular/cdk/drag-drop';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CoreModule} from './@core/core.module';
import {ThemeModule} from './@theme/theme.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AlertModule} from './auth/alert/alert.module';
import {AuthModule} from './auth/auth.module';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {ImageModule} from './components/image-upload/image-upload.module';
import {LogoutComponent} from './components/logout/logout.component';
import {ProfileModule} from './components/profile/profile-update/profile-update.module';
// import { ResourcesComponent } from './components/resources/resources.component';
import {StructureformatsComponent} from './components/structureformats/structureformats.component';
import {SubjectTasksComponent} from './components/subject-tasks/subject-tasks.component';
// import { NgxSortableModule } from 'ngx-sortable';
// Components
// import {SubjectEditComponent} from './components/subjects/subject-edit/subject-edit.component';
import {SubjectsComponent} from './components/subjects/subject-list/subjects.component';
import {TasksComponent} from './components/tasks/tasks.component';
// import {SectionstructureformatsComponent} from './components/structureformats/sectionstructureformats/sectionstructureformats.component';
// import {TrueorfalseComponent} from './components/resources/trueorfalse/trueorfalse.component';
import {HttpConfigInterceptor} from './interceptors/httpconfig.interceptor';
import {QuestionReportsListComponent} from './components/question-reports/question-reports-list/question-reports-list.component';
import {QuestionReportsSubjectComponent} from './components/question-reports/question-reports-subject/question-reports-subject.component';
import {QuestionDetailComponent} from './components/question-reports/question-detail/question-detail.component';
import {QuestionReportComponent} from './components/question-reports/question-detail/question-report.Component';
import {TaskPerformanceDetailComponent} from './components/task-performance/task-performance-detail/task-performance-detail.component';
import {ListComponent} from './components/task-performance/templates/task-performance-list/list.component';
import {TaskPerformanceComponent} from './components/task-performance/task-performance.component';
import {TaskPerformanceListComponent} from './components/task-performance/task-performance-list/task-performance-list.component';
import {ListGeneralExamsComponent} from './components/general-exam/list-general-exams/list-general-exams.component';
import {GeneralExamComponent} from './components/general-exam/general-exam/general-exam.component';
import {CreateEditExamComponent} from './components/general-exam/create-edit-exam/create-edit-exam.component';
import {GeneralExamQuestionsComponent} from './components/general-exam/general-exam-questions/general-exam-questions.component';
import {SingleSectionComponent} from './components/general-exam/single-section/single-section.component';
import {MultiplechoiceComponent} from './components/general-exam/general-exam-questions/question-forms/multiplechoice/multiplechoice.component';
import {TrueorfalseComponent} from './components/general-exam/general-exam-questions/question-forms/trueorfalse/trueorfalse.component';
import {DragdropComponent} from './components/general-exam/general-exam-questions/question-forms/dragdrop/dragdrop.component';
import {MatchingComponent} from './components/general-exam/general-exam-questions/question-forms/matching/matching.component';
import {CompleteQuestionComponent} from './components/general-exam/general-exam-questions/question-forms/complete-question/complete-question.component';
import {TimeInputComponent} from './components/time-input/time-input.component';
import {
  NbSidebarModule,
  NbMenuModule,
  NbDialogModule,
  NbWindowModule,
  NbToastrModule,
  NbChatModule,
  NbDatepickerModule, NbInputModule
} from '@nebular/theme';
import {NebularStuffModule} from './nebular-stuff/nebular-stuff.module';
import {PartialsModule} from './pages/partials/partials.module';
import {NgxPaginationModule} from 'ngx-pagination';
import {ViewCompletleComponent} from './components/general-exam/preview-questions/question-views/view-completle/view-completle.component';
import {ViewMatchingComponent} from './components/general-exam/preview-questions/question-views/view-matching/view-matching.component';
import {ViewMultiMatchingComponent} from './components/general-exam/preview-questions/question-views/view-multi-matching/view-multi-matching.component';
import {ViewTrueFalseComponent} from './components/general-exam/preview-questions/question-views/view-true-false/view-true-false.component';
import {ViewMultipleChoiceComponent} from './components/general-exam/preview-questions/question-views/view-multiple-choice/view-multiple-choice.component';
import {ViewDragDropComponent} from './components/general-exam/preview-questions/question-views/view-drag-drop/view-drag-drop.component';
import {SubjectsFilterComponent} from './components/subjects/subjects-filter/subjects-filter.component';
import {SubjectDetailsComponent} from './components/subjects/subject-details/subject-details.component';
import {SubSectionsComponent} from './components/subjects/sub-sections/sub-sections.component';
import {PreviewQuestionsDialogComponent} from './components/general-exam/preview-questions/preview-questions-dialog.component';
import {PreviewQuestionsFullComponent} from './components/general-exam/preview-questions/preview-questions-full.component';
import {InnerPreviewQuestionsComponent} from './components/general-exam/preview-questions/inner-preview.component';
import {MultiMatchingComponent} from './components/general-exam/general-exam-questions/question-forms/multi-matching/multi-matching.component';
import {PublishedDialogComponent} from './components/general-exam/published-dialog/published-dialog.component';
import {ToastrModule} from 'ngx-toastr';
import {MathModule} from './pages/math/math.module';
import {GeneralExamStudentsComponent} from './components/general-exam/general-exam-students/general-exam-students.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {SubjectEditModule} from './components/subjects/subject-edit-new/subject-edit.module';
import {ResourceTemplateService} from './resource-template.service';

import {MessagingService} from './shared/messaging.service';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AsyncPipe} from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import {environment} from '../environments/environment';
import { PostInterceptor } from './auth/helpers';
import { CanDeactivateComponent } from './can-deactivate-component';
import { NgxSpinnerModule } from "ngx-spinner";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function appInit(tmpltService: ResourceTemplateService) {
  return () => tmpltService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    CanDeactivateComponent,
    SubjectsComponent,
    StructureformatsComponent, DashboardComponent,
    TasksComponent, TaskSummaryComponent, TasksListComponent, TasksFilterComponent,
    SubjectTasksComponent,
    LogoutComponent, QuestionReportsListComponent, QuestionReportsSubjectComponent, QuestionDetailComponent,
    QuestionReportComponent, ListComponent, TaskPerformanceComponent,
    TaskPerformanceListComponent, TaskPerformanceDetailComponent,
    ListGeneralExamsComponent, GeneralExamComponent, CreateEditExamComponent, GeneralExamQuestionsComponent, SingleSectionComponent,
    MultiplechoiceComponent,
    TrueorfalseComponent,
    DragdropComponent,
    MatchingComponent,
    MultiMatchingComponent,
    CompleteQuestionComponent,
    TimeInputComponent,
    SubjectCloneComponent,
    PreviewQuestionsDialogComponent,
    PreviewQuestionsFullComponent,
    InnerPreviewQuestionsComponent,
    ViewCompletleComponent,
    ViewMatchingComponent,
    ViewMultiMatchingComponent,
    ViewTrueFalseComponent,
    ViewMultipleChoiceComponent,
    ViewDragDropComponent,
    SubjectsFilterComponent,
    SubjectDetailsComponent,
    SubSectionsComponent,
    PublishedDialogComponent,
    GeneralExamStudentsComponent,
    DiscriminationQuestionReportsSubjectComponent,
    DiscriminationQuestionDetailComponent,
    DiscriminationQuestionReportsListComponent,
    DiscriminationSendToEditComponent,
  ],
  imports: [
    HttpClientModule,
    AuthModule,
    BrowserModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    DragDropModule,
    AlertModule,
    ToastrModule.forRoot(), // ToastrModule added
    NebularStuffModule,
    NgxPaginationModule,
    MathModule.forRoot(),
    ThemeModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),
    PartialsModule,
    SubjectEditModule,
    ImageModule,
    ProfileModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgSelectModule,
    NbInputModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  exports: [TranslateModule, QuestionReportComponent, DiscriminationSendToEditComponent],
  entryComponents: [
    QuestionReportComponent,
    PreviewQuestionsDialogComponent,
    PublishedDialogComponent,
    DiscriminationSendToEditComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: PostInterceptor, multi: true },
    ResourceTemplateService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [ResourceTemplateService],
    },
    MessagingService, AsyncPipe],
})

export class AppModule {
}
