import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { OurEduLocalStorageService } from '../../services/our-edu-local-storage.service';

@Injectable()
export class PostInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (['POST', 'PUT'].includes(request.method)) {
            OurEduLocalStorageService.setFormSubmitting();
        }
        return next.handle(request).pipe(
            filter(event => event instanceof HttpResponse),
            tap(result => {
                OurEduLocalStorageService.clearFormSubmitting();
            })
        );
    }
}
