import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// theme module
import { NbAlertModule, NbBadgeModule, NbButtonModule, NbCardModule, NbIconModule, NbLayoutModule, NbListModule, NbSpinnerModule, NbTabsetModule, NbTooltipModule, NbUserModule, NbCheckboxModule, NbRadioModule, NbSelectModule, NbAccordionModule } from '@nebular/theme';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports:[
    NbLayoutModule,
    NbButtonModule,
    NbTabsetModule,
    NbIconModule,
    NbListModule,
    NbRadioModule,
    NbSelectModule,
    NbAccordionModule,
    NbSpinnerModule,
    NbUserModule,
    NbCheckboxModule,
    NbTabsetModule,
    NbCardModule,
    NbTooltipModule,
    NbAlertModule,
    NbBadgeModule,
  ]
})
export class NebularStuffModule{ }
