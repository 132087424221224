import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { QuestionData, mapToMasterQuestion } from '../../../../../models/QuestionData';
import { LanguageConfig } from '../../../../../models/LanguageConfig';
import { DragDropQuestion } from '../../../../../models/MasterQuestion';

export interface DragDropOption {
  id: string;
  option: string;
}

@Component({
  selector: 'dragdrop',
  templateUrl: './dragdrop.component.html',
  styleUrls: ['./dragdrop.component.scss'],
})
export class DragdropComponent implements OnInit {

  dragDropQuestion: DragDropQuestion;
  @Input() questionData: QuestionData;
  @Input() languageConfig: LanguageConfig;
  @Input() selectedQuestions: number[] = [];
  @Output() questionSelected: EventEmitter<void> = new EventEmitter<void>();
  @Output() questionRemoved: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnChanges(changes): void {
    this.dragDropQuestion = <DragDropQuestion>mapToMasterQuestion(this.questionData);
  }

  ngOnInit() {
  }

  selectQuestion() {
    this.questionSelected.emit();
  }

  removeQuestion() {
    this.questionRemoved.emit();
  }
}
