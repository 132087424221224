import { Formatter } from 'sarala-json-api-data-formatter';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import { ResourceTemplate } from './models/ResourceTemplate';
import { OurEduLocalStorageService } from './services/our-edu-local-storage.service';
import { environment } from '../environments/environment';

@Injectable()
export class ResourceTemplateService {

    resourceTemplates: ResourceTemplate[] = [];
    formatter = new Formatter();
    static instanceCount = 0;

    constructor(private http: HttpClient) {
        ResourceTemplateService.instanceCount += 1;
        if(ResourceTemplateService.instanceCount > 1){
            const err = "========> ResourceTemplateService cannot be double initialized";
            alert(err);
            throw err;
        }
    }

    load(): Promise<any> {
        var userData = OurEduLocalStorageService.getUserData();
        if (!userData) {
            return new Promise((resolve, reject) => {
                resolve();
            })
        }
        var token = userData.token;

        const prefixURL = environment.apiUrl;
        const baseUrl = prefixURL + OurEduLocalStorageService.getCurrentLanguage();

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/vnd.api+json');
        headers.append('Accept', 'application/vnd.api+json');
        headers.append('Authorization', 'Bearer ' + token);

        const zaRequest = this.http.get<any>(baseUrl + '/learning-resources', {headers});
        const promise = zaRequest
            .toPromise()
            .then(data => {
                const dataSerialized = this.formatter.deserialize(data);
                this.resourceTemplates = dataSerialized.data;
            });

        return promise;
    }
}
