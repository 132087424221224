import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import { Subscription} from 'rxjs';

import {DiscriminationQuestionReportService} from '../../../services/discrimination-question-report.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';
import {NbToastrService, NbDialogService, NbDialogRef} from '@nebular/theme';
import {GeneralExamReportQuestion} from '../../../models/DiscriminationQuestionReport.model';
import {tap} from 'rxjs/operators';
import * as XLSX from 'xlsx';
import {DiscriminationSendToEditComponent} from './discrimination-send-to-edit.component';
import { CanDeactivateComponent } from '../../../can-deactivate-component';

@Component({
  selector: 'ngx-discrimination-question-detail',
  templateUrl: './discrimination-question-detail.component.html',
  styleUrls: ['./discrimination-question-detail.component.scss'],
})


export class DiscriminationQuestionDetailComponent extends CanDeactivateComponent implements OnInit {
  question: GeneralExamReportQuestion;
  currentPage: any;
  id: number;
  langChange: Subscription;
  curretLang: string;
  spinner: boolean;
  loading: boolean;

  constructor(private service: DiscriminationQuestionReportService,
              private dlgService: NbDialogService,
              private cdr: ChangeDetectorRef,
              private activatedRoute: ActivatedRoute,
              private translate: TranslateService,
              private toastrService: NbToastrService,
              private router: Router,
              private dialogService: NbDialogService,
  ) {
    super()
    this.curretLang = this.translate.getDefaultLang();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.curretLang = event.lang;
    });
  }

  ngOnInit() {
    this.spinner = true;
    this.loading = false;
    this.id = parseInt(this.activatedRoute.snapshot.paramMap.get('questionId'), 10);

    this.reloadData();

  }

  private reloadData(pageNumber = 1) {

    this.service.getById(this.id).pipe(tap(data => {
      // const formatter = new Formatter();
      // const datas = formatter.deserialize(data);
      this.question = data;
      this.spinner = false;
    }))
      .subscribe();
  }

  ignore() {
    this.loading = true;
    this.service.ignore(this.id).subscribe(r => {
      this.loading = false;

      this.toastrService.success('Created successfully.');
      this.router.navigateByUrl('/general-exams');
    });
  }

  // sendToEdit() {
  //   this.loading = true;
  //   this.service.sendToEdit(this.id).subscribe(r => {
  //     this.loading = false;
  //
  //     this.toastrService.success('Created successfully.');
  //     this.router.navigateByUrl('/general-exams');
  //   });
  // }
  sendToEdit() {
    this.dialogService.open(DiscriminationSendToEditComponent, {
      context: {
        questionId: this.id,
      },
    }).onClose.subscribe((res) => {
      this.router.navigate(['general-exams']);
    });
  }
  exportexcel() {
    /* table id is passed over here */
    const element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const fileName = 'ExcelSheet.xlsx';

    /* save to file */
    XLSX.writeFile(wb, fileName);

  }

}
