import { OurEduLocalStorageService } from './services/our-edu-local-storage.service';
import { Component, HostListener } from "@angular/core";
import { Observable } from "rxjs";
import { IComponentCanDeactivate } from "./auth/helpers";


@Component({
    template: '',
})
export class CanDeactivateComponent implements IComponentCanDeactivate {

    constructor() {
    }

    @HostListener('window:beforeunload')
    canDeactivate: () => boolean | Observable<boolean> = () => {
        const result = OurEduLocalStorageService.getFormSubmitting();
        console.log('%cform submitting', 'color: orange;',  result);
        return !result;
    }
}
