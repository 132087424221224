import { Component, OnInit, Input } from '@angular/core';
import { MultipleChoiceQuestion } from '../../../../../models/MasterQuestion';
import { LanguageConfig } from '../../../../../models/LanguageConfig';

@Component({
  selector: 'view-multiple-choice',
  templateUrl: './view-multiple-choice.component.html',
  styleUrls: ['./view-multiple-choice.component.scss']
})
export class ViewMultipleChoiceComponent implements OnInit {

  @Input() languageConfig: LanguageConfig;
  @Input() question: MultipleChoiceQuestion;

  constructor() { }

  ngOnInit() {
  }

}
