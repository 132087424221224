import { Component, OnInit } from '@angular/core';
import { UserData } from '../../@core/data/users';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();
  users = [
     { name: 'Nick Jones', count: '50' },
     { name: 'Eva Moor', count: '30' },
     { name: 'Jack Williams', count: '99+' }
  ];
  constructor() { 

  }

  ngOnInit() {
  }

}
