import { Formatter } from 'sarala-json-api-data-formatter';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateGeneralExam, FetchedGeneralExam } from '../../../models/general-exam';
import { GeneralExamService } from '../../../services/general-exam.service';
import { SubjectService } from '../../../services/subject.service';
import { SubjectD } from '../../../models/subject';
import { DifficultyLevel } from '../../../models/DifficultyLevel';
import { SubjectFormatSubject, getDifficultyLevelId } from '../../../models/subject-format-subject';
import { NbToastrService, NbDialogService, NbDialogConfig } from '@nebular/theme';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { LanguageConfig } from '../../../models/LanguageConfig';
import { PreparedQuestion } from '../../../models/PreparedQuestion';
import { QuestionData, getPreparedQuestionId, mapToMasterQuestion } from '../../../models/QuestionData';
import { MasterQuestion, CompleteQuestion, DragDropQuestion, MatchingQuestion, MultiMatchingQuestion, MultipleChoiceQuestion, TrueFalseQuestion } from '../../../models/MasterQuestion';
import { PreviewQuestionsDialogComponent } from '../preview-questions/preview-questions-dialog.component';
import { ConfirmDialogComponent } from '../../../pages/partials/confirm-dialog/confirm-dialog.component';
import { PaginationConfig } from '../../../models/PaginationConfig';
import { CanDeactivateComponent } from '../../../can-deactivate-component';

@Component({
  selector: 'general-exam-questions',
  templateUrl: './general-exam-questions.component.html',
  styleUrls: ['./general-exam-questions.component.scss']
})
export class GeneralExamQuestionsComponent extends CanDeactivateComponent implements OnInit {

  id: number;
  isEdit: boolean = false;
  generalExam: FetchedGeneralExam;
  subject: SubjectD;
  subjectSections: SubjectFormatSubject[] = [];
  allDifficultyLevels: DifficultyLevel[] = [];
  loading = true;
  selectedSection: number = null;
  questionDatas: QuestionData[];
  preparedQuestionIds: any[] = [];
  // singularQuestionIds: number[] = [];
  languageConfig: LanguageConfig;
  noQuestionsHere: boolean;
  config: PaginationConfig;

  constructor(private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private router: Router,
    private dialogService: NbDialogService,
    private service: GeneralExamService,
    private cdr: ChangeDetectorRef,
    private toastr: NbToastrService,
    private subjectService: SubjectService) { 
      super()
    }

  ngOnInit() {
    this.id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
    this.subjectService.getDifficultyLevels().subscribe(difficultyLevels => {
      this.allDifficultyLevels = difficultyLevels
      this.isEdit = true;
      this.service.getById(this.id).subscribe(r => {
        console.log('general exam', r);
        this.generalExam = r;
        this.preparedQuestionIds = r.prepared_questions_ids.map(i => i.toString()) || [];
        this.setSubject();
        this.cdr.detectChanges();
      });
    });

    this.setupLanguage();


    // this.subjectSections = [SubjectFormatSubject.CreateDummy()];
    // for (let i = 0; i < this.subjectSections.length; i++) {
    //   const section = this.subjectSections[i];
    //   this.fillChildren(section);
    // }
  }

  private setupLanguage() {
    this.languageConfig = new LanguageConfig(this.translate.getDefaultLang());
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.languageConfig = new LanguageConfig(event.lang);
    });
  }

  private setSubject() {
    this.service.getSubject(this.generalExam)
      .subscribe(data => {
        const formatter = new Formatter();
        let subject = formatter.deserialize(data);
        this.subject = subject;
        if (subject.subjectFormatSubjects) {
          this.subjectSections = subject.subjectFormatSubjects.data;
          for (let i = 0; i < this.subjectSections.length; i++) {
            const section = this.subjectSections[i];
            this.fillChildren(section);
          }
          // this.fetchSubjectSelectedQuestions();
        }
      });
  }

  private fillChildren(fetchedSection) {
    fetchedSection.children = [];
    if (fetchedSection.subjectFormatSubjects && fetchedSection.subjectFormatSubjects.data) {
      for (let i = 0; i < fetchedSection.subjectFormatSubjects.data.length; i++) {
        const child = fetchedSection.subjectFormatSubjects.data[i];
        this.fillChildren(child);
        fetchedSection.children[i] = child;
      }
    }
  }

  selectSection(id) {
    this.questionDatas = [];
    this.resetConfig();
    this.selectedSection = id;
    let selected = this.subjectSections.map(s => this.getNodeWithId(s, id)).find(s => !!s);
    console.log(selected);
    let url = selected.actions.data.find(a => a.key == 'available_section_questions').endpoint_url;
    // url = url + 'difficulty_level_id=' + getDifficultyLevelId(this.generalExam.difficulty_level);
    this.service.getSubjectQuestions(url).subscribe(res => {
      this.setupConfig(res.pagination);
      const result = res.questions;
      console.log('questions', result);
      if (!result.length) {
        this.noQuestionsHere = true;
      }
      this.questionDatas = result;
    });
  }

  private resetConfig() {
    this.config = {
      currentPage: 1,
      itemsPerPage: 0,
      totalItems: 0
    };
  }

  private setupConfig(pagination) {
    this.config = {
      currentPage: pagination.current_page,
      itemsPerPage: pagination.per_page,
      totalItems: pagination.total
    };
  }

  onPageChange(pageNumber) {
    let selected = this.subjectSections.map(s => this.getNodeWithId(s, this.selectedSection)).find(s => !!s);
    console.log(selected);
    let url = selected.actions.data.find(a => a.key == 'available_section_questions').endpoint_url;
    const queryString = url.indexOf('?') == url.length - 1 ? `page=${pageNumber}` : `?page=${pageNumber}`;
    url += queryString;
    // url = url + 'difficulty_level_id=' + getDifficultyLevelId(this.generalExam.difficulty_level);
    this.service.getSubjectQuestions(url).subscribe(res => {
      this.setupConfig(res.pagination);
      const result = res.questions;
      this.questionDatas = result;
    });
  }

  getNodeWithId(node, id) {
    if (node.id == id) {
      return node;
    }
    for (let i = 0; i < node.children.length; i++) {
      const child = node.children[i];
      const targetChild = this.getNodeWithId(child, id);
      if (targetChild) {
        return targetChild;
      }
    }
    return null;
  }

  addTheWholeQuestionData(questionData: QuestionData) {
    this.preparedQuestionIds.push(questionData.prepared_question_id);
  }

  removeTheWholeQuestionData(questionData: QuestionData) {
    this.preparedQuestionIds.splice(this.preparedQuestionIds.indexOf(questionData.prepared_question_id), 1);
  }

  submit() {
    // const questionIds = Object.keys(this.preparedQuestionIds).reduce((result, key): number[] =>
    //   result.concat(this.preparedQuestionIds[key].questions),
    //   []
    // );
    this.service.updateQuestions(this.id, this.preparedQuestionIds).subscribe(r => {
      this.toastr.success('Edited successfully.');
      // location.reload();
    });
  }

  test() {
    console.log('preparedQuestionIds', this.preparedQuestionIds);
  }

  // previewQuestions() {
  //   let questions: MasterQuestion[] = [];
  //   this.questionDatas.forEach(singleBoy => questions.push(mapToMasterQuestion(singleBoy)));
  //   let ref = this.dialogService.open(PreviewQuestionsDialogComponent,
  //     {
  //       context: { questions: questions },
  //       autoFocus: true,
  //       closeOnBackdropClick: false,
  //       hasBackdrop: true
  //     }
  //   );
  // }


  previewQuestions() {
    let ref = this.dialogService.open(ConfirmDialogComponent, { context: { data: { message: "navigation_alert", action: 'danger' } } });
    ref.onClose.subscribe(result => {
      if (result == true) {
        this.router.navigateByUrl(`/general-exams/preview-questions/${this.id}`);
      }
    })
  }
}
