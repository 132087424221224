import { environment } from "../../environments/environment";

export const CKEDITOR_CONFIG = {
  simpleUpload: { uploadUrl: environment.mediaBaseUrl + "en/media/upload" },
  extraPlugins: "ckeditor_wiris,mathjax,N1ED-editor,divarea",
  apiKey: environment.ckeditorApiKey,
  language: "ar",
  // defaultLanguage: "ar",
  contentsLangDirection: "rtl",
  allowedContent: true,
  wirispluginperformance: true,
  Flmngr: {
    accessKey: environment.FlmngrAccessKey,
    urlFileManager: "https://files.flmngr.com/flmngr",
    urlFiles: environment.FlmngrUrlFiles,
  },
  removePlugins:
    "scayt,forms,link,iframe,about,pagebreak,templates,about,showblocks,newpage,language,print,div,blockquote,save,flash,preview,form,find,maximize",
  mathJaxLib:
    "http://cdn.mathjax.org/mathjax/2.6-latest/MathJax.js?config=TeX-AMS_HTML",
  extraAllowedContent:
    [
      "math",
      "maction",
      "maligngroup",
      "malignmark",
      "menclose",
      "merror",
      "mfenced",
      "mfrac",
      "mglyph",
      "mi",
      "mlabeledtr",
      "mlongdiv",
      "mmultiscripts",
      "mn",
      "mo",
      "mover",
      "mpadded",
      "mphantom",
      "mroot",
      "mrow",
      "ms",
      "mscarries",
      "mscarry",
      "msgroup",
      "msline",
      "mspace",
      "msqrt",
      "msrow",
      "mstack",
      "mstyle",
      "msub",
      "msup",
      "msubsup",
      "mtable",
      "mtd",
      "mtext",
      "mtr",
      "munder",
      "munderover",
      "semantics",
      "annotation",
      "annotation-xml",
    ].join(" ") +
    "(*)[*]{*};img[data-mathml,data-custom-editor,role](Wirisformula)",
};
