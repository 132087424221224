import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderByPipe'
})
export class OrderByPipe implements PipeTransform {

    transform(array: Array<any>, reorder: boolean): Array<any> {
        if (!array || array.length === 0) return array;
        array.sort((a: any, b: any) => {
            return a.list_order_key - b.list_order_key;
        });
        return array;
    }

}