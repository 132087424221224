import { ApiAction } from './ApiAction';
import { DifficultyLevel } from './DifficultyLevel';

export class CreateGeneralExam {
    id: string;
    name: string;
    difficulty_level_id: number;
    subject_format_subjects: number[] = [];
    subject_id: number;
    date: Date;
    start_time: string;
    end_time: string;
    published_at?: string;
    url?: string;

    static FromFetched(fetchedVersion: FetchedGeneralExam, difficultyLevels: DifficultyLevel[]): CreateGeneralExam {
        const instance = new CreateGeneralExam();
        Object.assign(instance, fetchedVersion);
        // instance.difficulty_level_id = difficultyLevels.find(level => level.name == fetchedVersion.difficulty_level).id;
        instance.date = new Date(fetchedVersion.date);
        return instance;
    }
}

export interface FetchedGeneralExam {
    questions_pagination?: any;
    prepared_questions_pagination?: any;
    prepared_questions_ids: number[];
    id: string;
    uuid: string;
    name: string;
    difficulty_level: string;
    difficulty_level_id: number;
    subject_format_subjects: number[];
    subject_id: number;
    subject_name: string;
    date: string;
    start_time: string;
    end_time: string;
    is_published: boolean;
    published_at?: string;
    created_at: Date;
    type: "general_exam";
    class: string;
    relationships: any[];
    actions: { data_collection: true, data: ApiAction[] };
    preparedQuestions?: { data_collection: true, data: any[] };
    questions?: { data_collection: true, data: any[] };
}
export interface ExamStudent {
  id: string;
  name: string;
  result_percentage: string;
}
