import { TaskPerformanceService } from './../../../services/task-performance.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Formatter } from 'sarala-json-api-data-formatter';
import { SubjectD } from '../../../models/subject';
import { SubjectService } from '../../../services/subject.service';
import { TaskService } from '../../../services/task.service';
import { ActivatedRoute } from '@angular/router';
import { CanDeactivateComponent } from '../../../can-deactivate-component';


(window as any).global = window;


@Component({
  selector: 'ngx-taskperformancelist',
  templateUrl: './task-performance-list.component.html',
  styleUrls: ['./task-performance-list.component.scss'],
})
export class TaskPerformanceListComponent extends CanDeactivateComponent implements OnInit, OnDestroy {

  constructor(private taskPerformanceService: TaskPerformanceService, private translate: TranslateService, private router: ActivatedRoute) {
    super()
  }
  langChange: Subscription;
  data: [];
  tableKeys: any;
  id: string;
  pagination;


  ngOnInit() {
    this.tableKeys = this.taskPerformanceService.getPerformance.specificList.tableKeys;
    this.data = [];
    this.router.params.subscribe(params => {
      this.id = params.id;
      this.reloadData(1);
    });
    this.langChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.reloadData(this.pagination.current_page);
    });
  }

  reloadData(pagenumber) {
    this.taskPerformanceService.getSpecificList(this.id, pagenumber)
      .subscribe(list => {
        this.data = list.data.map(item => {
          item.routeId = item.id;
          // this.tableKeys.map(key => {
          //   if (item.hasOwnProperty(key)) {
          //     if (item[key] === true) {
          //       item[key] = '<nb-icon icon=\'checkmark-outline\'></nb-icon>';
          //     } else if (item[key] === false) {
          //       item[key] = '<nb-icon icon="close-outline"></nb-icon>';
          //     }
          //   }
          // })
          return item;
        });
        this.pagination = list.pagination;
      });
  }


  ngOnDestroy() {
    this.langChange.unsubscribe();
  }

}
