import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
// starting from here
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';
import { AlertService } from '../../services/alert.service';
import { OurEduLocalStorageService } from '../../services/our-edu-local-storage.service';

@Component({
  selector: 'ngx-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['./request-password.component.scss'],
})
export class RequestPaswordComponent implements OnInit {
  RequestPaswordForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private translate: TranslateService,
  ) {
    // redirect to home if already logged in
    debugger;
    if (this.authenticationService.currentUserValue || OurEduLocalStorageService.getUserData().token) {
      this.router.navigate(['pages/dashboard']);
    }
  }

  ngOnInit() {
    this.RequestPaswordForm = this.formBuilder.group({
      email: ['', Validators.required],
    });

    // get return url from route parameters or default to '/'
  }

  // convenience getter for easy access to form fields
  get f() { return this.RequestPaswordForm.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.RequestPaswordForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.RequestPassword(this.f.email.value)
      .pipe(first())
      .subscribe(
        data => {
          debugger;
          this.alertService.success(data);
          this.loading = false;
        },
        error => {
          this.alertService.error(error.error.errors);
          this.loading = false;
        });
  }
}
