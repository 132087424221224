import { Component, OnInit, Input } from '@angular/core';
import { ValidationData } from '../../../components/subjects/subject-edit-new/ValidationData';

@Component({
  selector: 'validation-messages',
  templateUrl: './validation-messages.component.html',
  styleUrls: ['./validation-messages.component.scss']
})
export class ValidationMessagesComponent implements OnInit {
  @Input() messages: {}[];
  constructor() { }

  ngOnInit() {
  }

}

@Component({
  selector: 'validation-messages2',
  template: `
  <nb-alert *ngFor="let message of messages" status="danger">
    {{message.key | translate}}: {{message.value | translate : message.params}}
  </nb-alert>
  `,
  styleUrls: ['./validation-messages.component.scss']
})
export class ValidationMessages2Component implements OnInit {
  @Input() messages: ValidationData[];
  constructor() { }

  ngOnInit() {
  }
}
