import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
} from '@angular/common/http';

import {Observable, of, throwError} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { OurEduLocalStorageService } from '../services/our-edu-local-storage.service';
// import { NbToastrService } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

export interface ItemError {
    status: number;
    title: string;
    detail: string;
}
export interface BackEndErrorStructure {
    errors: ItemError[];
}
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(
        private toastr: ToastrService,
        private tranny: TranslateService,
        private spinner: NgxSpinnerService,
        private router: Router,
        private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token: string = OurEduLocalStorageService.getUserData().token;

        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        if (!request.headers.has('Content-Type') && !(request.body instanceof FormData)) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        let currentLang = OurEduLocalStorageService.getCurrentLanguage();
        request = request.clone({ headers: request.headers.set('Accept', 'application/json'), url: request.url.replace("-Lang-", currentLang) });
        // this.spinner.show();

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event--->>>', event);
                    // this.errorDialogService.openDialog(event);
                    // this.spinner.hide();
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log('error.status', error.status);
                if (error.status == 422) {
                    const data: BackEndErrorStructure = error.error;
                    const translatedKeys = data.errors.map(e => e.title).concat('validation_errors');
                    this.tranny.get(translatedKeys).subscribe(res => {
                        let listOfErrors = data.errors.map(e => `<li><strong>${res[e.title]}:</strong> <span>${e.detail}</span></li>`);
                        let listMarkup: string = listOfErrors.reduce((result, current) => result + current, '');
                        const html = `<div class='grosso' style="width: 500px"><ul style="list-style:none">${listMarkup}</ul></div>`;
                        this.toastr.warning(html, res['validation_errors'], {
                            enableHtml: true,
                            timeOut: 5000,
                            positionClass: 'toast-top-center',
                            toastClass: 'ngx-toastr validation-hoe'
                        });
                    });
                }
              if (error.status === 401 || error.status === 403) {
                // this.router.navigateByUrl(`/#/auth/login`);
                this.authenticationService.logout();
                this.router.navigate(['login']);
              }
                // this.errorDialogService.openDialog(data);
                return throwError(error);
            }));
    }
}
