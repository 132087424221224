import { CanDeactivateComponent } from './../../../can-deactivate-component';
import { NewSubjectFormService, SubjectFormData } from './../subjectForm.new.service';
import { NewSubjectService, Subject } from './../../../services/subject.service.new';
import { Component, OnInit, ViewChild, ViewContainerRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MediaService } from '../../../services/media.service';
import { Subscription } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NbToastrService } from '@nebular/theme';
import * as ClassicEditor from '../../../../assets/js/ck-editor-math-type/ckeditor.js';
import {environment} from '../../../../environments/environment';
import {CKEDITOR_CONFIG} from '../../../util';


@Component({
  selector: 'ngx-subject-edit',
  templateUrl: './subject-edit.component.html',
  styleUrls: ['./subject-edit.component.scss'],
})

export class SubjectEditComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
  public Editor = ClassicEditor;
  public baseApiURL = environment.mediaBaseUrl+ 'en/media/upload';

  @ViewChild('testOutlet', { read: ViewContainerRef, static: false })
  zoomFileTemp: ViewContainerRef;
  langChange: Subscription;
  zoomFile: any;
  subjectId: any;
  subject: Subject;
  tabs: any;
  subjectFormData: SubjectFormData;
  srvrrror: boolean = false;
  loading: boolean;
  refreshIsNeeded: boolean;
  curretLang: string;
  files: any;

  ckeditorConfig: any = CKEDITOR_CONFIG;

  constructor(
    private subjectService: NewSubjectService,
    private subjectFormService: NewSubjectFormService,
    private cdr: ChangeDetectorRef,
    private toastr: NbToastrService,
    private route: ActivatedRoute,
    private media: MediaService,
    private translate: TranslateService) {
      super()
  }
  
  ngOnInit() {
    this.subjectId = this.route.snapshot.paramMap.get('subjectId');
    this.loadData();

    this.curretLang = this.translate.getDefaultLang();
    this.langChange = this.translate.onLangChange.subscribe(() => {
      this.loadData();
    });
  }

  setZoomFile(file: any) {
    if (!(file.mime_type.includes('audio') || file.mime_type.includes('video') || file.mime_type.includes('image')))
      window.open(file.url, '_blank');
    else
      this.zoomFile = file;
  }

  stopParentEvent(event) {
    event.stopPropagation();
  }

  removeZoomFile() {
    this.zoomFile = null
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0] && !this.loading) {
      this.files = event.target.files;
      this.loading = true;
      (<HTMLInputElement>document.getElementById("myFile")).disabled = true;
      this.media.addNewMedia(this.files).subscribe(filesData => {
        this.loading = false;
        (<HTMLInputElement>document.getElementById("myFile")).disabled = false;
        if (filesData)
          this.subjectFormService.createMediaForm(this.subjectFormData, filesData.data);
      })
    }
    event.target.value = null;
  }

  removeFile(fileID) {
    this.subjectFormService.removeMedia(this.subjectFormData['media'], fileID);
  }

  fileType(mime_type, type): boolean {
    if (type === 'file' && !(mime_type.includes('audio') || mime_type.includes('video') || mime_type.includes('image')))
      return true;
    else
      return mime_type.includes(type)
  }

  private loadData() {
    this.loading = true;
    this.subjectService.getMinimalSubject(this.subjectId)
      .subscribe(this.afterLoaded);
  }

  private afterLoaded: (value: Subject) => void = data => {
    this.loading = false;
    this.subject = data;
    this.subjectFormData = this.subjectFormService.createSubjectForm(this.subject);
  };

  addSection() {
    this.subjectFormService.addNewSection(this.subjectFormData.nestedForms);
  }

  onDragOver(event) {
    event.preventDefault();
    this.subjectFormService.onDragOverComponent(event, this.subjectFormData);
  }

  onDrop(event) {
    event.preventDefault();
    this.subjectFormService.onDropComponent(event, this.subjectFormData, this.subjectId);
    this.refreshIsNeeded = !this.refreshIsNeeded;
  }

  onDragLeave(event) {
    this.subjectFormService.removeDragOverStyleIfExists(event);
  }

  onSubmit() {
    console.log('this.subjectFormData', this.subjectFormData);
    this.subjectService.updateSubject(this.subjectFormData, this.subject).subscribe(subjectWithMedia => {
      this.toastr.success('Success');
      this.loadData();
    });
  }

  generate() {
    this.loading = true;
    this.subjectService.generateSubject(this.subjectId).subscribe(r => {
      this.loading = false;
      this.toastr.success('Generated successfully');
    })
  }

  scrollToInvalidForm(formID) {
    const elementToScroll = document.getElementById(formID)
    elementToScroll.scrollIntoView();
  }

  ngOnDestroy() {
    this.langChange.unsubscribe();
  }
}

