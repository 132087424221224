import { ModuleWithProviders, NgModule } from "@angular/core";
import { MathDirective } from "./math.directive";

@NgModule({
  declarations: [MathDirective],
  exports: [MathDirective]
})
export class MathModule {
  constructor() {
    console.log(`module constructor`);
    const script = document.createElement("script") as HTMLScriptElement;
    script.type = "text/javascript";
    script.src = "https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image";
    script.async = true;

    document.getElementsByTagName("head")[0].appendChild(script);
  }

  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: MathModule,
    };
  }
}
