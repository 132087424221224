import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Task } from '../models/task';
import { BaseService } from './base.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Formatter } from 'sarala-json-api-data-formatter';
import { ApiAction } from '../models/ApiAction';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class TaskService extends BaseService {
  private triggerMethod$ = new Subject<any>();
  // private taskEditAction = null;

  constructor(private http: HttpClient, private fb: FormBuilder) {
    super();
  }
  // get taskEditAction$() {
  //   return this.taskEditAction;
  // }

  // set taskEditAction$(taskEditAction) {
  //   this.taskEditAction = taskEditAction;
  // }
  get getTriggerMethod$() {
    return this.triggerMethod$.asObservable();
  }

  callTriggerMethod(methodType) {
    this.triggerMethod$.next(methodType);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  getTaskPerformanceLst(pagenumber): Observable<any[]> {
    const url = `${BaseService.baseUrl}/sme/subjects/tasks-performance?page=${pagenumber}`;
    return this.http.get<any[]>(url)
      .pipe(
        tap(task => console.log('fetched tasks')),
        catchError(this.handleError('getTaskPerformanceLst', [])),
      );
  }
  getTaskPerformanceDetail(id): Observable<any> {
    const url = `${BaseService.baseUrl}/sme/subjects/tasks/${id}`;
    return this.http.get<any>(url)
      .pipe(
        tap(task => console.log('fetched tasks detail')),
        catchError(this.handleError('getTaskPerformanceDetail', [])),
      );
  }
  getTasks(page: any, filters: any): Observable<Task[]> {
    const url = `${BaseService.baseUrl}/sme/subjects/tasks`;

    let params = new HttpParams();
    Object.keys(filters).forEach(filterKey => {
      if (filters[filterKey])
        params = params.set(filterKey, filters[filterKey]);

    })
    params = params.set('page', page);
    return this.http.get<Task[]>(url, { params: params })
      .pipe(
        tap(),
        catchError(this.handleError('getSubjects', [])),
      );
  }
  //http://ouredu.testenv.tech/api/v1/en/sme/subjects/edit-resource-subject-format/5477
  getEditResource(param: { editAction?: ApiAction, id?: any }) {
    const url = param.editAction ?
      param.editAction.endpoint_url :
      `${BaseService.baseUrl}/sme/subjects/edit-resource-subject-format/${param.id}`;
    return this.http.get(url);
  }

  getSubjectTasks(filters: any, page: any, id: number): Observable<Task[]> {
    debugger;
    const url = `${BaseService.baseUrl + '/sme/subjects'}/${id}/tasks`;

    let params = new HttpParams();
    Object.keys(filters).forEach(filterKey => {
      if (filters[filterKey])
        params = params.set(filterKey, filters[filterKey]);
    })
    params = params.set('page', page);
    return this.http.get<Task[]>(url, { params: params })
      .pipe(
        tap(),
        catchError(this.handleError('getSubjects', [])),
      );
  }

  serializeDragDrop(task, taskForm, resourceSubjectFormatSubjectData) {
    const id = resourceSubjectFormatSubjectData === null ? 'new' : resourceSubjectFormatSubjectData.id;
    task['resource_subject_format_subject_data'] = {
      data: {
        ...taskForm.value,
        'id': id,
        'type': 'resource_subject_format_subject_data',
      },
    };
    task['relationships'].push('resource_subject_format_subject_data');

    const formatter = new Formatter();
    const taskSerialized = formatter.serialize({ ...task });
    return taskSerialized;
  }

  serializeTrueFalse(task, taskForm, learningResourceAcceptCriteria, resourceSubjectFormatSubjectData) {
    const id = resourceSubjectFormatSubjectData === null ? 'new' : resourceSubjectFormatSubjectData.id;
    task['resource_subject_format_subject_data'] = {
      data: {
        'description': taskForm.value.description,
        'true_false_type': learningResourceAcceptCriteria.true_false_type,
        'questions': taskForm.value.questions,
        'id': id,
        'type': 'resource_subject_format_subject_data',
      },
    };
    task['relationships'].push('resource_subject_format_subject_data');
    const formatter = new Formatter();
    const taskSerialized = formatter.serialize({ ...task });
    return taskSerialized;
  }

  serializeMultipleChoise(task, taskForm, learningResourceAcceptCriteria, resourceSubjectFormatSubjectData) {
    const id = resourceSubjectFormatSubjectData === null ? 'new' : resourceSubjectFormatSubjectData.id;
    task['resource_subject_format_subject_data'] = {
      data: {
        'description': taskForm.value.description,
        'questions': taskForm.value.questions,
        'id': id,
        'type': 'resource_subject_format_subject_data',
      },
    };
    task['learning_resource_accept_criteria'] = {
      data: {
        'type': learningResourceAcceptCriteria.type,
        'id': learningResourceAcceptCriteria.id,
        'resource_id': learningResourceAcceptCriteria.resource_id,
        'resource_slug': learningResourceAcceptCriteria.resource_slug,
        'due_date': learningResourceAcceptCriteria.due_date,
        'description': learningResourceAcceptCriteria.description,
        'number_of_question': learningResourceAcceptCriteria.number_of_question,
        'difficulty_level': learningResourceAcceptCriteria.difficulty_level,
        'learning_outcome': learningResourceAcceptCriteria.learning_outcome,
        'multiple_choice_type': learningResourceAcceptCriteria.multiple_choice_type,
      },
    };

    task['relationships'].push('resource_subject_format_subject_data');
    task['relationships'].push('learning_resource_accept_criteria');
    const formatter = new Formatter();
    const taskSerialized = formatter.serialize({ ...task });
    return taskSerialized;
  }

  serializeVideo(task, taskForm) {
    task['resource_subject_format_subject_data'] = {
      data: {
        'id': 'new',
        'type': 'resource_subject_format_subject_data',
        'relationships': []
      },
    };
    task['relationships'].push('resource_subject_format_subject_data');
    const dodo = task['resource_subject_format_subject_data'].data;
    if (taskForm.value.description)
      dodo['description'] = taskForm.value.description;
    if (taskForm.value.video)
      dodo['video'] = taskForm.value.video;
    if (taskForm.value.title) {
      dodo['title'] = taskForm.value.title;
    }
    if (taskForm.value.attachMedia) {
      dodo['attachMedia'] = {
        data: {
          'id': taskForm.value.attachMedia || '',
          'type': 'attach_media',
        },
      };
      dodo['relationships'].push('attachMedia');
    }

    if (taskForm.value.detachMedia) {
      dodo['detachMedia'] = {
        data: {
          'id': taskForm.value.detachMedia || '',
          'type': 'detach_media',
        },
      };
      dodo['relationships'].push('detachMedia');
    }

    const formatter = new Formatter();
    const taskSerialized = formatter.serialize({ ...task });
    return taskSerialized;
  }

  serializePdf(task, taskForm) {
    task['resource_subject_format_subject_data'] = {
      data: {
        'id': 'new',
        'type': 'resource_subject_format_subject_data',
      },
    };
    task['relationships'].push('resource_subject_format_subject_data');
    if (taskForm.value.description)
      task['resource_subject_format_subject_data'].data['description'] = taskForm.value.description;
    if (taskForm.value.pdf)
      task['resource_subject_format_subject_data'].data['pdf'] = taskForm.value.pdf;
    if (taskForm.value.title) {
      task['resource_subject_format_subject_data'].data['title'] = taskForm.value.title;
    }
    if (taskForm.value.attachMedia) {
      task['attachMedia'] = {
        data: {
          'id': taskForm.value.attachMedia || '',
          'type': 'attach_media',
        },
      };
      task['relationships'].push('attachMedia');
    }

    if (taskForm.value.detachMedia) {
      task['detachMedia'] = {
        data: {
          'id': taskForm.value.detachMedia || '',
          'type': 'detach_media',
        },
      };
      task['relationships'].push('detachMedia');
    }

    const formatter = new Formatter();
    const taskSerialized = formatter.serialize({ ...task });
    return taskSerialized;
  }

  serializeAudio(task, taskForm) {
    task['resource_subject_format_subject_data'] = {
      data: {
        'id': 'new',
        'type': 'resource_subject_format_subject_data',
        'relationships': []
      },
    };
    task['relationships'].push('resource_subject_format_subject_data');
    const dodo = task['resource_subject_format_subject_data'].data;
    if (taskForm.value.description)
      dodo['description'] = taskForm.value.description;
    if (taskForm.value.audio)
      dodo['audio'] = taskForm.value.audio;
    if (taskForm.value.title) {
      dodo['title'] = taskForm.value.title;
    }
    if (taskForm.value.attachMedia) {
      dodo['attachMedia'] = {
        data: {
          'id': taskForm.value.attachMedia || '',
          'type': 'attach_media',
          'attributes': {},
        },
      };
      dodo['relationships'].push('attachMedia');
    }

    if (taskForm.value.detachMedia) {
      dodo['detachMedia'] = {
        data: {
          'id': taskForm.value.detachMedia || '',
          'type': 'detach_media',
          'description': 'test picture des',
        },
      };
      dodo['relationships'].push('detachMedia');
    }

    const formatter = new Formatter();
    const taskSerialized = formatter.serialize({ ...task });
    return taskSerialized;
  }

  serializePicture(task, taskForm, resourceSubjectFormatSubjectDataID, learningResourceAcceptCriteria) {
    task['resource_subject_format_subject_data'] = {
      data: {
        'id': resourceSubjectFormatSubjectDataID,
        'type': 'resource_subject_format_subject_data',
        'relationships': []
      },
    };
    task['relationships'].push('resource_subject_format_subject_data');
    const dodo = task['resource_subject_format_subject_data'].data;

    if (taskForm.value.description) {
      dodo['description'] = taskForm.value.description;
    }
    if (taskForm.value.title) {
      dodo['title'] = taskForm.value.title;
    }

    if (taskForm.value.attachMedia) {
      const attaches = [{
        'id': taskForm.value.attachMedia,
        'type': 'attach_media',
      }];
      dodo['attachMedia'] = {
        data: attaches
      };
      dodo['relationships'].push('attachMedia');
    }

    if (taskForm.value.detachMedia) {
      const detaches = [{
        'id': taskForm.value.detachMedia,
        'type': 'detach_media',
      }];
      dodo['detachMedia'] = {
        data: detaches
      };
      dodo['relationships'].push('detachMedia');
    }
    const formatter = new Formatter();
    const taskSerialized = formatter.serialize({ ...task });
    console.log('serialized', taskSerialized);
    return taskSerialized;
  }

  serializePage(task, taskForm) {
    task['resource_subject_format_subject_data'] = {
      data: {
        'id': 'new',
        'type': 'resource_subject_format_subject_data',
      },
    };
    task['learning_resource_accept_criteria_field'] = {
      data: {
        'id': '1dd450e0-682b-4c2d-82d9-64e380e2f7d5',
        'type': 'learning_resource_accept_criteria_field',
      },
    };
    task['relationships'].push('resource_subject_format_subject_data');
    task['relationships'].push('learning_resource_accept_criteria_field');
    task['resource_subject_format_subject_data'].data['page'] = taskForm.controls.page.value;
    task['resource_subject_format_subject_data'].data['title'] = taskForm.controls.title.value;
    const formatter = new Formatter();
    const taskSerialized = formatter.serialize({ ...task });
    taskSerialized;
    return taskSerialized;
  }

  serializeFlash(task, taskForm) {
    task['resource_subject_format_subject_data'] = {
      data: {
        'id': 'new',
        'type': 'resource_subject_format_subject_data',
        'relationships': []
      },
    };
    task['learning_resource_accept_criteria_field'] = {
      data: {
        'id': '1dd450e0-682b-4c2d-82d9-64e380e2f775',
        'type': 'learning_resource_accept_criteria_field',
      },
    };
    task['relationships'].push('resource_subject_format_subject_data');
    task['relationships'].push('learning_resource_accept_criteria_field');
    const dodo = task['resource_subject_format_subject_data'].data;
    
    if (taskForm.value.description)
      dodo['description'] = taskForm.value.description;
    if (taskForm.value.title) {
      dodo['title'] = taskForm.value.title;
    }
    if (taskForm.value.attachMedia) {
      dodo['attachMedia'] = {
        data: {
          'id': taskForm.value.attachMedia,
          'type': 'attach_media',
        },
      };
      dodo['relationships'].push('attachMedia');
    }

    if (taskForm.value.detachMedia) {
      dodo['detachMedia'] = {
        data: {
          'id': taskForm.value.detachMedia,
          'type': 'detach_media',
        },
      };
      dodo['relationships'].push('detachMedia');
    }

    const formatter = new Formatter();
    const taskSerialized = formatter.serialize({ ...task });
    return taskSerialized;

    for (const includedTask of taskSerialized['included']) {
      if (includedTask.type === 'resource_subject_format_subject_data') {
        includedTask.relationships = new Object();
      }
    }

    if (taskForm.value.attachMedia.length > 0) {
      taskSerialized['included'].push({
        'id': taskForm.value.attachMedia || '',
        'type': 'attach_media',
        'attributes': {},
      });
      for (const includedTask of taskSerialized['included']) {
        if (includedTask.type === 'resource_subject_format_subject_data') {
          includedTask.relationships.attachMedia = {
            data: {
              'id': taskForm.value.attachMedia || '',
              'type': 'attach_media',
            },
          };
        }
      }
    }
    if (taskForm.value.detachMedia) {
      taskSerialized['included'].push({
        'id': taskForm.value.detachMedia || '',
        'type': 'detach_media',
        'attributes': {},
      });
      for (const includedTask of taskSerialized['included']) {
        if (includedTask.type === 'resource_subject_format_subject_data') {
          includedTask.relationships.detachMedia = {
            data: {
              'id': taskForm.value.detachMedia || '',
              'type': 'detach_media',
            },
          };
        }
      }
    }
    return taskSerialized;
  } 
  
  serializeCompleteQuestion(task, taskForm, learningResourceAcceptCriteria, resourceSubjectFormatSubjectData) {
    const id = resourceSubjectFormatSubjectData === null ? 'new' : resourceSubjectFormatSubjectData.id;
    task['resource_subject_format_subject_data'] = {
      data: {
        'id': id,
        'type': 'resource_subject_format_subject_data',
      },
    };
    task['learning_resource_accept_criteria'] = {
      data: {
        'id': learningResourceAcceptCriteria.id,
        'type': 'learning_resource_accept_criteria',
      },
    };
    task['relationships'].push('resource_subject_format_subject_data');
    task['relationships'].push('learning_resource_accept_criteria');
    const formatter = new Formatter();
    const taskSerialized = formatter.serialize({ ...task });
    for (let incudedObj of taskSerialized.included) {
      if (incudedObj.type === 'resource_subject_format_subject_data') {
        incudedObj.attributes = taskForm.value;
      }
    }
    return taskSerialized;
  }

  serializeMatchingQuestion(task, taskForm) {
    task['resource_subject_format_subject_data'] = {
      data: {
        'id': 'new',
        'type': 'resource_subject_format_subject_data',
        'question_feedback': taskForm.value.question_feedback,
        'description': taskForm.value.description
      },
    };
    task['learning_resource_accept_criteria_field'] = {
      data: {
        'id': '1dd450e0-682b-4c2d-82d9-64e380e2f775',
        'type': 'learning_resource_accept_criteria_field',
      },
    };
    task['relationships'].push('resource_subject_format_subject_data');
    task['relationships'].push('learning_resource_accept_criteria_field');
    const formatter = new Formatter();
    const taskSerialized = formatter.serialize({ ...task });
    for (let incudedObj of taskSerialized.included) {
      if (incudedObj.type === 'resource_subject_format_subject_data') {
        incudedObj.attributes.questions = taskForm.value.questions;
        incudedObj.attributes.options = taskForm.value.options;
      }
    }
    return taskSerialized;
  }

  serializeMultiMatchingQuestion(task, taskForm) {
    task['resource_subject_format_subject_data'] = {
      data: {
        'id': 'new',
        'type': 'resource_subject_format_subject_data',
        'question_feedback': taskForm.value.question_feedback,
        'description': taskForm.value.description
      },
    };
    task['learning_resource_accept_criteria_field'] = {
      data: {
        'id': '1dd450e0-682b-4c2d-82d9-64e380e2f775',
        'type': 'learning_resource_accept_criteria_field',
      },
    };
    task['relationships'].push('resource_subject_format_subject_data');
    task['relationships'].push('learning_resource_accept_criteria_field');
    const formatter = new Formatter();
    const taskSerialized = formatter.serialize({ ...task });
    for (let incudedObj of taskSerialized.included) {
      if (incudedObj.type === 'resource_subject_format_subject_data') {
        incudedObj.attributes.questions = taskForm.value.questions;
        incudedObj.attributes.options = taskForm.value.options;
      }
    }
    return taskSerialized;
  }

  serializeHotSpot(task, taskForm, resourceSubjectFormatSubjectData) {
    const id = resourceSubjectFormatSubjectData === null ? 'new' : resourceSubjectFormatSubjectData.id;
    task['resource_subject_format_subject_data'] = {
      data: {
        'description': taskForm.value.description,
        'questions': taskForm.value.questions,
        'id': id,
        'type': 'resource_subject_format_subject_data',
      },
    };

    task['relationships'].push('resource_subject_format_subject_data');
    const formatter = new Formatter();
    const taskSerialized = formatter.serialize({ ...task });
    return taskSerialized;
  }

  actionTask(method, endPoint, body?): Observable<any> {
    switch (method) {
      case 'POST':
        return this.http.post<any>(endPoint, body).pipe(
          tap(task => {
          }),
          catchError(this.handleError<Task>(`POSTPullTask`)),
        );
      case 'GET':
        endPoint = `${BaseService.baseUrl}/content-author/subjects/resources/${endPoint}/fill`;
        return this.http.get<any>(endPoint).pipe(
          tap(),
          catchError(this.handleError<Task>(`getFillTask`)),
        );
      case 'PUT':
        return this.http.put<any>(endPoint, body).pipe(
          tap(),
          catchError(err => this.handleError<Task>(err)),
        );
      default:
        this.handleError<Task>(`not defined action`);
        return;
    }
  }

  getDateInfo(created_at: Date) {
    const date = new Date(created_at);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    return { day, month };
  }

  makeTaskDone(method, endPoint): Observable<any> {
    return this.http.get<any>(endPoint).pipe(
      tap(),
      catchError(this.handleError<Task>(`getFillTask`)),
    );
  }
}
