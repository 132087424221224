import { Component, OnInit, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Formatter } from 'sarala-json-api-data-formatter';
import { Task } from '../../models/task';
import { TaskService } from '../../services/task.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CanDeactivateComponent } from '../../can-deactivate-component';

@Component({
  selector: 'ngx-subject-tasks',
  templateUrl: './subject-tasks.component.html',
  styleUrls: ['./subject-tasks.component.scss'],
})
export class SubjectTasksComponent extends CanDeactivateComponent implements OnInit, OnChanges, OnDestroy {

  langChange: Subscription;
  subjectId: number;
  tasks: { freeAssigned: Task[], assignedToMe: Task[] } = { freeAssigned: [], assignedToMe: [] };
  loading: { freeAssigned: boolean, assignedToMe: boolean };
  isEmpty: boolean = false;
  activeTab: string;
  noTasks: boolean = false;
  pagination = Object.create({});
  curretLang: string;
  filters: [];
  activeFilters: any;
  tabChanged = false;
  constructor(private tasksService: TaskService, private translate: TranslateService,
    private router: ActivatedRoute) {
      super()
    this.router = router;
    this.router.params.subscribe(params => {
      this.subjectId = params.subjectId;
    });
  }

  ngOnInit() {
    this.loading = { freeAssigned: true, assignedToMe: true };
    this.activeTab = 'freeAssigned';
    this.activeFilters = {};
    this.filters = [];
    this.getTasks(this.subjectId, 'first', 'all');
    this.curretLang = this.translate.getDefaultLang();
    this.langChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      console.log(event)
      this.curretLang = event.lang;
      this.getTasks(this.subjectId, this.pagination[this.activeTab].current_page, 'all');
    });
  }


  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    console.log(changes);
  }
  getTasks(subjectId, pagination: number | 'first' | 'last', page: 'assignedToMe' | 'freeAssigned' | 'all', filters?) {
    let customPagination = pagination;
    if (pagination == 'first') {
      customPagination = this.pagination[page] && this.pagination[page].total_pages ? 1 : 1;
    }
    if (pagination == 'last') {
      customPagination = this.pagination[page] && this.pagination[page].total_pages ? this.pagination[page].total_pages.length : 1;
    }
    if (page === 'all') {
      this.getFreeAssignedSubjectTasks(customPagination, subjectId, filters);
      this.getAssignedToMeSubjectTasks(customPagination, subjectId, filters);
    } else if (page === 'assignedToMe') {
      this.getAssignedToMeSubjectTasks(customPagination, subjectId, filters);
    } else if (page === 'freeAssigned') {
      this.getFreeAssignedSubjectTasks(customPagination, subjectId, filters);
    }

  }
  afterPullTaskAction(event) {
    if (event === 'pull_task') {
      this.tabChanged = true;
      this.getTasks(this.subjectId, 'first', 'assignedToMe');
      this.changeActiveTab('assignedToMe');
    }
  }
  changeActiveTab(tab: any) {
    this.activeTab = tab;
    this.tabChanged = true;
    this.activeFilters = {};
    this.getTasks(this.subjectId, 'first', tab);
    this.checkIfTaskListExits();
  }
  checkIfTaskListExits() {
    if (this.activeTab === 'freeAssigned')
      (this.tasks.freeAssigned.length > 0) ? this.noTasks = false : this.noTasks = true;
    else
      (this.tasks.assignedToMe.length > 0) ? this.noTasks = false : this.noTasks = true;
  }
  setPagination(datas, activeTab) {
    this.pagination[activeTab] = { ...datas.links, ...datas.meta.pagination };
    const total_pages = []
    for (let index = 0; index < this.pagination[activeTab].total_pages; index++) {
      const active = index + 1 == activeTab ? true : false
      total_pages.push({ id: index + 1, active: active })
    }
    this.pagination[activeTab].total_pages = total_pages;
    this.loading[activeTab] = false;
  }
  getFreeAssignedSubjectTasks(pagination: string | number, subjectId: any, filters?) {
    if (!filters) { filters = {} } else {
      this.activeFilters = filters;
    }
    this.activeFilters.is_assigned = 'no';
    this.tasksService.getSubjectTasks(this.activeFilters, pagination, subjectId)
      .subscribe(data => {
        const formatter = new Formatter();
        const datas = formatter.deserialize(data);
        this.filters = datas.meta.filters.filter(filter => filter.name !== 'is_assigned' && filter.name !== 'subject_id');
        this.tabChanged = false;
        this.tasks.freeAssigned = datas.data.map((task: any) => {
          const dateInfo = this.tasksService.getDateInfo(task.created_at);
          return { ...task, ...dateInfo };
        });
        this.loading.freeAssigned = false;
        this.setPagination(datas, 'freeAssigned');
        this.checkIfTaskListExits();
      });
  }
  getAssignedToMeSubjectTasks(pagination: string | number, subjectId: any, filters?) {
    if (!filters) { filters = {} } else {
      this.activeFilters = filters;
    }
    this.activeFilters.is_assigned = 'yes';
    this.tasksService.getSubjectTasks(this.activeFilters, pagination, subjectId)
      .subscribe(data => {
        const formatter = new Formatter();
        const datas = formatter.deserialize(data);
        this.tabChanged = false;
        this.filters = datas.meta.filters.filter(filter => filter.name !== 'is_assigned' && filter.name !== 'subject_id');
        this.tasks.assignedToMe = datas.data.map((task: any) => {
          const dateInfo = this.tasksService.getDateInfo(task.created_at);
          return { ...task, ...dateInfo };
        });
        this.loading.assignedToMe = false;
        this.setPagination(datas, 'assignedToMe');
        this.checkIfTaskListExits();
      });
  }
  filterTasks(filters) {
    if (filters.is_assigned === 'no') {
      this.activeTab = 'freeAssigned';
      this.getFreeAssignedSubjectTasks(this.subjectId, 'first', filters);

    } else {
      this.activeTab = 'assignedToMe';
      this.getAssignedToMeSubjectTasks(this.subjectId, 'first', filters);

    }
  }
  ngOnDestroy() {
    this.langChange.unsubscribe();
  }
}
