import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'subjects-filter',
  templateUrl: './subjects-filter.component.html',
  styleUrls: ['./subjects-filter.component.scss']
})
export class SubjectsFilterComponent implements OnInit {
  @Output() filtersChange = new EventEmitter();
  @Input() filters: [any];
  filterValues = {};
  curretLang: string;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    this.curretLang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.curretLang = event.lang;
    });
    this.createFilterObject();
  }

  createFilterObject() {
    console.log(this.filters);
    if (this.filters) {
      this.filters.forEach(filter => {
        this.filterValues[filter.name] = null;
      });
    }
  }

  selectedValue(options, key) {
    const selectedOption = options.find(option => option.key === key);
    return selectedOption ? selectedOption.value : '';
  }

  submitHandle() {
    this.filtersChange.emit({ ...this.filterValues });
  }
}
