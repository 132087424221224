import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, BehaviorSubject, Subscribable, concat, merge, forkJoin } from 'rxjs';
import { catchError, tap, map, concatAll, combineAll, flatMap } from 'rxjs/operators';
import { SubjectD,SubjectLookup } from '../models/subject';
import { BaseService } from './base.service';
import { Formatter } from 'sarala-json-api-data-formatter';
import { FormBuilder, Validators, FormArray, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NbToastrService } from '@nebular/theme';
import { DifficultyLevel } from '../models/DifficultyLevel';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
const apiUrl = BaseService.baseUrl + '/sme/subjects';

@Injectable({
  providedIn: 'root',
})
export class SubjectService extends BaseService {
  private rmovcomponnt = new Subject<any>();
  private submitTrird = new Subject<any>();
  formatter = new Formatter();
  subjectForm: any;
  resourceTemplates$ = new BehaviorSubject<any>([]);
  private permenantRemovedComponents = {
    removed_sections: [],
    removed_resources: []
  };
  private media = {
    attachMedia: [],
    detachMedia: []
  }
  constructor(private http: HttpClient, private fb: FormBuilder, private translate: TranslateService, private toastrService: NbToastrService) {
    super();

  }

  private handleSuccess() {
    this.translate.get("serverResponse.subjectForm.success").subscribe((successMessage: string) => {
      this.toastrService.show(
        'Success',
        successMessage,
        { status: 'success' });
    });
  }

  private handleError<T>(operation: any, result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      if (error.status == 422 && error.error && error.error.errors) {
        let errorMsg = '';
        error.error.errors.forEach(err => {
          if (err.status == 422) {
            errorMsg += err.detail;
          }
        });
        if (!errorMsg) {
          errorMsg = 'serverResponse.subjectForm.error';
          this.translate.get(errorMsg).subscribe((errorMessage: string) => {
            this.toastrService.show(
              'Error',
              errorMessage,
              { status: 'danger' });
          });
        } else {
          this.toastrService.show(
            'Error',
            errorMsg,
            { status: 'danger' });
        }
      }
      return of(result as T);
    };
  }
  get trmovcomponnt() {
    return this.rmovcomponnt.asObservable();
  }
  get tsubmitTrird() {
    return this.submitTrird.asObservable();
  }
  sndsubmitTrird(data) {
    this.submitTrird.next(data);
  }
  sndrmovcomponnt(data) {
    this.rmovcomponnt.next(data);
  }
  get getPermenantRemovedComponents() {
    return this.permenantRemovedComponents;
  }
  setPermenantRemovedComponents(type: 'subject_format_subject' | 'resource_subject_format_subject', id) {
    const removedType = type === 'subject_format_subject' ? 'sections' : 'resources';
    this.permenantRemovedComponents[`removed_${removedType}`].push({
      id: id,
      type: `removed_${removedType}`
    });
  }
  setMedia(type: 'attach_media' | 'detach_media', id) {
    if (type === 'attach_media') {
      this.media['attachMedia'].push({
        "type": type,
        "id": id
      });
    } else if (type === 'detach_media') {
      this.media['detachMedia'].push({
        "type": type,
        "id": id
      });
    }

  }
  getSubjects(pagenumber, filters?): Observable<SubjectD[]> {
    const url = `${BaseService.baseUrl}/sme/subjects`;

    let params = new HttpParams();
    if (filters) {
      Object.keys(filters).forEach(filterKey => {
        if (filters[filterKey])
          params = params.set(filterKey, filters[filterKey]);
      })
    }
    params = params.set('page', pagenumber);

    // return this.http.get<SubjectD[]>(BaseService.baseUrl + '/sme/subjects?page=' + pagenumber)
    return this.http.get<SubjectD[]>(url, { params: params })
      .pipe(
        tap(subject => console.log('fetched subjects')),
        catchError(this.handleError('getSubjects', [])),
      );
  }

  // NOTE: emits multiple times, not just once
  getAllSubjects(): Observable<SubjectD[]> {
    const firstLoad = this.http.get<any>(BaseService.baseUrl + '/sme/subjects?page=1');
    const higherOrder = firstLoad.pipe(
      flatMap((dataForFirstLoad): Observable<SubjectD[]> => {
        const observables: Observable<SubjectD[]>[] = [];
        const formatter = new Formatter();
        const datas = formatter.deserialize(dataForFirstLoad);
        const total_pages = datas.meta.pagination.total_pages as number;
        for (let i = 1; i <= total_pages; i++) {
          observables.push(this.http.get<any>(BaseService.baseUrl + '/sme/subjects?page=' + i).pipe(
            map((data): SubjectD[] => formatter.deserialize(data).data)
          ));
        }
        let result: Observable<SubjectD[]> = merge(...observables);
        return result;
      })
    );
    return higherOrder;
  }

  getAllSubjectsLookup(): Observable<any> {

    return this.http.get<any>(BaseService.baseUrl + '/look-up?include=subjects')
      .pipe(
        tap(data => {
          const m = this.formatter.deserialize(data);
          return m;
        }),
        catchError(this.handleError('getAllSubjectsLookup', [])),
      );

  }


  getDifficultyLevels(): Observable<any> {
    return this.http.get<any>(BaseService.baseUrl + '/look-up?include=difficultyLevel')
      .pipe(
        tap(data => {
          const m = this.formatter.deserialize(data);
          console.log(m);
          return m;
        }),
        catchError(this.handleError('getAcademicalYears', [])),
      );
  }
  resetSubjectPrivateVariables() {
    this.permenantRemovedComponents = {
      removed_sections: [],
      removed_resources: []
    };
  }
  getSubject(id: number): Observable<any> {
    this.resetSubjectPrivateVariables()
    const url = `${BaseService.baseUrl + '/sme/subjects'}/${id}`;

    return this.http.get<any>(url).pipe(
      tap(data => {
        const m = this.formatter.deserialize(data);
        console.log(m)
        return m;
      }),
      catchError(this.handleError<SubjectD>(`getSubject id=${id}`)),
    );
  }

  getSubjectFormatSubject(id: number): Observable<any> {
    this.resetSubjectPrivateVariables()
    const url = `${BaseService.baseUrl + '/sme/subjects/view-subject-format-subject'}/${id}`;

    return this.http.get<any>(url).pipe(
      tap(data => {
        const m = this.formatter.deserialize(data);
        console.log(m)
        return m;
      }),
      catchError(this.handleError<SubjectD>(`getSubject id=${id}`)),
    );
  }

  cloneSubject(id: number): Observable<SubjectD> {
    this.resetSubjectPrivateVariables()
    const url = `${BaseService.baseUrl + '/sme/subjects/clone-subject'}/${id}`;

    return this.http.get<SubjectD>(url).pipe(
      tap(data => {
        const m = this.formatter.deserialize(data);
        console.log(m)
        return m;
      }),
      catchError(this.handleError<SubjectD>(`getSubject id=${id}`)),
    );
  }

  getAcademicalYears(): Observable<any> {
    return this.http.get<any>(BaseService.baseUrl + '/look-up?include=academicYear')
      .pipe(
        tap(data => {
          const m = this.formatter.deserialize(data);
          console.log(m);
          return m;
        }),
        catchError(this.handleError('getAcademicalYears', [])),
      );
  }

  addSubject(subject: SubjectD): Observable<SubjectD> {
    return this.http.post<any>(BaseService.baseUrl + '/sme/subjects', subject, httpOptions).pipe(
      tap((prod: SubjectD) => console.log(`added subject w/ id=${subject.id}`)),
      catchError(this.handleError<SubjectD>('addSubject')),
    );
  }
  formatSubject(subjectForm, subjectData) {
    subjectData['subject_library_text'] = subjectForm.form.get('subject_library_text').value;
    if (subjectData['relationships']) {
      for (let relationIndex = 0; relationIndex < subjectData['relationships'].length; relationIndex++) {
        const relation = subjectData['relationships'][relationIndex];
        delete subjectData[relation]
      }
      delete subjectData['relationships']
    }
    if (subjectForm.nestedForms) {
      console.log(subjectForm, subjectData);
      this.addComponents(subjectForm.nestedForms, subjectData)
    }
    if (subjectForm.media)
      this.formatMedia(subjectForm.media, subjectData)
  }
  formatMedia(mediaPath: any[], subjectData) {
    subjectData.attachMedia = {
      data: [],
      data_collection: true,
    }
    subjectData.detachMedia = {
      data: [],
      data_collection: true,
    }
    if (!subjectData['relationships'])
      subjectData['relationships'] = [];
    subjectData['relationships'].push("attachMedia", "detachMedia");

    for (let mediaIndex = 0; mediaIndex < mediaPath.length; mediaIndex++) {
      const media = mediaPath[mediaIndex];
      if (media.type === 'garbage_media') {
        media.type = 'attach_media';
        subjectData.attachMedia.data.push({
          id: media.id,
          type: media.type
        })
      } else if (media.type === 'detach_media') {
        subjectData.detachMedia.data.push({
          id: media.id,
          type: media.type
        })
      }
    }
  }
  addComponents(formPath: any[], formatData) {
    for (let formIndex = 0; formIndex < formPath.length; formIndex++) {
      const componentForm = formPath[formIndex]
      if (componentForm.type === 'subject_format_subject') {
        if (!formatData.subjectFormatSubjects) {
          formatData.subjectFormatSubjects = {
            data: [],
            data_collection: true,
          }
          if (!formatData['relationships'])
            formatData['relationships'] = [];
          formatData['relationships'].push("subjectFormatSubjects");
        }
        formatData.subjectFormatSubjects.data.push(this.formatSection(componentForm))
        if (componentForm['nestedForms']) {
          const sectionData = formatData.subjectFormatSubjects.data[formatData.subjectFormatSubjects.data.length - 1];
          this.addComponents(componentForm.nestedForms, sectionData)
        }
      } else if (componentForm.type === 'resource_subject_format_subject') {
        if (!formatData.resourceSubjectFormatSubjects) {
          formatData.resourceSubjectFormatSubjects = {
            data: [],
            data_collection: true,
          }
          if (!formatData['relationships'])
            formatData['relationships'] = [];
          formatData['relationships'].push("resourceSubjectFormatSubjects");
        }
        formatData.resourceSubjectFormatSubjects.data.push(this.formatResource(componentForm))
      }
    }
  }
  formatSection(sectionForm) {
    const formattedSection = { ...sectionForm };
    for (const valueKey in formattedSection.form.value) {
      if (formattedSection.form.value.hasOwnProperty(valueKey)) {
        const value = formattedSection.form.value[valueKey];
        formattedSection[valueKey] = value
      }
    }
    formattedSection['subject_type'] = 'section'
    delete formattedSection.form
    delete formattedSection.nestedForms
    return formattedSection
  }

  formatResource(resourceForm) {
    const formattedResource = { ...resourceForm };
    formattedResource.is_active = formattedResource.form.value.is_active;
    formattedResource['resource_slug'] = resourceForm.slug;
    (<FormGroup>formattedResource.form).removeControl('is_active');

    formattedResource.learningResourceAcceptCriteria = {
      data: {
        type: "learning_resource_accept_criteria_field",
        //   id: `JSONApi_${Math.floor((Math.random() * 100) + 1)}`,
        resource_id: resourceForm.resource_id,
        resource_slug: resourceForm.slug
      },
    }
    formattedResource['relationships'] = ["learningResourceAcceptCriteria"];

    for (const valueKey in formattedResource.form.value) {
      if (formattedResource.form.value.hasOwnProperty(valueKey)) {
        const value = formattedResource.form.value[valueKey];
        formattedResource.learningResourceAcceptCriteria.data[valueKey] = value
      }
    }
    delete formattedResource.form
    return formattedResource
  }
  addRemovedComponents(formatData) {
    formatData.removed_sections = {
      data: this.permenantRemovedComponents.removed_sections,
      data_collection: true,
    }
    formatData.removed_resources = {
      data: this.permenantRemovedComponents.removed_resources,
      data_collection: true,
    }

    formatData.relationships ? formatData.relationships.push('removed_sections', 'removed_resources')
      : formatData.relationships = ['removed_sections', 'removed_resources']
  }
  removeActions(formatData) {
    delete formatData.actions;
    const actionIndex = formatData.relationships.findIndex(relation => relation === 'actions');
    if (actionIndex > -1)
      formatData.relationships.splice(actionIndex, 1)
  }
  updateSubject(endpoint_url: string, subjectForm: any, subjectData: any): Observable<any> {
    let subjectDataSend = { ...subjectData };
    this.formatSubject(subjectForm, subjectDataSend)
    this.addRemovedComponents(subjectDataSend)
    this.removeActions(subjectDataSend)
    const subjectSerialzed = this.formatter.serialize(subjectDataSend);

    return this.http.put(endpoint_url, subjectSerialzed, httpOptions).pipe(
      tap(subjectRspons => {
        console.log(`updated subjct id=${subjectRspons['id']}`);
        this.handleSuccess()
      }),
      catchError(this.handleError('updateSubject'))
    );

  }

  removeNotIncludedSections(formatData, cloneSubjectModel: CloneSubjectModel[]) {
    const incudedFormatSubjects = formatData.subjectFormatSubjects.data.filter(x => cloneSubjectModel
      .findIndex(y => y.id === x.id && y.included === true) !== -1);
    formatData.subjectFormatSubjects.data = incudedFormatSubjects;
  }

  cloneSubjectPost(endpoint_url: string, subjectForm: any, subjectData: any, cloneSubjectModel: CloneSubjectModel[]): Observable<any> {
    let subjectDataSend = { ...subjectData };
    this.formatSubject(subjectForm, subjectDataSend);
    this.removeNotIncludedSections(subjectDataSend, cloneSubjectModel);
    this.removeActions(subjectDataSend)
    const subjectSerialzed = this.formatter.serialize(subjectDataSend);
    return this.http.post(endpoint_url, subjectSerialzed, httpOptions).pipe(
      tap(subjectRspons => {
        this.handleSuccess();
      }),
      catchError(this.handleError('cloneSubject'))
    );

  }

  getResourceTemplates = (() => {
    return this.http.get<any>(BaseService.baseUrl + '/learning-resources')
      .pipe(
        tap(resource => console.log('fetched resources')),
        catchError(this.handleError('getSubjects', [])),
      ).subscribe(data => {
        const dataSerialized = this.formatter.deserialize(data);
        console.log(dataSerialized.data);
        const resourceTemplates = dataSerialized.data;
        this.resourceTemplates$.next(resourceTemplates);
      });
  })()

  toggleSubjectStatus(subjectId: number): Observable<any> {
    const url = `${BaseService.baseUrl}/sme/subjects/${subjectId}/pause-unpause`;
    return this.http.post<any>(url, {});
  }
}
