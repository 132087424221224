import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { LanguageConfig } from '../../../../../models/LanguageConfig';
import { QuestionData, mapToMasterQuestion } from '../../../../../models/QuestionData';
import { TrueFalseQuestion } from '../../../../../models/MasterQuestion';

@Component({
  selector: 'trueorfalse',
  templateUrl: './trueorfalse.component.html',
  styleUrls: ['./trueorfalse.component.scss'],
})
export class TrueorfalseComponent implements OnChanges {

  @Input() questionData: QuestionData;
  tfQuestion: TrueFalseQuestion;
  @Input() languageConfig: LanguageConfig;
  @Input() selectedQuestions: number[] = [];
  @Output() questionSelected: EventEmitter<number> = new EventEmitter<number>();
  @Output() questionRemoved: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tfQuestion = <TrueFalseQuestion>mapToMasterQuestion(this.questionData);
  }

  ngOnInit() {
  }

  selectQuestion(id){
    this.questionSelected.emit(id);
  }

  removeQuestion(id){
    this.questionRemoved.emit(id);
  }
}
