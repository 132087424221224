import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { QuestionData } from '../../../models/QuestionData';
import { MasterQuestion } from '../../../models/MasterQuestion';
import { LanguageConfig } from '../../../models/LanguageConfig';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralExamService } from '../../../services/general-exam.service';
import { PaginationConfig } from '../../../models/PaginationConfig';

@Component({
  selector: 'inner-preview',
  templateUrl: './inner-preview.component.html',
  styleUrls: ['./preview-questions.scss']
})
export class InnerPreviewQuestionsComponent {

  @Input() questions: MasterQuestion[];
  @Input() languageConfig: LanguageConfig;
  @Input() config: PaginationConfig;
  @Output() pageChange = new EventEmitter<number>();

  constructor() {
  }

  onPageChange(pageNumber) {
    this.pageChange.emit(pageNumber);
  }
}
