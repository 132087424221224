import { PendingChangesGaurd } from './auth/helpers/pending-changes.gaurd';
import { SubjectEditComponent } from './components/subjects/subject-edit-new/subject-edit.component';
import { TasksListComponent } from './components/tasks/tasks-list/tasks-list.component';
import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/helpers';
import { LoginComponent } from './auth/login';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ProfileUpdateComponent } from './components/profile/profile-update/profile-update.component';
import { SubjectTasksComponent } from './components/subject-tasks/subject-tasks.component';
import { SubjectsComponent } from './components/subjects/subject-list/subjects.component';
import { RequestPaswordComponent } from './auth/request-password';
import { ResetPaswordComponent } from './auth/reset-password';
import { SubjectCloneComponent } from './components/subjects/subject-clone/subject-clone.component';
import { QuestionReportsListComponent } from './components/question-reports/question-reports-list/question-reports-list.component';
import { QuestionReportsSubjectComponent } from './components/question-reports/question-reports-subject/question-reports-subject.component';
import { QuestionDetailComponent } from './components/question-reports/question-detail/question-detail.component';
import { TaskPerformanceDetailComponent } from './components/task-performance/task-performance-detail/task-performance-detail.component';
import { TaskPerformanceComponent } from './components/task-performance/task-performance.component';
import { TaskPerformanceListComponent } from './components/task-performance/task-performance-list/task-performance-list.component';
import { ListGeneralExamsComponent } from './components/general-exam/list-general-exams/list-general-exams.component';
import { GeneralExamComponent } from './components/general-exam/general-exam/general-exam.component';
import { CreateEditExamComponent } from './components/general-exam/create-edit-exam/create-edit-exam.component';
import { GeneralExamQuestionsComponent } from './components/general-exam/general-exam-questions/general-exam-questions.component';
import { SubjectDetailsComponent } from './components/subjects/subject-details/subject-details.component';
import { SubSectionsComponent } from './components/subjects/sub-sections/sub-sections.component';
import { PreviewQuestionsFullComponent } from './components/general-exam/preview-questions/preview-questions-full.component';
import { GeneralExamStudentsComponent } from './components/general-exam/general-exam-students/general-exam-students.component';
import { DiscriminationQuestionReportsSubjectComponent } from './components/discrimination-question-reports/discrimination-question-reports-subject/discrimination-question-reports-subject.component';
import { DiscriminationQuestionReportsListComponent } from './components/discrimination-question-reports/discrimination-question-reports-list/discrimination-question-reports-list.component';
import { DiscriminationQuestionDetailComponent } from './components/discrimination-question-reports/discrimination-question-detail/discrimination-question-detail.component';

const routes: Routes = [
  {
    path: 'auth/login',
    component: LoginComponent,
  },
  {
    path: 'auth/request-password',
    component: RequestPaswordComponent,
  }, {
    path: 'auth/reset-password',
    component: ResetPaswordComponent,
  },
  {
    path: 'auth/logout',
    component: LogoutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'subjects',
    component: SubjectsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'question-reports',
    component: QuestionReportsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'question-reports/subjects/:subjectId',
    component: QuestionReportsSubjectComponent,
    canDeactivate: [PendingChangesGaurd],
    canActivate: [AuthGuard],
  },
  {
    path: 'question-reports/question/:questionId',
    component: QuestionDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'task-performance/list',
    component: TaskPerformanceComponent,
    canDeactivate: [PendingChangesGaurd],
    canActivate: [AuthGuard],
  },
  {
    path: 'task-performance/list/:id',
    component: TaskPerformanceListComponent,
    canDeactivate: [PendingChangesGaurd],
    canActivate: [AuthGuard],
  },
  {
    path: 'task-performance/Detail/:taskId',
    component: TaskPerformanceDetailComponent,
    canDeactivate: [PendingChangesGaurd],
    canActivate: [AuthGuard],
  },
  {
    path: 'subjects/:subjectId/edit',
    component: SubjectEditComponent,
    canDeactivate: [PendingChangesGaurd],
    canActivate: [AuthGuard]
  },
  {
    path: 'subjects/:subjectId/clone',
    component: SubjectCloneComponent,
    children: [
      {
        path: '',
        component: SubjectCloneComponent,
        canDeactivate: [PendingChangesGaurd],
        canActivate: [AuthGuard],
      }, {
        path: ':subjectId/clone',
        component: SubjectCloneComponent,
        canActivate: [AuthGuard],
        canDeactivate: [PendingChangesGaurd],
      },
    ],
  },
  {
    path: 'subjects/:subjectId/details',
    component: SubjectDetailsComponent,
    children: [
      {
        path: '',
        component: SubjectDetailsComponent,
        canActivate: [AuthGuard],
      }, {
        path: ':subjectId/details',
        component: SubjectDetailsComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'discrimination-reports/subjects',
    component: DiscriminationQuestionReportsListComponent,
    children: [
      {
        path: '',
        component: DiscriminationQuestionReportsListComponent,
        canActivate: [AuthGuard],
      }, {
        path: ':subjectId/details',
        component: SubjectDetailsComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'subjects/subsections/:subjectId/:sectionId',
    component: SubSectionsComponent,
    canDeactivate: [PendingChangesGaurd],
    canActivate: [AuthGuard],
  },
  {
    path: 'fill/:resId',
    loadChildren: () => import('./components/subjects/fill-resource/fill-resource.module').then(m => m.FillResourceModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'subjects/:subjectId/tasks',
    component: SubjectTasksComponent,
    canDeactivate: [PendingChangesGaurd],
    canActivate: [AuthGuard],
  },
  {
    path: 'pages',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'tasks',
    component: TasksListComponent,
    canDeactivate: [PendingChangesGaurd],
    canActivate: [AuthGuard],
  },
  {
    path: 'profile/update',
    component: ProfileUpdateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'general-exams',
    component: GeneralExamComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ListGeneralExamsComponent,
        canDeactivate: [PendingChangesGaurd],
      },
      {
        path: 'create',
        component: CreateEditExamComponent,
        canDeactivate: [PendingChangesGaurd],
      },
      {
        path: 'edit/:id',
        component: CreateEditExamComponent,
        canDeactivate: [PendingChangesGaurd],
      },
      {
        path: 'questions/:id',
        component: GeneralExamQuestionsComponent,
        canDeactivate: [PendingChangesGaurd],
      },
      {
        path: 'preview-questions/:id',
        component: PreviewQuestionsFullComponent,
        canDeactivate: [PendingChangesGaurd],
      },
      {
        path: 'list-students/:id',
        component: GeneralExamStudentsComponent,
        canDeactivate: [PendingChangesGaurd],
      },
      {
        path: 'discrimination-question-report/:id',
        component: DiscriminationQuestionReportsSubjectComponent,
        canDeactivate: [PendingChangesGaurd],
      },
      {
        path: 'discrimination-question-report/:id/:questionId/details',
        component: DiscriminationQuestionDetailComponent,
        canDeactivate: [PendingChangesGaurd],
      },
    ],
  },
  // otherwise redirect to login
  { path: '**', redirectTo: 'auth/login' },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
