import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ResourceDataService{
    
    private _resource : any;
    public get resource() : any {
        const temp = this._resource;
        this._resource = null;
        return temp;
    }
    public set resource(v : any) {
        this._resource = v;
    }
    
}