import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { Task } from '../models/task';
import { BaseService } from './base.service';
import { FetchedGeneralExam, CreateGeneralExam, ExamStudent } from '../models/general-exam';
import { Formatter } from 'sarala-json-api-data-formatter';
import { SubjectD } from '../models/subject';
import { PreparedQuestion } from '../models/PreparedQuestion';
import { QuestionData, mapToMasterQuestion } from '../models/QuestionData';
import { ApiAction } from '../models/ApiAction';
import { MasterQuestion, CompleteQuestion, DragDropQuestion, MatchingQuestion, MultiMatchingQuestion, MultipleChoiceQuestion, TrueFalseQuestion } from '../models/MasterQuestion';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
    providedIn: 'root',
})
export class NotificationService extends BaseService {
    private readonly service_url = `${BaseService.baseUrl}/notifications`;
    constructor(private http: HttpClient) {
        super();
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error); // log to console instead
            return of(result as T);
        };
    }

    getAll(pageNumber): Observable<any> {
        return this.http.get<any>(`${this.service_url}?page=${pageNumber}`)
    }



    getExamStudents(id: number): Observable<ExamStudent[]> {
        return this.http.get<any>(`${this.service_url}/exam-students/${id}`)
            .pipe(
                map(data => {
                    const formatter = new Formatter();
                    const datas = formatter.deserialize(data);
                    return datas.data
                }),
                catchError(this.handleError('', [])),
            );
    }
}
