import { ActivatedRoute, Router } from "@angular/router";
import { SubjectService } from "../../../services/subject.service";
import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  OnDestroy,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { Formatter } from "sarala-json-api-data-formatter";
import { interval, Subscription } from "rxjs";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { SubjectFormService } from "../subjectForm.service";
import { CanDeactivateComponent } from "../../../can-deactivate-component";
@Component({
  selector: "ngx-subject-clone",
  templateUrl: "./subject-clone.component.html",
  styleUrls: ["./subject-clone.component.scss"],
})
export class SubjectCloneComponent  extends CanDeactivateComponent implements OnInit, OnDestroy {

  @ViewChild('testOutlet', { read: ViewContainerRef, static: false })

  testOutlet: ViewContainerRef | undefined;
  langChange: Subscription;
  subjectId: any;
  clarrrors: boolean = true;

  modules = {
    formula: true,
    toolbar: [[{ indent: "-1" }, { indent: "+1" }], ["formula"]],
  };
  NonOveridedSubject: any;
  subject: any = {
    actions: {
      data: [],
    },
  };
  resources: any;
  secondsCounter: any;
  tabs: any;
  sections: any;
  lookupSectionsTypes: any;
  subSections: any;
  subjectForm: { nestedForms?: [] };
  srvrrror: boolean = false;
  loading: boolean;
  curretLang: string;
  reorder: boolean;
  // cloneSubjectModel: CloneSubjectModel[];
  cloneSubjectModel: any[];
  cloneFormSubmited = false;
  acdimicalYears: any[];
  @ViewChild("f", { static: false }) cloneForm: NgForm;
  constructor(
    private subjectService: SubjectService,
    private subjectFormService: SubjectFormService,
    private router: ActivatedRoute,
    private translate: TranslateService,
    private routerService: Router
  ) {
    super();
    this.router = router;
    this.getAcdimacalYear();
  }

  ngOnInit() {
    this.subjectForm = {};
    this.subjectFormService.createSubjectForm(this.subjectForm, {});

    this.reorder = false;
    this.router.params.subscribe((params) => {
      this.subjectId = params.subjectId;
      this.reloadData(this.subjectId);
    });
    this.curretLang = this.translate.getDefaultLang();
    this.langChange = this.translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        console.log(event);
        this.reloadData(this.subjectId);
      }
    );
    this.secondsCounter = interval(10000);

    this.subjectService.tsubmitTrird.subscribe((data) => {
      this.clarrrors = !data;
      this.loading = false;
    });
  }

  stopParentEvent(event) {
    event.stopPropagation();
  }

  reloadData(subjectId: any) {
    this.loading = true;
    this.subjectService.cloneSubject(subjectId).subscribe((data) => {
      const formatter = new Formatter();
      this.loading = false;
      this.subject = formatter.deserialize(data);
      this.NonOveridedSubject = JSON.parse(JSON.stringify(this.subject));
      this.SetAllSubjectsFormatsubjectNotEditable();
      this.fillCloneSubjectModel(this.subject);
      this.subjectFormService.createSubjectForm(this.subjectForm, this.subject);
    });
  }

  getAcdimacalYear() {
    this.subjectService.getAcademicalYears().subscribe((data) => {
      this.acdimicalYears = data.data[0].academicYear.data;
    });
  }

  SetAllSubjectsFormatsubjectNotEditable() {
    this.subject.subjectFormatSubjects.data.map((todo, i) => {
      this.subject.subjectFormatSubjects.data[i].is_editable = false;
    });
  }

  fillCloneSubjectModel(subject: any) {
    this.cloneSubjectModel = [];
    subject.subjectFormatSubjects.data.forEach((element) => {
      this.cloneSubjectModel.push({
        id: element.id,
        included: false,
      });
    });
  }

  getSection(list_order_key) {
    const sections = this.subject.subjectFormatSubjects.data;
    const sectionIndex = sections.findIndex(
      (section) => section.list_order_key === list_order_key
    );
    if (sectionIndex > -1) {
      const returnedSection = {
        id: sections[sectionIndex].id,
        is_active: sections[sectionIndex].is_active,
        is_editable: sections[sectionIndex].is_editable,
        list_order_key: sections[sectionIndex].list_order_key,
        subject_type: sections[sectionIndex].subject_type,
        title: sections[sectionIndex].title,
        type: sections[sectionIndex].type,
      };
      return returnedSection;
    }
  }

  onSubmit(actionIndex) {
    this.subjectFormService.triggerSubjectFormSubmit$ = true;
    const formInvalid = this.subjectFormService.getSubjectFormInValid;
    this.cloneFormSubmited = true;
    if (!formInvalid.length && !this.loading && this.cloneForm.valid) {
      this.loading = true;
      this.subjectService
        .cloneSubjectPost(
          this.subject.actions["data"][actionIndex].endpoint_url,
          this.subjectForm,
          this.NonOveridedSubject,
          this.cloneSubjectModel
        )
        .subscribe((data) => {
          this.subjectFormService.triggerSubjectFormSubmit$ = false;
          this.loading = false;
          console.log(
            "🚀 ~ file: subject-clone.component.ts ~ line 149 ~ SubjectCloneComponent ~ onSubmit ~ data",
            data
          );
          if (data) {
            this.routerService.navigate(["/subjects"]);
          }
        },err => {
          console.log(err)
        });
    } else this.scrollToInvalidForm();
  }

  scrollToInvalidForm() {
    const elementToScroll = document.getElementById("clone-form");
    elementToScroll.scrollIntoView();
  }

  checkDirections(i) {
    if (i % 2 !== 0)
      if (this.curretLang === "en") return "float-right";
      else return "float-left";
  }

  ngOnDestroy() {
    this.langChange.unsubscribe();
  }
}
