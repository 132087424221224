import { Component, OnInit, Input } from '@angular/core';
import { MultiMatchingQuestion } from '../../../../../models/MasterQuestion';
import { LanguageConfig } from '../../../../../models/LanguageConfig';

@Component({
  selector: 'view-multi-matching',
  templateUrl: './view-multi-matching.component.html',
  styleUrls: ['./view-multi-matching.component.scss']
})
export class ViewMultiMatchingComponent implements OnInit {
  @Input() languageConfig: LanguageConfig;
  @Input() questionData: MultiMatchingQuestion;

  constructor() { }

  ngOnInit() {
  }

}
