import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { QuestionData, mapToMasterQuestion } from '../../../../../models/QuestionData';
import { LanguageConfig } from '../../../../../models/LanguageConfig';
import { MultiMatchingQuestion } from '../../../../../models/MasterQuestion';

@Component({ selector: 'multi-matching', templateUrl: './multi-matching.component.html', styleUrls: ['./multi-matching.component.scss'] })

export class MultiMatchingComponent implements OnInit {

  matchingQuestion: MultiMatchingQuestion;
  @Input() questionData: QuestionData;
  @Input() languageConfig: LanguageConfig;
  @Input() selectedQuestions: number[] = [];
  @Output() questionSelected: EventEmitter<void> = new EventEmitter<void>();
  @Output() questionRemoved: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnChanges(): void {
    this.matchingQuestion = <MultiMatchingQuestion>mapToMasterQuestion(this.questionData);
  }

  ngOnInit() {
  }

  selectQuestion() {
    this.questionSelected.emit();
  }

  removeQuestion() {
    this.questionRemoved.emit();
  }
}
