import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageUploadComponent } from './image-upload.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ImageUploadComponent],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    ImageUploadComponent,
  ],

})
export class ImageModule { }
