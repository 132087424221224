import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, AfterViewInit } from '@angular/core';
// starting from here
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';
import { AlertService } from '../../services/alert.service';
import { OurEduLocalStorageService } from '../../services/our-edu-local-storage.service';

@Component({
  selector: 'ngx-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPaswordComponent implements OnInit {
  ResetPaswordForm: FormGroup;
  loading = false;
  submitted = false;
token ='';
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private translate: TranslateService,
  ) {
    // redirect to home if already logged in
    debugger;
    if (this.authenticationService.currentUserValue || OurEduLocalStorageService.getUserData().token) {
      this.router.navigate(['pages/dashboard']);
    }
    //check token and redirect to login if not set
    this.route.queryParams.subscribe(params => {
      this.token= params['token'];
      if(this.token == undefined)
      this.router.navigate(['auth/login']);
  });
  }

  ngOnInit() {
    this.ResetPaswordForm = this.formBuilder.group({
      newPassword: ['', Validators.required],
      confirmPassword:  ['', [Validators.required, this.passwordMatcher.bind(this)]],
    });

  }

  private passwordMatcher(control: FormControl): { [s: string]: boolean } {
    if (
        this.ResetPaswordForm &&
        (control.value !== this.ResetPaswordForm.controls.newPassword.value)
    ) {
        return { passwordNotMatch: true };
    }
    return null;
}




  // convenience getter for easy access to form fields
  get f() { return this.ResetPaswordForm.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.ResetPaswordForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.ResetPassword(this.f.newPassword.value,this.f.confirmPassword.value,this.token)
      .pipe(first())
      .subscribe(
        data => {
          this.alertService.success(data,true);
          this.loading = false;
          this.router.navigate(['auth/login']);
        },
        error => {
          this.alertService.error(error.error.errors);
          this.loading = false;
        });
  }




}
