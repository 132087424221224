import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NbCardModule, NbInputModule } from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { ImageModule } from '../../image-upload/image-upload.module';
import { ProfileUpdateComponent } from './profile-update.component';


@NgModule({
  declarations: [ProfileUpdateComponent],
  imports: [
    CommonModule,
    ImageModule,
    NbInputModule,
    NbCardModule,
    FormsModule,
    TranslateModule.forRoot(),
  ],
})
export class ProfileModule {
  constructor() {
}
}
