import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NebularStuffModule } from '../../nebular-stuff/nebular-stuff.module';
import { NbSidebarModule, NbMenuModule, NbDialogModule, NbWindowModule, NbToastrModule } from '@nebular/theme';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { ValidationMessagesComponent, ValidationMessages2Component } from './validation-messages/validation-messages.component';
import { attributeTypePipe } from './pipes/attributeType.pipe';
import { OrderByPipe } from './pipes/orderPipe';
import { attributeKeyPipe } from './pipes/attributeKey.pipe';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import { deleteKeysPipe } from './pipes/deleteKeysPipe';
import { HeaderNoteComponent } from './header-note/header-note.component';
import { QuestionNoteComponent } from './question-note/question-note.component';
import { AnswerNoteComponent } from './answer-note/answer-note.component';
import { UploadVideoNoteComponent } from './upload-video-note/upload-video-note.component';
import { UploadAudioNoteComponent } from './upload-audio-note/upload-audio-note.component';
import { LinkVideoNoteComponent } from './link-video-note/link-video-note.component';
import { LinkAudioNoteComponent } from './link-audio-note/link-audio-note.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    ConfirmDialogComponent,
    ValidationMessagesComponent,
    ValidationMessages2Component,
    SafeHtmlPipe,
    attributeTypePipe,
    deleteKeysPipe,
    OrderByPipe,
    attributeKeyPipe,
    HeaderNoteComponent,
    QuestionNoteComponent,
    AnswerNoteComponent,
    UploadVideoNoteComponent,
    UploadAudioNoteComponent,
    LinkVideoNoteComponent,
    LinkAudioNoteComponent
  ],
  entryComponents: [
    ConfirmDialogComponent
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NebularStuffModule,
    // ThemeModule,
    NbSidebarModule,
    NbMenuModule,
    NbDialogModule,
    NbWindowModule,
    NbToastrModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true,
    })
  ],
  exports: [
    SafeHtmlPipe,
    ValidationMessages2Component,
    ValidationMessagesComponent,
    attributeTypePipe,
    OrderByPipe,
    attributeKeyPipe,
    deleteKeysPipe,
    HeaderNoteComponent,
    QuestionNoteComponent,
    AnswerNoteComponent,
    UploadVideoNoteComponent,
    UploadAudioNoteComponent,
    LinkVideoNoteComponent,
    LinkAudioNoteComponent,
 ]
})
export class PartialsModule { }
