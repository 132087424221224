import { FormGroup } from '@angular/forms';
import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TaskService } from '../../../services/task.service';
import { Task } from '../../../models/task';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
    selector: 'tasks-filter',
    templateUrl: './tasks-filter.component.html',
    styleUrls: ['./tasks-filter.component.scss']
})

export class TasksFilterComponent implements OnInit, OnDestroy, OnChanges {
    @Output() filtersChange = new EventEmitter();
    @Input() filters: [any];
    @Input() reset: boolean;
    filterValues = {};
    curretLang: string;
    constructor(private tasksService: TaskService, private translate: TranslateService) { }

    ngOnInit() {
        this.curretLang = this.translate.getDefaultLang();
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.curretLang = event.lang;
        });
        this.createFilterObject()
    }
    ngOnChanges(changes: SimpleChanges): void {

        if (this.reset) this.createFilterObject();
    }
    createFilterObject() {
        this.filters.forEach(filter => {
            this.filterValues[filter.name] = null;
        });
    }
    selectedValue(options, key) {
        const selectedOption = options.find(option => option.key === key);
        return selectedOption ? selectedOption.value : '';
    }
    submitHandle(name) {
        this.filtersChange.emit({ ...this.filterValues });
    }

    ngOnDestroy() {

    }

};

