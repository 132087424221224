import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { Task } from '../models/task';
import { BaseService } from './base.service';
import { FetchedGeneralExam, CreateGeneralExam, ExamStudent } from '../models/general-exam';
import { Formatter } from 'sarala-json-api-data-formatter';
import { SubjectD } from '../models/subject';
import { PreparedQuestion } from '../models/PreparedQuestion';
import { QuestionData, mapToMasterQuestion } from '../models/QuestionData';
import { ApiAction } from '../models/ApiAction';
import { MasterQuestion, CompleteQuestion, DragDropQuestion, MatchingQuestion, MultiMatchingQuestion, MultipleChoiceQuestion, TrueFalseQuestion } from '../models/MasterQuestion';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
    providedIn: 'root',
})
export class GeneralExamService extends BaseService {
    private readonly service_url = `${BaseService.baseUrl}/sme/general-exams`;

    constructor(private http: HttpClient) {
        super();
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error); // log to console instead
            return of(result as T);
        };
    }

    getAll(pageNumber): Observable<FetchedGeneralExam[]> {
        return this.http.get<any>(`${this.service_url}?page=${pageNumber}`)
    }

    getById(id: number, pageNumber: number = 1): Observable<FetchedGeneralExam> {
        const url = `${this.service_url}/${id}?question-page=${pageNumber}`;
        return this.http.get<any>(url)
            .pipe(
                map(data => {
                    const formatter = new Formatter();
                    const datas = formatter.deserialize(data);
                    return datas;
                }),
                catchError(this.handleError('dude2', [])),
            );
    }

    getSubject(exam: FetchedGeneralExam): Observable<any> {
        const url = exam.actions.data.find(a => a.key == 'view subject sections').endpoint_url;
        return this.http.get<any>(url);
    }

    getSubjectQuestions(url: string): Observable<{ pagination: any, questions: QuestionData[] }> {
        // const url = `${this.service_url}/subject-sections/${id}`;
        return this.http.get<any>(url).pipe(
            map(response => {
                const pagination = response.meta.pagination;
                try {
                    let questionDatas: QuestionData[] = response.included.filter(i => i.type == 'question_exam_data').map((i): QuestionData => ({
                        id: i.id,
                        type: i.type,
                        ...i.attributes,
                        prepared_question_id: null
                    }));
                    let preparedQuestions_raw = response.data;
                    //remove duplicate prepared questions
                    for (let i = 0; i < preparedQuestions_raw.length; i++) {
                        const preparedQuestion_raw = preparedQuestions_raw[i];
                        let preparedQuestion: PreparedQuestion = {
                            id: preparedQuestion_raw.id,
                            type: preparedQuestion_raw.type,
                            ...preparedQuestion_raw.attributes
                        };
                        let questionData = questionDatas.find(i => i.id == preparedQuestion_raw.relationships.questionData.data.id);
                        questionData.prepared_question_id = preparedQuestion.id
                        questionData.prepared_question = preparedQuestion;
                        questionData.question_type = preparedQuestion.question_type;
                    }
                    return { pagination: pagination, questions: questionDatas };
                }
                catch {
                    return { pagination: pagination, questions: [] };
                }
            })
        );
    }

    create(model: CreateGeneralExam) {
        const url = `${this.service_url}`;
        const attributes: any = Object.assign({}, model);
        attributes.date = this.convertDateToDateString(model.date);
        const postModel = {
            id: null,
            type: 'general_exam',
            attributes: attributes
        };
        return this.http.post(url, { data: postModel });
    }

    edit(id: number, model: CreateGeneralExam) {
        const url = `${this.service_url}/${id}`;
        const attributes: any = Object.assign({}, model);
        attributes.date = this.convertDateToDateString(model.date);
        this.removeUnwantedProperties(attributes);
        const postModel = {
            id: model.id,
            type: 'general_exam',
            attributes: attributes
        };
        return this.http.post(url, { data: postModel });
    }

    convertDateToDateString(dodo: Date | string) {
        var date = new Date(dodo);
        const numericalMonth = date.getMonth() + 1;
        var month: string = numericalMonth > 9 ? numericalMonth.toString() : `0${numericalMonth}`;
        var zaString = `${date.getFullYear()}-${month}-${date.getDate()}`
        return zaString;
    }

    publish(id: number, action: ApiAction) {
        return this.http.get(action.endpoint_url);
    }

    deleteExam(id: number) {
        return this.http.get(`${this.service_url}/${id}/delete`);
    }

    updateQuestions(id: number, preparedQuestions: number[]) {
        const url = `${this.service_url}/${id}/update-questions`;
        let postModel = {
            "data": {
                "id": id,
                "type": "general_exam",
                "attributes": {
                    "prepared_questions": preparedQuestions
                }
            }
        }
        return this.http.post(url, postModel);
    }

    getPreviewQuestionsForExam(exam: any): MasterQuestion[] {
        let questions: MasterQuestion[] = [];
        let preparedQuestionDatas: QuestionData[] = this.getQuestionDatasFromRawExam(exam);

        preparedQuestionDatas.forEach(singleBoy => questions.push(mapToMasterQuestion(singleBoy)));
        return questions;
    }

    getQuestionDatasFromRawExam(exam: FetchedGeneralExam): QuestionData[] {
        let questions = exam.questions || exam.preparedQuestions;
        if (!questions) {
            return [];
        }
        return questions.data.map(p => p.questionData.data[0] ? {
            question_type: p.question_type,
            prepared_question_id: p.id,
            ...p.questionData.data[0]
        } : {
                question_type: p.question_type,
                prepared_question_id: p.id,
                ...p.questionData.data
            });
    }

    private removeUnwantedProperties(attributes: any) {
        attributes.id = undefined;
        attributes.type = undefined;
        attributes.relationships = undefined;
        attributes.links = undefined;
    }

    getExamStudents(id: number): Observable<ExamStudent[]> {
        return this.http.get<any>(`${this.service_url}/exam-students/${id}`)
            .pipe(
                map(data => {
                    const formatter = new Formatter();
                    const datas = formatter.deserialize(data);
                    return datas.data
                }),
                catchError(this.handleError('', [])),
            );
    }
}
