import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TaskService } from '../../../services/task.service';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'task-summary',
  templateUrl: './task-summary.component.html',
  styleUrls: ['./task-summary.component.scss'],
})
export class TaskSummaryComponent implements OnInit {

  @Input() task: any;
  @Output() pullingTaskAction = new EventEmitter();
  statusMessage: string;

  constructor(private tasksService: TaskService, private router: Router, private translate: TranslateService) {
  }

  more: boolean = false;
  attributes: any;
  curretLang: string;

  ngOnInit() {
    this.curretLang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.curretLang = event.lang;
    });
    if (this.task.resourceSubjectFormatSubject.data.learningResourceAcceptCriteria) {
      this.attributes = this.task.resourceSubjectFormatSubject.data.learningResourceAcceptCriteria.data;
      delete this.attributes.id;
      delete this.attributes.type;
    } else {
      this.attributes = null;
    }

  }

  toggleMore() {
    this.more = !this.more;
  }

  actionTask(actionID) {
    const action = this.task.actions.data[actionID];
    if (action.key === 'pull_task')
      this.tasksService.actionTask(action.method, action.endpoint_url).subscribe(data => {
        {
          this.pullingTaskAction.emit('pull_task');
          this.tasksService.callTriggerMethod('getTasks');
        }
      });
    else if (action.key === 'fill_resource') {
      //  this.tasksService.taskEditAction$ = action;
      const resourceID = this.task.resourceSubjectFormatSubject.data.id;
      this.router.navigate([`/pages/tasks/${this.task.id}/${resourceID}/form`]);
    }
  }

  getStatus() {
    if (!this.task.is_expired && this.task.is_assigned) {
      this.translate.get('assignedNotExpired').subscribe(
        (value: any) => {
          this.statusMessage = value;
        });
      return 'success';
    } else if (this.task.is_expired && this.task.is_assigned) {
      this.translate.get('assignedExpired').subscribe(
        (value: any) => {
          this.statusMessage = value;
        });
      return 'danger';
    } else if (!this.task.is_assigned) {
      this.translate.get('notAssigned').subscribe(
        (value: any) => {
          this.statusMessage = value;
        });
      return 'warning';
    }
  }

  getTranslation(key: string) {
    if (this.translate !== undefined)
      return this.translate.instant(key);
  }
}
