import { Injectable, Renderer2, RendererFactory2, ElementRef } from '@angular/core';
import { Formatter } from 'sarala-json-api-data-formatter';
import { FormBuilder, Validators, FormArray, FormControl, FormGroup } from '@angular/forms';
import { Subject, BehaviorSubject } from 'rxjs';
import { SubjectService } from '../../services/subject.service';

@Injectable({
  providedIn: 'root',
})
export class SubjectFormService {
  subjectForm: any;
  private renderer: Renderer2;
  private subjectFormSubmit$ = new Subject<boolean>();
  private subjectFormInValid = [];
  private dragDrop_components = {};

  constructor(rendererFactory: RendererFactory2, private subjectService: SubjectService, private fb: FormBuilder) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  get getSubjectFormSubmit$() {
    return this.subjectFormSubmit$.asObservable();
  }
  set triggerSubjectFormSubmit$(onSubmit: boolean) {
    this.subjectFormSubmit$.next(onSubmit);
  }
  get getSubjectFormInValid(): any {
    return this.subjectFormInValid;
  }

  resetFormPrivateVariables() {
    this.subjectFormInValid = [];
    this.dragDrop_components = {};
  }

  createSubjectForm(subjectForm: {nestedForms?: []}, subject) {
    this.resetFormPrivateVariables();
    this.subjectService.resetSubjectPrivateVariables();
    if (subject.relationships) {
      subjectForm['id'] = subject.id;
      subjectForm['type'] = 'subject';
    }
    if (subject.subjectFormatSubjects) {
      subjectForm.nestedForms = [];
      this.addForm(subjectForm.nestedForms, subject.subjectFormatSubjects.data, 'subject_format_subject')
    }
    if (subject.subjectMedia) {
      subjectForm['media'] = [];
      this.createMediaForm(subjectForm, subject.subjectMedia.data)
    }
    subjectForm['form'] = this.fb.group({
      'subject_library_text': this.fb.control(subject.subject_library_text || '')
    })
  }

  createMediaForm(subjectForm: any, mediaData: []) {
    if (!subjectForm['media'])
      subjectForm['media'] = [];

    let returnedMedia = mediaData.map((media: any) => {
      if (media.attributes) {
        media = { ...media, ...media.attributes }
        delete media.attributes
      }
      return media;
    })
    subjectForm['media'] = [...subjectForm['media'], ...returnedMedia]
  }

  removeMedia(mediaPath: any, fileID: number) {
    const fileIndex = mediaPath.findIndex(file => file.id === fileID)
    if (fileIndex > -1)
      if (mediaPath[fileIndex].type === 'garbage_media')
        mediaPath.splice(fileIndex, 1);
      else mediaPath[fileIndex].type = 'detach_media'
  }

  addForm(formPath: any[],
    formsData: any[],
    type: 'subject_format_subject' | 'resource_subject_format_subject') {
    for (let index = 0; index < formsData.length; index++) {
      const formData = formsData[index];
      const obj: any = {
        id: formData.id,
        is_editable: formData.is_editable,
        list_order_key: formData.list_order_key,
        description: formData.description,
        title: formData.title,
        type: formData.type,
        form: this.fb.group({})
      };
      if (type === 'subject_format_subject') {
        this.createSectionForm(obj.form, formData)
      } else if (type === 'resource_subject_format_subject') {
        obj.slug = formData.slug;
        obj.resource_id = formData.resource_id;
        this.createResourceForm(obj.form, formData)
      }
      if (formData.subjectFormatSubjects) {
        obj.nestedForms = []
        this.addForm(obj.nestedForms, formData.subjectFormatSubjects.data, 'subject_format_subject')
      }
      if (formData.resourceSubjectFormatSubjects) {
        obj.nestedForms = []
        this.addForm(obj.nestedForms, formData.resourceSubjectFormatSubjects.data, 'resource_subject_format_subject')
      }
      formPath.push(obj);
    }
  }

  createSectionForm(formPath: FormGroup, sectionData) {
    formPath.addControl('title', new FormControl(sectionData.title, Validators.required));
    formPath.addControl('is_active', new FormControl(sectionData.is_active))
    formPath.addControl('description', new FormControl(sectionData.description))

    if (!sectionData.is_editable)
      formPath.disable()
  }

  createResourceForm(formPath, resourceData) {
    formPath.addControl('is_active', new FormControl(resourceData.is_active))
    this.subjectService.resourceTemplates$.subscribe((resourceTemplates) => {
      console.log(resourceTemplates);
      if (resourceTemplates.length) {
        const template = resourceTemplates.find(template => template.id == resourceData.resource_id);
        if (template) {
          const attributes = template['learningResourceAcceptCriteria'].data;
          attributes['id'] = '';
          for (const attributeKey in attributes) {
            if (attributeKey === 'type') {
              continue;
            }
            if (attributes.hasOwnProperty(attributeKey)) {
              if (resourceData.learningResourceAcceptCriteria) {
                formPath.addControl(attributeKey, new FormControl(resourceData.learningResourceAcceptCriteria.data[attributeKey] || '', this.setFieldValidation(attributes[attributeKey].validation)));
              } else {
                formPath.addControl(attributeKey, new FormControl('', this.setFieldValidation(attributes[attributeKey].validation)));
              }
            }
          }

          if (!formPath.get('id').value)
            formPath.controls['id'].setValue(`new_${Math.floor((Math.random() * 100) + 1)}`);

          if (!resourceData.is_editable)
            formPath.disable()
        }
      }
    });
  }

  setFieldValidation(validation: {}) {
    const neededValidations = [];
    for (const validationKey in validation) {
      if (validation.hasOwnProperty(validationKey)) {
        switch (validationKey) {
          case 'required':
            if (validation[validationKey])
              neededValidations.push(Validators.required);
            break;
          case 'max':
            neededValidations.push(Validators.max(validation[validationKey]));
            break;
          case 'min':
            neededValidations.push(Validators.min(validation[validationKey]));
            break;
          case 'number':
            if (validation[validationKey])
              neededValidations.push(Validators.pattern('^(0|[1-9][0-9]*)$'));
            break;
          default:
            break;
        }
      }
    }
    return neededValidations;
  }

  updateSubjectFormInValid(validFlag) {
    const formIDIndex = this.subjectFormInValid.findIndex(formID => formID === validFlag.formID)
    if (validFlag.valid && formIDIndex > -1)
      this.subjectFormInValid.splice(formIDIndex, 1)
    else if (!validFlag.valid && formIDIndex === -1)
      this.subjectFormInValid.push(validFlag.formID)
  }

  setInvalidControlsMessages(form: FormGroup) {
    const messages = [];
    const controls = form.controls;
    for (const controlKey in controls)
      if (controls[controlKey].invalid)
        if (controlKey.includes('type'))
          messages.push({ message: `validation.form.type`, type: 'danger' });
        else
          messages.push({ message: `validation.form.${controlKey}`, type: 'danger' });

    return messages;
  }

  createComponentID(id, type) {
    return `${type}_${id}`;
  }

  getComponentID(componentID) {
    return componentID.split('_')[1];
  }

  OnDragStartComponent(event, parentComponent, component, dragMessage: ElementRef, componentEl: ElementRef) {
    document.getElementsByTagName('nb-layout-header')[0].classList.remove("fixed");
    event.dataTransfer.setData('drag_component', component);
    event.dataTransfer.setData('drag_parentComponent', parentComponent);
    this.dragDrop_components['drag_component'] = component;
    this.dragDrop_components['drag_parentComponent'] = parentComponent;
    event.dataTransfer.dropEffect = "move";
    this.renderer.addClass(componentEl.nativeElement, 'onDragStart');
    event.dataTransfer.setDragImage(dragMessage.nativeElement, 0, 0);
  }

  onDragEndComponent(componentEl: ElementRef) {
    this.renderer.removeClass(componentEl.nativeElement, 'onDragStart');
    document.getElementsByTagName('nb-layout-header')[0].classList.add('fixed');
  }

  onDragOverComponent(event, parentComponent) {
    const drag_component = this.dragDrop_components['drag_component'];
    const drop_parentComponent = parentComponent;
    if (!(drag_component.id === drop_parentComponent.id && drag_component.type === drop_parentComponent.type) && !(drag_component.type === 'resource_subject_format_subject' && drop_parentComponent.type === 'subject')) {
      if (!event.target.classList.contains("drag-over"))
        event.target.classList.add("drag-over");
    } else {
      event.dataTransfer.dropEffect = "none";
      if (event.target.classList.contains("drag-over"))
        event.target.classList.remove("drag-over");
    }
  }

  onDragLeaveComponent(event) {
    if (event.target.classList.contains("drag-over"))
      event.target.classList.remove("drag-over");
  }

  onDropComponent(event, parentComponent) {
    if (event.target.classList.contains("drag-over"))
      event.target.classList.remove("drag-over");
    const drag_component = this.dragDrop_components['drag_component'];
    const drag_parentComponent = this.dragDrop_components['drag_parentComponent'];
    const drop_parentComponent = parentComponent;
    if (!(drag_component.id === drop_parentComponent.id && drag_component.type === drop_parentComponent.type) && !(drag_component.type === 'resource_subject_format_subject' && drop_parentComponent.type === 'subject')) {
      const beforeComponent_id = event.target.id;
      if (beforeComponent_id === 'endOfNestedForm') {
        this.removeComponent(drag_parentComponent, drag_component.id)
        this.insertComponent(drop_parentComponent, beforeComponent_id, drag_component)
      } else if (beforeComponent_id !== drag_component.id) {
        this.removeComponent(drag_parentComponent, drag_component.id)
        const findIndex = drop_parentComponent.nestedForms.findIndex(form => form.id === beforeComponent_id)
        if (findIndex > -1) {
          const beforeComponent = drop_parentComponent.nestedForms[findIndex];
          this.insertComponent(drop_parentComponent, beforeComponent, drag_component)
        }
      }
    }
  }

  insertComponent(parentComponent, beforeComponent, insertedComponent) {
    parentComponent.nestedForms = parentComponent.nestedForms || [];
    if (beforeComponent === 'endOfNestedForm') {
      let last_list_order_key = 0;

      for (let componentID = 0; componentID < parentComponent.nestedForms.length; componentID++) {
        const component = parentComponent.nestedForms[componentID];
        if (component.list_order_key >= last_list_order_key)
          last_list_order_key = component.list_order_key + 1;
      }

      insertedComponent.list_order_key = last_list_order_key;
    } else {
      insertedComponent.list_order_key = beforeComponent.list_order_key;

      for (let componentID = 0; componentID < parentComponent.nestedForms.length; componentID++) {
        const component = parentComponent.nestedForms[componentID];
        if (component.list_order_key >= insertedComponent.list_order_key)
          ++component.list_order_key;
      }
    }

    parentComponent.nestedForms.push(insertedComponent)
  }

  removeComponent(parentComponent, componentID: string, permenantDelete?: boolean) {
    const findIndex = parentComponent.nestedForms.findIndex(form => form.id === componentID)
    if (findIndex > -1) {
      const type = parentComponent.nestedForms[findIndex].type
      if (permenantDelete && !componentID.includes("new"))
        this.subjectService.setPermenantRemovedComponents(type, componentID);

      const id = parentComponent.nestedForms[findIndex].id
      const removedType = type === 'subject_format_subject' ? 'section' : 'resource';
      const invalidID = this.createComponentID(id, removedType)
      this.updateSubjectFormInValid({ valid: true, formID: invalidID });
      parentComponent.nestedForms.splice(findIndex, 1)

    }

    if (!parentComponent.nestedForms.length)
      delete parentComponent.nestedForms;
  }
}
