import { OurEduLocalStorageService } from './../../../services/our-edu-local-storage.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Formatter } from 'sarala-json-api-data-formatter';
import { TaskService } from '../../../services/task.service';
import { Task } from '../../../models/task';
import { QuestionReportService } from '../../../services/question-report.service';
import { Questionsubject } from '../../../models/question-subject';
import { ActivatedRoute } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { IComponentCanDeactivate } from '../../../auth/helpers';
import { HostListener } from '@angular/core';
import { CanDeactivateComponent } from '../../../can-deactivate-component';

@Component({
  selector: 'ngx-question-reports-subject',
  templateUrl: './question-reports-subject.component.html',
  styleUrls: ['./question-reports-subject.component.scss'],
})
export class QuestionReportsSubjectComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
  constructor(private qestionReportService: QuestionReportService, private activatedRout: ActivatedRoute
    , private translate: TranslateService) {
      super()
    this.activatedRout.params.subscribe(params => {
      if (params.subjectId) {
        this.subjectId = params.subjectId;
        this.reloadData(1);
      }
    });
  }
  langChange: Subscription;
  questionSubjects: any[];
  dataUnSerlaized: any;
  pagination;
  subjectId;
  config: any;

  ngOnInit() {
    const el = document.getElementById('nb-global-spinner');
    if (el) {
      el.style['display'] = 'none';
    }
    this.langChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.reloadData(this.pagination.current_page);
    });
  }

  reloadData(pagenumber) {
    this.qestionReportService.getQuestionReportsSubject(pagenumber, this.subjectId)
      .subscribe(data => {
        this.dataUnSerlaized = data;
        const formatter = new Formatter();
        const datas = formatter.deserialize(data);
        this.questionSubjects = datas.subjectFormatSubjects.data[0].questionReports.data;
        // this.generatePagination(this.dataUnSerlaized.meta.pagination);
      });
  }

  generatePagination(pagination) {
    this.pagination = pagination;
    // this.pagination.numbers = [];
    this.config = {
      currentPage: this.pagination.current_page,
      itemsPerPage: this.pagination.per_page,
      totalItems: this.pagination.total
    };
    // for (let i = 1; i <= this.pagination.total_pages; i++) {
    //   this.pagination.numbers.push(i);
    // }
  }

  onPageChange(pageNumber) {
    if (pageNumber !== this.pagination.current_page)
      this.reloadData(pageNumber);
  }

  // getPage(pageNumber) {
  //   this.reloadData(pageNumber);
  // }
  ngOnDestroy() {
    this.langChange.unsubscribe();
  }

}
