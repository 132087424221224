import {Formatter} from 'sarala-json-api-data-formatter';
import {Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, EventEmitter} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CreateGeneralExam} from '../../../models/general-exam';
import {GeneralExamService} from '../../../services/general-exam.service';
import {SubjectService} from '../../../services/subject.service';
import {SubjectLookup} from '../../../models/subject';
import {DifficultyLevel} from '../../../models/DifficultyLevel';
import {SubjectFormatSubject} from '../../../models/subject-format-subject';
import {NbToastrService, NbDialogService, NbDialogRef} from '@nebular/theme';
import {ApiAction} from '../../../models/ApiAction';
import {LanguageConfig} from '../../../models/LanguageConfig';
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';
import {MasterQuestion} from '../../../models/MasterQuestion';

import {PreviewQuestionsDialogComponent} from '../preview-questions/preview-questions-dialog.component';
import {PublishedDialogComponent} from '../published-dialog/published-dialog.component';
import {PaginationConfig} from '../../../models/PaginationConfig';
import {NgSelectComponent} from "@ng-select/ng-select";
import { CanDeactivateComponent } from '../../../can-deactivate-component';

@Component({
  selector: 'create-edit-exam',
  templateUrl: './create-edit-exam.component.html',
  styleUrls: ['./create-edit-exam.component.scss']
})
export class CreateEditExamComponent extends CanDeactivateComponent implements OnInit {

  @ViewChild('picker', {static: false}) picker: ElementRef;
  @ViewChild('myselect', {static: false}) myselect: NgSelectComponent;

  id: number;
  isEdit: boolean = false;
  isPublished: boolean = false;
  model: CreateGeneralExam;
  config: PaginationConfig;
  allSubjects: SubjectLookup[] = [];
  subjectLookups: SubjectLookup[] = [];

  // get subjectMap(): any[] {
  //   return this.subjectLookups ? this.subjectLookups.map(s => ({id: +s.id, name: s.name})) : [];
  // }

  allSubjectSections: SubjectFormatSubject[] = [];
  allDifficultyLevels: DifficultyLevel[] = [];
  editAction: ApiAction;
  languageConfig: LanguageConfig;
  publishAction: ApiAction;
  loading = true;
  selected: any;
  dialogRef: NbDialogRef<PreviewQuestionsDialogComponent>;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private translate: TranslateService,
              private dialogService: NbDialogService,
              private service: GeneralExamService,
              private cdr: ChangeDetectorRef,
              private toastr: NbToastrService,
              private subjectService: SubjectService) {
                super()
  }

  ngOnInit() {
    this.id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
    this.subjectService.getDifficultyLevels().subscribe(difficultyLevels => {
      this.allDifficultyLevels = difficultyLevels.data[0].difficultyLevel.data;
      // this.allDifficultyLevels = difficultyLevels
      if (this.id) {
        this.isEdit = true;
        this.service.getById(this.id).subscribe(r => {
          console.log('general exam', r);
          this.model = CreateGeneralExam.FromFetched(r, this.allDifficultyLevels);
          this.isPublished = r.is_published;
          this.editAction = r.actions.data.find(a => a.key == 'edit_general_exam');
          this.publishAction = r.actions.data.find(a => a.key == 'publish_general_exam');
          this.setSubjectId(this.model.subject_id);


          this.cdr.detectChanges();
        });
      } else {
        this.model = new CreateGeneralExam();
      }
    });
    this.subjectService.getAllSubjectsLookup().subscribe(data => {
      // console.log('data baby', data.data[0].subjects);
      // this.subjectLookups = data.data[0].subjects.data;
      this.subjectLookups = [...this.subjectLookups, ...data.data[0].subjects.data];

      // this.cdr.detectChanges();
    }, error => {
    }, () => {

      this.loading = false;
    });
    this.setupLanguage();
  }

  getExam(pageNumber: number = 1) {
    this.service.getById(this.id, pageNumber).subscribe(r => {
      let questions = this.service.getPreviewQuestionsForExam(r);
      this.setupConfig(r.questions ? r.questions_pagination : r.prepared_questions_pagination);
      this.dialogRef.componentRef.instance.config = this.config;
      this.dialogRef.componentRef.instance.questions = questions;
    });
  }

  setupConfig(pagination) {
    this.config = {
      currentPage: pagination.current_page,
      itemsPerPage: pagination.per_page,
      totalItems: pagination.total
    };
  }

  setSubjectId(value) {
    if (!this.model.subject_id) {
      this.allSubjectSections = [];
      return;
    }
    this.subjectService.getSubject(this.model.subject_id)
      .subscribe(data => {
        let subjectSections: SubjectFormatSubject[] = data.included.filter(i => i.type == 'subject_format_subject').map(i => ({id: i.id, ...i.attributes}));
        console.log('subject sections', subjectSections);
        if (subjectSections) {
          this.allSubjectSections = subjectSections;
        } else {
          this.allSubjectSections = [];
        }
      });
  }

  submit() {
    if (this.isEdit) {
      this.service.edit(this.id, this.model).subscribe(r => {
        this.toastr.success('Edited successfully.');
        this.router.navigateByUrl('/general-exams/edit/' + this.id);
      });
    } else {
      this.service.create(this.model).subscribe(r => {
        this.toastr.success('Created successfully.')
        this.router.navigateByUrl('/general-exams');
      });
    }
  }

  publish() {
    this.service.publish(this.id, this.publishAction).subscribe(r => {
      const attributes = (<any>r).data.attributes;
      this.model.url = attributes.url;
      this.isPublished = true;
      this.model.published_at = attributes.published_at;
      let ref = this.dialogService.open(PublishedDialogComponent, {
        context: {
          url: attributes.url,
          msg: (<any>r).meta.message
        }
      });

      // this.router.navigateByUrl('/general-exams');
    });
  }

  myObservable: EventEmitter<number>;

  previewQuestions() {
    let questions: MasterQuestion[] = [];
    questions = this.service.getPreviewQuestionsForExam(this.model);
    this.myObservable = new EventEmitter<number>();
    this.myObservable.subscribe((pageNumber) => this.getExam(pageNumber));
    let r = <any>this.model;
    this.setupConfig(r.questions ? r.questions_pagination : r.prepared_questions_pagination);
    this.dialogRef = this.dialogService.open(PreviewQuestionsDialogComponent,
      {
        context: {
          questions: questions,
          config: this.config,
          pageChange: this.myObservable
        },
        autoFocus: true,
        closeOnBackdropClick: false,
        hasBackdrop: true
      }
    );
    this.dialogRef.onClose.subscribe(s => {
      this.dialogRef = null;
      this.myObservable = null;
    });
  }

  // previewQuestions(){
  //   let ref = this.dialogService.open(ConfirmDialogComponent, { context: { data: { message: "navigation_alert", action: 'danger' } } });
  //   ref.onClose.subscribe(result => {
  //     if (result == true) {
  //       this.router.navigateByUrl(`/general-exams/preview-questions/${this.id}`);
  //     }
  //   })
  // }

  private setupLanguage() {
    this.languageConfig = new LanguageConfig(this.translate.getDefaultLang());
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.languageConfig = new LanguageConfig(event.lang);
    });
  }

}
