import { Component, OnInit } from '@angular/core';
import { MasterQuestion } from '../../../models/MasterQuestion';
import { LanguageConfig } from '../../../models/LanguageConfig';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralExamService } from '../../../services/general-exam.service';
import { PaginationConfig } from '../../../models/PaginationConfig';
import { CanDeactivateComponent } from '../../../can-deactivate-component';
@Component({
  templateUrl: './preview-questions-full.component.html',
  styleUrls: ['./preview-questions.scss']
})
export class PreviewQuestionsFullComponent extends CanDeactivateComponent implements OnInit {
  questions: MasterQuestion[];
  languageConfig: LanguageConfig;
  id;
  is_published: boolean;
  currentPage: any;
  config: PaginationConfig;

  constructor(private translate: TranslateService, private route: ActivatedRoute, private service: GeneralExamService) {
    super()
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.reloadData();
    this.setupLanguage();
  }

  private reloadData(pageNumber = 1) {
    this.service.getById(this.id, pageNumber)
      .subscribe(r => {
        this.is_published = r.is_published;
        this.questions = this.service.getPreviewQuestionsForExam(r);
        this.generatePagination(r.questions ? (<any>r).questions_pagination : (<any>r).prepared_questions_pagination);
      });
  }

  onPageChange(pageNumber) {
    if (pageNumber !== this.currentPage)
      this.reloadData(pageNumber);
  }

  private generatePagination(pagination) {
    this.currentPage = pagination.current_page;
    // this.pagination.numbers = [];
    this.config = {
      currentPage: pagination.current_page,
      itemsPerPage: pagination.per_page,
      totalItems: pagination.total
    };
  }

  private setupLanguage() {
    this.languageConfig = new LanguageConfig(this.translate.getDefaultLang());
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.languageConfig = new LanguageConfig(event.lang);
    });
  }
}
