import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import { tap, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NbToastrService } from '@nebular/theme';



@Injectable({
  providedIn: 'root',
})
export class MediaService {

  constructor(private http: HttpClient, private translate: TranslateService, private toastrService: NbToastrService) { }

  private handleSuccess() {
    this.translate.get("serverResponse.subjectForm.success").subscribe((successMessage: string) => {
      this.toastrService.show(
        'Success',
        successMessage,
        { status: 'success' });
    });
  }

  private handleError<T>(operation: any, result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      if (error.error) {
        let errorMsg = '';
        error.error.errors.forEach(err => {
          if (err.status == 422) {
            errorMsg += err.detail;
          }
        });
        if (!errorMsg) {
          errorMsg = 'serverResponse.subjectForm.error';
          this.translate.get(errorMsg).subscribe((errorMessage: string) => {
            this.toastrService.show(
              'Error',
              errorMessage,
              { status: 'danger' });
          });
        } else {
          this.toastrService.show(
            'Error',
            errorMsg,
            { status: 'danger' });
        }
      }
      return of(result as T);
    };
  }
  addNewMedia(files: any): Observable<any> {
    const filesAmount = files.length;
    const formData: FormData = new FormData();
    for (let i = 0; i < filesAmount; i++) {
      formData.append('media[]', files[i]);
    }
    return this.http.post<any>(BaseService.mediaUrl + '/media', formData).pipe(
      tap((filesData: any) => console.log(`added files w/ id=${filesData}`)),
      catchError(this.handleError('addFiles')),
    );
  }
  removeNewMedia() {

  }
  removexistMedia() {

  }
}
