import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Formatter } from 'sarala-json-api-data-formatter';
import { catchError, map, tap } from 'rxjs/operators';
import { BaseService } from './base.service';
import { Observable, of } from 'rxjs';
import { userInfo } from 'os';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class TaskPerformanceService extends BaseService {
  private performance = {
    list: { data: [], tableKeys: ['tasks_count', 'done_tasks_count', 'expired_tasks_count', 'in_progress_tasks_count', 'content_author'] },
    specificList: { data: [], tableKeys: ['title', 'is_active', 'is_expired', 'is_assigned'] },
    details: { data: {}, tableKeys: [] }
  }
  get getPerformance() {
    return this.performance;
  }

  constructor(private http: HttpClient) {
    super();
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  getList(pagenumber): Observable<any> {
    const url = `${BaseService.baseUrl}/sme/subjects/tasks-performance?page=${pagenumber}`;

    return this.http.get<any>(url)
      .pipe(
        map(data => {
          const formatter = new Formatter();
          const formattedData = formatter.deserialize(data);
          this.performance.list.data = formattedData.data;
          const result = { data: this.performance.list.data, pagination: data.meta.pagination };
          return result;
        }),
        catchError(this.handleError('getTaskPerformanceList', [])),
      );
  }

  getSpecificList(userId, pagenumber): Observable<any> {
    // const list = this.performance.list.data.find(item => item.id === id)
    // const endPoint = list.actions.data[0].endpoint_url;
    // const url = `${endPoint}?page=${pagenumber}`;
    const url = `${BaseService.baseUrl}/sme/subjects/tasks-performance-list/${userId}?page=${pagenumber}`;

    return this.http.get<any>(url)
      .pipe(
        map(data => {
          const formatter = new Formatter();
          const formattedData = formatter.deserialize(data);
          this.performance.specificList.data = formattedData.data;
          const result = { data: this.performance.specificList.data, pagination: data.meta.pagination };
          return result;
        }),
        catchError(this.handleError('getTaskPerformanceList', [])),
      );
  }

  getDetail(id): Observable<any> {
    const url = `${BaseService.baseUrl}/sme/subjects/tasks/${id}`;
    return this.http.get<any>(url)
      .pipe(
        tap(task => console.log('fetched tasks detail')),
        catchError(this.handleError('getTaskPerformanceDetail', [])),
      );
  }

}
