import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'attributeKeyPipe'
})
export class attributeKeyPipe implements PipeTransform {
    transform(attributeKey: string): any {
        if (attributeKey.includes('type'))
            return 'type'
        else
            return attributeKey;
    }
}