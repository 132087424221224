import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Formatter } from 'sarala-json-api-data-formatter';
import { TaskService } from '../../../services/task.service';
import { Task } from '../../../models/task';
import { QuestionReportService } from '../../../services/question-report.service';
import { Questionsubject } from '../../../models/question-subject';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'ngx-question-reports',
  templateUrl: './question-reports-list.component.html',
  styleUrls: ['./question-reports-list.component.scss'],
})
export class QuestionReportsListComponent implements OnInit, OnDestroy {
  constructor(private qestionReportService: QuestionReportService, private translate: TranslateService) {
    this.reloadData(1);
  }
  langChange: Subscription;
  questionSubjects: Observable<Questionsubject[]>;
  dataUnSerlaized: any;
  pagination;
  config: any;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit() {
    const el = document.getElementById('nb-global-spinner');
    if (el) {
      el.style['display'] = 'none';
    }
    this.langChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      console.log(event)
      this.reloadData(this.pagination.current_page);
    });
  }

  reloadData(pagenumber) {
    this.qestionReportService.getQuestionReports(pagenumber)
      .subscribe(data => {
        this.dataUnSerlaized = data;
        const formatter = new Formatter();
        const datas = formatter.deserialize(data);
        this.questionSubjects = datas.data;
        this.generatePagination(this.dataUnSerlaized.meta.pagination);
      });
  }

  generatePagination(pagination) {
    this.pagination = pagination;
    // this.pagination.numbers = [];
    this.config = {
      currentPage: this.pagination.current_page,
      itemsPerPage: this.pagination.per_page,
      totalItems: this.pagination.total
    };
    // for (let i = 1; i <= this.pagination.total_pages; i++) {
    //   this.pagination.numbers.push(i);
    // }
  }

  onPageChange(pageNumber) {
    if (pageNumber !== this.pagination.current_page)
      this.reloadData(pageNumber);
  }


  // getPage(pageNumber) {
  //   this.reloadData(pageNumber);
  // }
  ngOnDestroy() {
    this.langChange.unsubscribe();
  }

}
