import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Formatter } from 'sarala-json-api-data-formatter';
import { SubjectD } from '../../../models/subject';
import { SubjectService } from '../../../services/subject.service';


(window as any).global = window;


@Component({
  selector: 'ngx-subjects',
  templateUrl: './subjects.component.html',
  styleUrls: ['./subjects.component.scss'],
})
export class SubjectsComponent implements OnInit, OnDestroy {
  langChange: Subscription;
  filters: [];
  subjects: Observable<SubjectD[]>;
  items: number[];
  config: any;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  pagination;

  constructor(private subjectService: SubjectService,
    private toastrService: NbToastrService,
    private translate: TranslateService) {
    this.reloadData(1);
  }

  ngOnInit() {
    const el = document.getElementById('nb-global-spinner');
    if (el) {
      el.style['display'] = 'none';
    }

    this.langChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      console.log(event);
      localStorage.setItem("lang", event.lang)
      this.reloadData(this.pagination.current_page);
    });

  }

  reloadData(pagenumber, filters?) {
    this.subjectService.getSubjects(pagenumber, filters)
      .subscribe(data => {
        const formatter = new Formatter();
        const datas = formatter.deserialize(data);
        console.log(datas.data);
        this.subjects = datas.data;
        this.filters = datas.meta.filters;
        this.items = [1, 2, 3, 4, 5];
        this.generatePagination(datas.meta.pagination);
      });
    // this.subject-list = this.subjectService.getSubjects();
  }
  toggleStatus(id: number) {
    this.subjectService.toggleSubjectStatus(id).subscribe(
      (res) => {
        this.reloadData(this.pagination);
        this.toastrService.show(
          'Success',
          'Status changed successfully',
          { status: 'success' });
      },
      (err) => {
        console.log(err);
      },
    );
  }

  generatePagination(pagination) {
    this.pagination = pagination;
    // this.pagination.numbers = [];
    this.config = {
      currentPage: this.pagination.current_page,
      itemsPerPage: this.pagination.per_page,
      totalItems: this.pagination.total
    };
    // for (let i = 1; i <= this.pagination.total_pages; i++) {
    //   this.pagination.numbers.push(i);
    // }
  }
  onPageChange(pageNumber) {
    if (pageNumber !== this.pagination.current_page)
      this.reloadData(pageNumber);
  }

  // getPage(pageNumber) {
  //   if (pageNumber !== this.pagination.current_page)
  //     this.reloadData(pageNumber);
  // }
  ngOnDestroy() {
    this.langChange.unsubscribe();
  }
}
