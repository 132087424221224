import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/user';
import { OurEduLocalStorageService } from './our-edu-local-storage.service';
import { BaseService } from './base.service';
import { AlertService } from './alert.service';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService extends BaseService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient,
    private alertService: AlertService,
  ) {
    super();
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  get getCurrentUserSubject(): Observable<User> {
    return this.currentUserSubject
  }

  login(email, password) {
    let data = {
      'data': {
        'id': null,
        'type': 'user',
        'attributes': {
          'email': email,
          'password': password,
        },
      },
    };

    return this.http.post<any>(`${BaseService.baseUrl}/auth/login`, data)
      .pipe(map(data => {
        let formatedUser = this.formatter.deserialize(data);
        let userData = {
          userObject: formatedUser,
          name: formatedUser.name,
          profile_picture: formatedUser.profile_picture,
          token: data.meta.token,
        };
        OurEduLocalStorageService.saveUserData(userData);
        this.currentUserSubject.next(data);
        return data;
      }));
  }

  RequestPassword(email) {
    const data = {
      'data': {
        'id': null,
        'type': 'user',
        'attributes': {
          'email': email,
        },
      },
    };

    return this.http.post<any>(`${BaseService.baseUrl}/auth/forget-password`, data)
      .pipe(map((data: any) => {
        return data.meta.message;
      }));
  }

  ResetPassword(newPass, confirmPass, token) {
    const data = {
      'data': {
        'id': null,
        'type': 'user',
        'attributes': {
          'password': newPass,
          'password_confirmation': confirmPass,
        },
      },
    };

    return this.http.post<any>(`${BaseService.baseUrl}/auth/reset-password/${token}`, data)
      .pipe(map(data => {
        return data.meta.message;
      }));
  }


  logout() {
    // remove user from local storage and set current user to null
    OurEduLocalStorageService.removeUserData();
    this.currentUserSubject.next(null);
  }

}
