import { CanDeactivateComponent } from './../../../can-deactivate-component';
import { Component, OnInit } from '@angular/core';
import { SubjectService } from '../../../services/subject.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Formatter } from 'sarala-json-api-data-formatter';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ResourceDataService } from '../resource-data.service';

@Component({
  selector: 'sub-sections',
  templateUrl: './sub-sections.component.html',
  styleUrls: ['./sub-sections.component.scss']
})
export class SubSectionsComponent extends CanDeactivateComponent implements OnInit {
  loading: boolean;
  subjectId: any;
  sectionId: any;
  subSections: any;
  subjectFormatSubject: any;
  resources: any;
  currentLang: any;
  langChange: Subscription;

  constructor(private subjectService: SubjectService, private router: ActivatedRoute, private translate: TranslateService,
    private dataService: ResourceDataService,
    private routerService: Router) {
      super()
    this.currentLang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
    });
    this.router.params.subscribe(params => {
      this.subjectId = params.subjectId;
      this.sectionId = params.sectionId;
      this.reloadData(this.sectionId)
    });
  }

  ngOnInit() {
    this.langChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.reloadData(this.sectionId);
    });
  }
  reloadData(sectionId: any) {
    this.loading = true;
    this.subjectService.getSubjectFormatSubject(sectionId)
      .subscribe(data => {
        const formatter = new Formatter();
        this.loading = false;
        this.subjectFormatSubject = formatter.deserialize(data);
        console.log(this.subjectFormatSubject);
        this.subSections = this.subjectFormatSubject.subjectFormatSubjects ? this.subjectFormatSubject.subjectFormatSubjects.data : null;
        this.resources = this.subjectFormatSubject.resourceSubjectFormatSubject ? this.subjectFormatSubject.resourceSubjectFormatSubject.data : null;
      });
  }

  editResource(res){
    this.dataService.resource = res;
    this.routerService.navigateByUrl(`/fill/${res.id}`)
  }

  ngOnDestroy() {
    this.langChange.unsubscribe();
  }
}
