import { Component, Input, EventEmitter, Output, OnInit, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';


(window as any).global = window;


@Component({
  selector: 'list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListComponent implements OnInit, OnChanges {

  @Input() tableKeys: [];
  @Input() data: [];
  @Input() pagination: any;
  @Input() actionLink;
  @Output() getData = new EventEmitter();
  ngOnInit(): void {
    console.log(this.data);
    this.pagination = { pages: [] }

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pagination.currentValue && changes.pagination.currentValue.total_pages)
      this.generatePagination(this.pagination)
  }
  generatePagination(pagination) {
    this.pagination = pagination;
    const pages = [];
    for (let index = 0; index < this.pagination.total_pages; index++) {
      const id = index + 1;
      pages.push({ id: id, active: this.pagination.current_page === id });
    }
    this.pagination.pages = pages;
  }

  getPage(pageNumber) {
    this.getData.emit(pageNumber);
  }

}
