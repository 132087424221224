import { Injectable } from '@angular/core';
import { Router, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface IComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({ providedIn: 'root' })
export class PendingChangesGaurd implements CanDeactivate<IComponentCanDeactivate> {
    constructor(
        private router: Router,

    ) { }

    canDeactivate(component: IComponentCanDeactivate) {
        if(!component) return true;
        return component.canDeactivate() ?
            true :
            confirm('تحذير: لديك تعديلات غير محفوظة. اضغط إلغاء للعودة أو موافق لتأكيد هجر التعديلات غير المحفوظة');
    }
}
