import {Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {BehaviorSubject} from 'rxjs';
import {BaseService} from '../services/base.service';
import {HttpClient} from '@angular/common/http';
import {OurEduLocalStorageService} from "../services/our-edu-local-storage.service";
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(private angularFireMessaging: AngularFireMessaging, private http: HttpClient, private toastrService: ToastrService) {
    // this.angularFireMessaging.messages((msgings) => console.log(msgings));

    this.angularFireMessaging.messaging.subscribe(
      (_messaging: any) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        // _messaging._next = (payload: any) => {
        //   this.currentMessage.next(payload);
        // };
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );

    // this.angularFireMessaging.messages.subscribe(
    //   (msgings) => {
    //     console.log(msgings)
    //     // msgings = msgings.onMessage.bind(msgings);
    //     // msgings.onTokenRefresh = msgings.onTokenRefresh.bind(msgings);
    //   });
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.updateToken(token).subscribe();


      });
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (msg: any) => {
        console.log("show message!", msg);
        const body = `<div class='grosso' style="width: 500px">${msg.notification.body}<a href="${msg.notification.click_action}">click</a></div>`;
        this.toastrService.success(
          body,
          msg.notification.title,
          {
            enableHtml: true,
            positionClass: 'toast-top-left',
            disableTimeOut: true,

          });

        this.currentMessage.next(msg);
      })
  }

  private updateToken(token) {
    const userData = OurEduLocalStorageService.getUserData();

    if (userData) {
      let data = {
        data: {
          type: "notification",
          id: "null",
          attributes: {
            device_token: token,
            device_type: "web",
            fingerprint: "sme_web_device_device_device"
          }
        }
      };
      const url = BaseService.baseUrl + '/notifications/update-token';
      return this.http.post(url, data);

    } else {
    }
  }

  // tokenRefresh() {
  //
  //   this.angularFireMessaging.onTokenRefresh(() => {
  //     this.angularFireMessaging.requestToken.subscribe(
  //       (token) => {
  //         console.log(token);
  //         this.updateToken(token).subscribe();;
  //       },
  //       (err) => {
  //         console.error('Unable to get permission to notify.', err);
  //       }
  //     );
  //   });
  // }
}
